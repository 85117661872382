import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardMedia,
  IconButton,
  CardContent,
  Typography,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import ShareIcon from "@mui/icons-material/Share";
import DirectionsIcon from "@mui/icons-material/Directions";
import { useParams, useNavigate } from "react-router-dom";
import firebase from "../../../config/firebase";
import Page from "../../../utils/Page";

const useStyles = makeStyles((theme) => ({
  root: {
    background: `linear-gradient(135deg, ${theme.palette.primary.main} 30%, ${theme.palette.secondary.main} 90%)`,
    minHeight: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(3),
  },
  card: {
    maxWidth: 600,
    width: "100%",
    position: "relative",
    boxShadow: theme.shadows[10],
    textAlign: "center",
  },
  cardMedia: {
    height: 300,
  },
  cardContent: {
    paddingBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
  },
  shareButton: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  businessLogo: {
    width: 100,
    height: 100,
    borderRadius: "50%",
    marginBottom: theme.spacing(2),
  },
  centeredContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textShadow: "0px 1px 1px rgba(0,0,0,0.7)",
  },
  actionButtons: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(2),
  },
}));

const PromotionDetailCard = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { merchantId, promoId } = useParams();
  const [promotion, setPromotion] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchPromotion = async () => {
      try {
        const promoData = await firebase.fetchPromotionData(
          merchantId,
          promoId
        );
        if (!promoData) {
          throw new Error("Promotion not found");
        }
        setPromotion(promoData);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPromotion();
  }, [merchantId, promoId]);

  const handleGetDirections = () => {
    const url = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
      promotion.businessAddress
    )}`;
    window.open(url, "_blank");
  };

  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        title: promotion.name,
        text: promotion.description,
        url: window.location.href,
      });
    } else {
      navigate("/not-found");
      console.log("Web Share is not supported in your browser");
    }
  };

  if (loading) {
    return (
      <Box className={classes.root}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    navigate("/not-found");
    return null; // Return null to avoid rendering below component
  }

  return (
    <Page title="Promotion Details">
      <Box className={classes.root}>
        <Card className={classes.card}>
          <IconButton className={classes.shareButton} onClick={handleShare}>
            <ShareIcon />
          </IconButton>
          <CardMedia
            className={classes.cardMedia}
            image={promotion.imageUrl || "https://via.placeholder.com/300"}
            title={promotion.name}
          />
          <CardContent className={classes.cardContent}>
            <div className={classes.centeredContent}>
              {promotion.businessLogoUrl && (
                <img
                  src={promotion.businessLogoUrl}
                  alt="Business Logo"
                  className={classes.businessLogo}
                />
              )}
              <Typography variant="h2" component="div" gutterBottom>
                <strong>{promotion.businessName}</strong>
              </Typography>
              <Typography variant="body2" color="text.secondary" gutterBottom>
                {promotion.businessAddress}
              </Typography>
            </div>
            <Typography variant="h4" component="div" gutterBottom mt={2}>
              <strong>{promotion.name}</strong>
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {promotion.description}
            </Typography>
            <Typography variant="body2" color="text.secondary" mt={2}>
              <strong>Valid till: </strong>{" "}
              {new Date(promotion.endDate).toLocaleDateString()}
            </Typography>
            <div className={classes.actionButtons}>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={handleGetDirections}
                startIcon={<DirectionsIcon />}
                sx={{ mr: 1 }}
              >
                Get Directions
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={handleShare}
                startIcon={<ShareIcon />}
              >
                Share
              </Button>
            </div>
          </CardContent>
        </Card>
      </Box>
    </Page>
  );
};

export default PromotionDetailCard;
