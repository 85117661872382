import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Container,
  Typography,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import Logo from "../../icons/logo.png";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100%",
  },
  logo: {
    width: "100px", // Adjust the size of your logo
    marginBottom: theme.spacing(1),
  },
  heading: {
    marginBottom: theme.spacing(1),
  },
  subtitle: {
    marginBottom: theme.spacing(2),
  },
  input: {
    marginBottom: theme.spacing(2),
  },
  button: {
    padding: theme.spacing(1, 2),
  },
}));

const Passcode = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [passcode, setPasscode] = useState("");
  const [openErrorDialog, setOpenErrorDialog] = useState(false);

  const handlePasscodeSubmit = async (passcode) => {
    try {
      const firestore = getFirestore(); // Get the Firestore instance

      const passcodeRef = collection(firestore, "passcode"); // Replace 'approved-merchant-ids' with the actual name of your Firestore collection
      const passcodeSnapshot = await getDocs(passcodeRef);

      let validPasscode = false;

      passcodeSnapshot.forEach((doc) => {
        if (doc.id === passcode) {
          validPasscode = true;
          sessionStorage.setItem("passcode", passcode);
          // Redirect to the merchant onboarding start page
          navigate(`/merchant-onboarding-start/`);
        }
      });

      if (!validPasscode) {
        // Display an error message
        setOpenErrorDialog(true);
      }
    } catch (error) {
      console.error("Error retrieving approved merchant IDs:", error);
      // Handle the error
    } finally {
      setPasscode(""); // Reset the passcode input field
    }
  };

  const handleCloseErrorDialog = () => {
    setOpenErrorDialog(false);
  };

  return (
    <Container className={classes.container}>
      <div className={classes.logoContainer}>
        {/* Add your logo image here */}
        <img src={Logo} alt="Logo" className={classes.logo} />
      </div>
      <Typography variant="h2" className={classes.heading}>
        Admin Passcode
      </Typography>
      <Typography variant="h5" className={classes.subtitle}>
        Please enter the passcode to proceed
      </Typography>
      <TextField
        id="outlined-basic"
        label="Enter passcode"
        variant="outlined"
        type="password"
        value={passcode}
        onChange={(e) => setPasscode(e.target.value)}
        className={classes.input}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={() => handlePasscodeSubmit(passcode)}
        className={classes.button}
      >
        Submit
      </Button>
      <Dialog open={openErrorDialog} onClose={handleCloseErrorDialog}>
        <DialogTitle>
          <Typography
            variant="h6"
            component="div"
            style={{ fontWeight: "bold", fontSize: "1.4rem" }}
          >
            Error
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Invalid passcode! Please try again.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseErrorDialog} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Passcode;
