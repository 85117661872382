import React, { useState, useEffect } from "react";
import firebase from "../../config/firebase";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  TablePagination,
  IconButton,
  Menu,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import Box from "@mui/material/Box";
import { useNavigate, useParams } from "react-router-dom";
import angryEmoji from "../../utils/images/angry.png";
import sadEmoji from "../../utils/images/sad.png";
import okEmoji from "../../utils/images/ok.png";
import happyEmoji from "../../utils/images/happy.png";
import lovedEmoji from "../../utils/images/loved.png";

const useStyles = makeStyles((theme) => ({
  tableHeader: {
    backgroundColor: theme.palette.primary.main, // Use primary color from the theme
  },
  tableText: {
    color: theme.palette.common.white,
    fontWeight: "bold",
    padding: "8px 8px", // Adjust the padding as needed
  },
  table: {
    width: "100%",
  },
  tableContainer: {
    height: "100%",
  },
  feelingCell: {
    fontSize: "1.5rem", // Adjust font size as needed
  },
  Button: {
    border: "1px solid #343ff9",
    borderRadius: "5px",
    padding: "8px",
  },
  filterButton: {
    color: "#ffffff",
  },
}));

function CustomerTable() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { id } = useParams();

  // State variables
  const [searchText, setSearchText] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [customerData, setCustomerData] = useState([]);
  const [selectedFeelings, setSelectedFeelings] = useState([]);
  const [showNoFeedback, setShowNoFeedback] = useState(false);
  const [feedbackFilterAnchor, setFeedbackFilterAnchor] = useState(null);
  const [feelingFilterAnchor, setFeelingFilterAnchor] = useState(null);

  // Fetch customer data from Firebase
  const getAllCustomers = (merchantID) => {
    try {
      setLoading(true);

      firebase.getAllMerchantCustomers(merchantID).then((data) => {
        const sortedData = data.sort((a, b) => b.timestamp - a.timestamp);
        setCustomerData(sortedData);
        setLoading(false);
      });
    } catch (error) {
      alert(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getAllCustomers(id);
  }, []);

  // Event handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchTextChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleViewCustomerDetailsBtn = (customerData) => {
    navigate("/app/customer-details", {
      state: {
        filteredCustomers,
        customerData: {
          visitDate: customerData.visitDate,
          feedback: customerData.feedback,
          feeling: customerData.feeling,
          name: customerData.name,
          phone: customerData.phone,
          gender: customerData.gender,
          email: customerData.email,
          dob: customerData.dob,
        },
      },
    });
  };

  const handleFeelingFilterClick = (event) => {
    setFeelingFilterAnchor(event.currentTarget);
  };

  const handleFeelingFilterChange = (event) => {
    const filterValue = event.target.value;

    if (selectedFeelings.includes(filterValue)) {
      setSelectedFeelings(
        selectedFeelings.filter((feeling) => feeling !== filterValue)
      );
    } else {
      setSelectedFeelings([...selectedFeelings, filterValue]);
    }
  };

  const handleFeelingFilterClose = () => {
    setFeelingFilterAnchor(null);
  };

  const handleFeedbackFilterClick = (event) => {
    setFeedbackFilterAnchor(event.currentTarget);
  };

  const handleFeedbackFilterClose = () => {
    setFeedbackFilterAnchor(null);
  };

  const handleNoFeedbackFilterChange = () => {
    setShowNoFeedback(!showNoFeedback);
  };

  // Filtered customer data
  const filteredCustomers = customerData.filter((customerData) => {
    const nameMatch = customerData.name
      .toLowerCase()
      .includes(searchText.toLowerCase());
    const emailMatch = customerData.email
      .toLowerCase()
      .includes(searchText.toLowerCase());
    const phoneMatch = customerData.phone
      .toLowerCase()
      .includes(searchText.toLowerCase());

    const hasFeedback =
      customerData.feedback && customerData.feedback.trim() !== "";
    const hasNoFeedback =
      !hasFeedback || customerData.feedback === "Not provided";

    if (selectedFeelings.length > 0) {
      return (
        selectedFeelings.includes(customerData.feeling) &&
        (showNoFeedback ? hasNoFeedback : true)
      );
    }

    return (
      (nameMatch || emailMatch || phoneMatch) &&
      (showNoFeedback ? hasNoFeedback : true)
    );
  });

  return (
    <div>
      <Box
        component="form"
        sx={{ "& > :not(style)": { margin: 2, width: "25ch" } }}
        noValidate
        autoComplete="off"
      >
        <TextField
          id="outlined-basic"
          label="Search"
          value={searchText}
          variant="outlined"
          onChange={handleSearchTextChange}
        />
      </Box>
      <TableContainer
        component={Paper}
        className={classes.tableContainer}
        sx={{ mt: 4 }}
      >
        <Table className={classes.table} aria-label="customer table">
          <TableHead
            className={classes.tableHeader}
            style={{ position: "sticky", top: 0, zIndex: 1 }}
          >
            <TableRow key={1}>
              <TableCell
                className={classes.tableText}
                style={{ width: "15%", minWidth: 60 }}
              >
                Name
              </TableCell>
              <TableCell
                className={classes.tableText}
                style={{ width: "15%", minWidth: 120 }}
              >
                Email
              </TableCell>
              <TableCell
                className={classes.tableText}
                style={{ width: "10%", minWidth: 80 }}
              >
                Phone
              </TableCell>
              <TableCell
                className={classes.tableText}
                style={{ width: "10%", minWidth: 50 }}
              >
                Experience
                <IconButton
                  className={classes.filterButton}
                  onClick={handleFeelingFilterClick}
                  size="small"
                >
                  <FilterListOutlinedIcon />
                </IconButton>
                <Menu
                  anchorEl={feelingFilterAnchor}
                  open={Boolean(feelingFilterAnchor)}
                  onClose={handleFeelingFilterClose}
                >
                  <MenuItem>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedFeelings.includes("loved")}
                          onChange={handleFeelingFilterChange}
                          value="loved"
                        />
                      }
                      label="Loved"
                    />
                  </MenuItem>
                  <MenuItem>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedFeelings.includes("happy")}
                          onChange={handleFeelingFilterChange}
                          value="happy"
                        />
                      }
                      label="Happy"
                    />
                  </MenuItem>
                  <MenuItem>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedFeelings.includes("ok")}
                          onChange={handleFeelingFilterChange}
                          value="ok"
                        />
                      }
                      label="Ok"
                    />
                  </MenuItem>
                  <MenuItem>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedFeelings.includes("sad")}
                          onChange={handleFeelingFilterChange}
                          value="sad"
                        />
                      }
                      label="Sad"
                    />
                  </MenuItem>
                  <MenuItem>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedFeelings.includes("angry")}
                          onChange={handleFeelingFilterChange}
                          value="angry"
                        />
                      }
                      label="Angry"
                    />
                  </MenuItem>
                </Menu>
              </TableCell>
              <TableCell
                className={classes.tableText}
                style={{ width: "35%", minWidth: 100 }}
              >
                Feedback
                <IconButton
                  className={classes.filterButton}
                  onClick={handleFeedbackFilterClick}
                  size="small"
                >
                  <FilterListOutlinedIcon />
                </IconButton>
                <Menu
                  anchorEl={feedbackFilterAnchor}
                  open={Boolean(feedbackFilterAnchor)}
                  onClose={handleFeedbackFilterClose}
                >
                  <MenuItem>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={showNoFeedback}
                          onChange={handleNoFeedbackFilterChange}
                          value="noFeedback"
                        />
                      }
                      label="No Feedback"
                    />
                  </MenuItem>
                </Menu>
              </TableCell>
              <TableCell
                className={classes.tableText}
                style={{ width: "15%", minWidth: 100 }}
              >
                Date
              </TableCell>
              <TableCell
                className={classes.tableText}
                style={{ width: "10%", minWidth: 80 }}
              >
                Option
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {filteredCustomers.length === 0 ? (
              <TableRow>
                <TableCell colSpan={7} className={classes.noData}>
                  <Typography>
                    No customer data available. Please try again later.
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              filteredCustomers
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((customerData) => (
                  <TableRow key={customerData.id}>
                    <TableCell component="th" scope="row">
                      {customerData.name}
                    </TableCell>
                    <TableCell>{customerData.email}</TableCell>
                    <TableCell>{customerData.phone}</TableCell>
                    <TableCell
                      style={{ textAlign: "center" }}
                      className={classes.feelingCell}
                    >
                      {customerData.lastFeeling === "loved" ? (
                        <img
                          src={lovedEmoji}
                          alt="Loved"
                          className={classes.smiley}
                        />
                      ) : customerData.lastFeeling === "happy" ? (
                        <img
                          src={happyEmoji}
                          alt="Happy"
                          className={classes.smiley}
                        />
                      ) : customerData.lastFeeling === "ok" ? (
                        <img
                          src={okEmoji}
                          alt="Ok"
                          className={classes.smiley}
                        />
                      ) : customerData.lastFeeling === "sad" ? (
                        <img
                          src={sadEmoji}
                          alt="Sad"
                          className={classes.smiley}
                        />
                      ) : customerData.lastFeeling === "angry" ? (
                        <img
                          src={angryEmoji}
                          alt="Angry"
                          className={classes.smiley}
                        />
                      ) : (
                        ""
                      )}
                    </TableCell>
                    <TableCell>
                      {customerData.feedback || "Not provided"}
                    </TableCell>
                    <TableCell>{customerData.visitDate}</TableCell>
                    <TableCell>
                      <Button
                        className={classes.Button}
                        variant="filled"
                        onClick={() =>
                          handleViewCustomerDetailsBtn(customerData)
                        }
                      >
                        View
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredCustomers.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        // onChangeRowsPerPage={handleChangeRowsPerPage}
      />

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredCustomers.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onRowsPerPageChange={handleChangeRowsPerPage} // Update this prop
        onPageChange={handleChangePage} // Update this prop
      />
    </div>
  );
}

export default CustomerTable;
