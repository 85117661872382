import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import {
  is2ndLastMonthData,
  is5thLastMonthData,
  is3rdLastMonthData,
  is4thLastMonthData,
  isLastMonthData,
  isCurrentMonthData,
} from "../../utils/checkMonth";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import Typography from "@mui/material/Typography";

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const BmCaChart = ({ selectedMonth, customerData }) => {
  const [chartData, setChartData] = useState(null); // Initialize as null

  const getDataSet = (dataArr) => {
    return [
      dataArr.filter((cd) => is5thLastMonthData(cd.timestamp)).length,
      dataArr.filter((cd) => is4thLastMonthData(cd.timestamp)).length,
      dataArr.filter((cd) => is3rdLastMonthData(cd.timestamp)).length,
      dataArr.filter((cd) => is2ndLastMonthData(cd.timestamp)).length,
      dataArr.filter((cd) => isLastMonthData(cd.timestamp)).length,
      dataArr.filter((cd) => isCurrentMonthData(cd.timestamp)).length,
    ];
  };

  useEffect(() => {
    if (customerData.length > 0) {
      const chart = () => {
        const today = new Date();
        const labels = [];

        for (let i = selectedMonth - 1; i >= 0; i--) {
          const month = new Date(today);
          month.setMonth(today.getMonth() - i);
          labels.push(month.toLocaleDateString("default", { month: "short" }));
        }

        const data = getDataSet(customerData);
        setChartData({
          labels: labels,
          datasets: [
            {
              label: "Number of Customers",
              data,
              backgroundColor: "rgba(75,192,192,0.2)",
              borderColor: "rgba(75,192,192,1)",
              borderWidth: 2,
            },
          ],
        });
      };

      chart();
    } else {
      setChartData(null); // Ensure chart data is set to null if customer data is not available
    }
  }, [selectedMonth, customerData]); // Depend on both customerData and selectedMonth

  return (
    <div style={{ display: "flex", height: "320px", marginTop: "20px" }}>
      {chartData ? (
        <Line data={chartData} options={{ responsive: true }} />
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%", // Full height of the container
          }}
        >
          <Typography variant="body2">
            No data is available to display the graph yet. Please check back
            later.
          </Typography>
        </div>
      )}
    </div>
  );
};

export default BmCaChart;
