import React, { useEffect } from "react";
import { Box, Container, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Page from "../../utils/Page";
import Lottie from "lottie-react";
import thankyouAnim from "../../utils/animations/thankyou.json";
import logo from "../../icons/logo.png";
import { useLocation, useNavigate } from "react-router-dom";
import heart from "../../utils/images/heart.png";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    background: `linear-gradient(135deg, ${theme.palette.primary.main} 20%, ${theme.palette.secondary.main} 90%)`,
  },
  logo: {
    width: 100,
    height: 100,
    borderRadius: "50%",
    boxShadow: "0 0 15px 5px rgba(255,255,255,0.7)",
    marginBottom: theme.spacing(2),
    userSelect: "none",
  },
  title: {
    fontSize: "2rem",
    fontWeight: "bold",
    color: "#fff",
    textShadow: "2px 2px 4px rgba(0,0,0,0.3)",
    textAlign: "center",
    userSelect: "none",
  },
  address: {
    fontSize: "1rem",
    color: "#fff",
    textShadow: "1px 1px 2px rgba(0,0,0,0.3)",
    textAlign: "center",
    userSelect: "none",
  },
  animationContainer: {
    width: 260,
    height: 260,
    margin: theme.spacing(1, 0), // Reduce gap above and below the animation
    userSelect: "none",
  },
  message: {
    color: "#fff",
    textAlign: "center",
    fontSize: "1rem",
    fontFamily: "'Roboto', sans-serif",
    textShadow: "2px 2px 8px rgba(0, 0, 0, 0.5)",
    userSelect: "none",
  },
  smiley: {
    width: "24px",
    userSelect: "none",
  },
}));

const ThankYouTabletMode = () => {
  const classes = useStyles();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/customer-view-tablet-mode/" + state.merchantId, {
        replace: true,
      });
    }, 8000);

    return () => clearTimeout(timer);
  }, [state, navigate]);

  return (
    <Page className={classes.root} title={t("THANK_YOU")}>
      <Container maxWidth="sm">
        <Box display="flex" flexDirection="column" alignItems="center">
          {state.logo ? (
            <img
              src={state.logo}
              alt={t("BUSINESS_NAME")}
              className={classes.logo}
            />
          ) : (
            <img src={logo} alt={t("BUSINESS_NAME")} className={classes.logo} />
          )}
          <Typography className={classes.title}>
            {state.name || t("BUSINESS_NAME")}
          </Typography>
          <Typography className={classes.address}>
            {state.address || ""}
          </Typography>

          <Box className={classes.animationContainer}>
            <Lottie animationData={thankyouAnim} />
          </Box>
          <Typography className={classes.message}>
            {t("THANK_YOU_MESSAGE", {
              businessName: state.name || t("BUSINESS_NAME"),
            })}
            {/* <span>
              <img src={heart} alt="heart" className={classes.smiley} />
            </span> */}
          </Typography>
        </Box>
      </Container>
    </Page>
  );
};

export default ThankYouTabletMode;
