import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Container,
  Typography,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import SearchLocation from "../../components/Maps/SearchLocation";
import { SnackbarProvider, useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import IconButton from "@mui/material/IconButton";
import RefreshIcon from "@mui/icons-material/Refresh";
import Page from "../../utils/Page";
import { Link } from "react-feather";
import {
  BusinessOutlined,
  LocationSearchingOutlined,
  Save,
} from "@mui/icons-material";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import storage from "../../config/firebaseStorage";
import firebase from "../../config/firebase";
import Snackbar from "@mui/material/Snackbar";
import Logo from "../../icons/logo.png";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  title: {
    color: "#000000",
    paddingBottom: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  marginTp: {
    marginTop: theme.spacing(2),
  },
  marginBtm: {
    marginBottom: theme.spacing(2),
  },
  select: {
    borderRadius: 25,
    backgroundColor: "white",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    "&:focus": {
      borderRadius: 25,
      backgroundColor: "white",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "150px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      maxWidth: "200px",
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: "300px",
    },
  },
  menuItem: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  logoContainer: {
    textAlign: "center",
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  logo: {
    width: 80,
    height: 80,
    borderRadius: "50%",
    boxShadow: "0px 0px 15px 5px rgba(255,255,255,0.7)",
  },
}));

const SettingsView = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const [fieldValues, setFieldValues] = useState({
    merchantID: "",
    businessName: "",
    businessAddress: "",
    businessType: "",
    googleReviewUrl: "",
    googlePlaceID: "",
    businessLogoUrl: "",
    website: "",
    initGoogleRating: 0.0,
    initGoogleReviews: 0,
    lati: "",
    longi: "",
    urls: {
      facebook: "",
      instagram: "",
      tiktok: "",
      tripadvisor: "",
      yellowpages: "",
      yelp: "",
    },
    settings: {
      collectCustDetails: true,
      fullCustDetails: false,
    },
  });

  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isDigitalCouponsEnabled, setIsDigitalCouponsEnabled] = useState(false);
  const [imageAsUrl, setImageAsUrl] = useState("");
  const [mapData, setMapData] = useState(null);
  const navigate = useNavigate();

  const handleCollectCustDetailsChange = (event) => {
    setFieldValues((prevState) => ({
      ...prevState,
      settings: {
        ...prevState.settings,
        collectCustDetails: event.target.value === true,
      },
    }));
  };

  const handleFullCustDetailsChange = (event) => {
    setFieldValues((prevState) => ({
      ...prevState,
      settings: {
        ...prevState.settings,
        fullCustDetails: event.target.value === true,
      },
    }));
  };

  const handleIsSubscribedChange = (event) => {
    setIsSubscribed(event.target.value);
  };

  const handleIsDigitalCouponsEnabledChange = (event) => {
    setIsDigitalCouponsEnabled(event.target.value);
  };

  const handleRefreshGooglePlaceId = () => {
    // Perform any necessary actions to refresh the Google Place ID
    // For example, you can make an API call to get the latest Google Place ID based on the location name using Google API
    // Once you have the updated Google Place ID, you can update the state accordingly

    // Placeholder code for demonstration purposes
    console.log("Refreshing Google Place ID...");
  };

  useEffect(() => {
    getMerchantData(id);
  }, []);

  function createReviewURL(data) {
    var baseURL = "https://search.google.com/local/writereview?placeid=";
    return baseURL + data;
  }

  useEffect(() => {
    if (mapData) {
      const {
        name,
        formatted_address,
        place_id,
        geometry,
        website,
        rating,
        user_ratings_total,
      } = mapData;
      const lati = geometry?.location?.lat() ?? "";
      const longi = geometry?.location?.lng() ?? "";
      const googleReviewUrl = createReviewURL(place_id);

      setFieldValues((prevState) => ({
        ...prevState,
        businessName: name,
        businessAddress: formatted_address,
        googlePlaceID: place_id,
        lati: lati,
        longi: longi,
        website: website ?? "Not Available",
        googleReviewUrl: googleReviewUrl,
        initGoogleRating: rating,
        initGoogleReviews: user_ratings_total,
      }));
    }
  }, [mapData]);

  const handleLogoAsFile = (e) => {
    const image = e.target.files[0];

    // async magic goes here...
    if (image === "") {
      console.error(`not an image, the image file is a ${typeof image}`);
    }

    const uploadTask = storage
      .ref(`/businessLogos/${fieldValues.merchantID}`)
      .put(image);
    //initiates the firebase side uploading
    uploadTask.on(
      "state_changed",
      (snapShot) => {
        // console.log(snapShot)
      },
      (err) => {
        //catches the errors
        console.log(err);
      },
      () => {
        storage
          .ref("businessLogos")
          .child(fieldValues.merchantID)
          .getDownloadURL()
          .then((fireBaseUrl) => {
            //console.log(fireBaseUrl)
            setImageAsUrl(fireBaseUrl);
            setFieldValues({
              businessLogoUrl: fireBaseUrl,
            });
          });
      }
    );
  };

  const constructSocialMediaUrl = (platform, accountName) => {
    const trimmedAccountName = accountName.trim();
    if (!trimmedAccountName) return ""; // Directly return empty if input is effectively empty

    // Check if the accountName already includes a platform URL
    if (
      trimmedAccountName.startsWith(`https://www.${platform}.com/`) ||
      (platform === "tiktok" &&
        trimmedAccountName.startsWith("https://www.tiktok.com/@"))
    ) {
      return trimmedAccountName; // Return the full URL if it's already correct
    }

    // Construct URL based on the platform
    switch (platform) {
      case "facebook":
        return `https://www.facebook.com/${trimmedAccountName}`;
      case "instagram":
        return `https://www.instagram.com/${trimmedAccountName}`;
      case "tiktok":
        return `https://www.tiktok.com/@${trimmedAccountName}`;
      default:
        return ""; // Return empty string if platform is not recognized
    }
  };

  const getMerchantData = (merchantId) => {
    const locations = [];
    //set merchant docs data from firebase
    try {
      firebase.getMerchantDetails(merchantId).then((data) => {
        if (data !== undefined) {
          setFieldValues({
            merchantID: merchantId,
            businessName: data.businessName,
            businessAddress: data.businessAddress,
            googleReviewUrl: data.googleReviewUrl,
            lati: data.location[0],
            longi: data.location[1],
            googlePlaceID: data.googlePlaceID,
            website: data.website ?? "Not Available",
            businessLogoUrl: data.businessLogoUrl,
            initGoogleRating: data.initGoogleRating ?? 0.0,
            initGoogleReviews: data.initGoogleReviews ?? 0,
            urls: {
              facebook: data.urls?.facebook || "",
              instagram: data.urls?.instagram || "",
              tiktok: data.urls?.tiktok || "",
              tripadvisor: data.urls?.tripadvisor || "",
              yellowpages: data.urls?.yellowpages || "",
              yelp: data.urls?.yelp || "",
            },
            settings: {
              collectCustDetails: data.settings.collectCustDetails,
              fullCustDetails: data.settings.fullCustDetails,
            },
          });
          setImageAsUrl(data.businessLogoUrl);
        } else {
          console.log("Merchant not found " + merchantId);
          navigate("/not-found");
        }
      });
    } catch (error) {
      console.log(error.message);
      navigate("/not-found");
    }
  };

  const handleSubmition = async (e) => {
    e.preventDefault();
    try {
      //console.log("Submitting data:", fieldValues);
      await firebase.updateMerchantBusinessSettings(fieldValues);
      enqueueSnackbar("Business Profile is updated", {
        variant: "success",
        autoHideDuration: 3000,
      });
      //console.log("Merchant details updated successfully");
    } catch (error) {
      enqueueSnackbar("Something went wrong. Please try again.", {
        variant: "error",
        autoHideDuration: 3000,
      });
      //console.error("Error updating details:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (
      [
        "facebook",
        "instagram",
        "tiktok",
        "yellowpages",
        "tripadvisor",
        "yelp",
      ].includes(name)
    ) {
      if (["facebook", "instagram", "tiktok"].includes(name)) {
        const url = constructSocialMediaUrl(name, value);
        setFieldValues((prevState) => ({
          ...prevState,
          urls: {
            ...prevState.urls,
            [name]: url,
          },
        }));
      } else {
        setFieldValues((prevState) => ({
          ...prevState,
          urls: {
            ...prevState.urls,
            [name]: value,
          },
        }));
      }
    } else {
      setFieldValues((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  return (
    <Page className={classes.root} title="Settings">
      <Box sx={{ flexGrow: 1, p: 3 }}>
        <Typography variant="h4" sx={{ fontWeight: "bold" }} gutterBottom>
          Customize Settings
        </Typography>

        <Typography variant="body2">
          You can customize various aspects of your merchant profile. Update
          your business name, address, website, and other details as needed.
          Simply fill in the fields below and click "Save Data" to apply your
          changes.
        </Typography>
        <Container maxWidth="md" sx={{ mt: 4 }}>
          <form onSubmit={handleSubmition} autoComplete="off">
            <Box
              display="flex"
              flexDirection="column"
              height="100%"
              justifyContent="center"
              alignItems="center"
              p={2}
            >
              <Typography variant="h5" gutterBottom>
                Business Profile Settings
              </Typography>
              <div className={classes.logoContainer}>
                {imageAsUrl !== "" ? (
                  <img
                    src={imageAsUrl}
                    alt="Business Logo"
                    className={classes.logo}
                  />
                ) : (
                  <img
                    src={Logo}
                    alt="Business Logo"
                    className={classes.logo}
                  />
                )}
              </div>

              <Button
                className={classes.marginBtm}
                variant="outlined"
                component="label"
                color="primary"
                startIcon={<PhotoCamera />}
              >
                Upload Business Logo
                <input
                  type="file"
                  id="businessLogo"
                  name="businessLogo"
                  onChange={handleLogoAsFile}
                  hidden
                />
              </Button>
            </Box>
            <TextField
              fullWidth
              label="Merchant ID"
              margin="normal"
              name="merchantID"
              type="text"
              value={id}
              variant="outlined"
              disabled
            />
            <Box display="flex" alignItems="center" marginTop={1}>
              <TextField
                fullWidth
                label="Business Place ID"
                margin="normal"
                name="googlePlaceId"
                type="text"
                value={fieldValues.googlePlaceID}
                variant="outlined"
                disabled
              />
              <IconButton
                color="primary"
                onClick={handleRefreshGooglePlaceId}
                aria-label="refresh"
              >
                <RefreshIcon />
              </IconButton>
            </Box>
            <TextField
              fullWidth
              label="Business Name"
              margin="normal"
              name="businessName"
              type="text"
              value={fieldValues.businessName}
              variant="outlined"
              onChange={handleChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <BusinessOutlined />
                  </InputAdornment>
                ),
              }}
            />
            <SearchLocation
              lati={fieldValues.lati}
              longi={fieldValues.longi}
              passChildData={setMapData}
            />

            <TextField
              fullWidth
              label="Business Address"
              margin="normal"
              name="businessAddress"
              type="text"
              value={fieldValues.businessAddress}
              variant="outlined"
              onChange={handleChange}
              disabled={true}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LocationSearchingOutlined />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              fullWidth
              label="Website"
              margin="normal"
              name="website"
              type="text"
              placeholder="Enter your website url"
              value={fieldValues.website}
              autoComplete="new-password"
              variant="outlined"
              onChange={handleChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Link />
                  </InputAdornment>
                ),
              }}
            />

            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              marginTop={4}
            >
              <Typography variant="h5" gutterBottom>
                Other Business Profiles
              </Typography>
              <TextField
                fullWidth
                label="Facebook Page Name"
                margin="normal"
                name="facebook"
                type="text"
                value={fieldValues.urls.facebook}
                variant="outlined"
                onChange={handleChange}
              />
              <TextField
                fullWidth
                label="Instagram Account Name"
                margin="normal"
                name="instagram"
                type="text"
                value={fieldValues.urls.instagram}
                variant="outlined"
                onChange={handleChange}
              />
              <TextField
                fullWidth
                label="TikTok Account Name"
                margin="normal"
                name="tiktok"
                type="text"
                value={fieldValues.urls.tiktok}
                variant="outlined"
                onChange={handleChange}
              />
              <TextField
                fullWidth
                label="Yellow Pages URL"
                margin="normal"
                name="yellowpages"
                type="text"
                value={fieldValues.urls.yellowpages}
                variant="outlined"
                onChange={handleChange}
              />
              <TextField
                fullWidth
                label="TripAdvisor URL"
                margin="normal"
                name="tripadvisor"
                type="text"
                value={fieldValues.urls.tripadvisor}
                variant="outlined"
                onChange={handleChange}
              />
              <TextField
                fullWidth
                label="Yelp URL"
                margin="normal"
                name="yelp"
                type="text"
                value={fieldValues.urls.yelp}
                variant="outlined"
                onChange={handleChange}
              />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              marginTop={4}
            >
              <Typography variant="h5" gutterBottom>
                Customer Configuration
              </Typography>
              <Box marginTop={2} width="100%">
                <Typography>Collect Customer Feedback</Typography>
                <Select
                  fullWidth
                  name="collectCustDetails"
                  value={fieldValues.settings.collectCustDetails}
                  onChange={handleCollectCustDetailsChange}
                >
                  <MenuItem value={"true"}>Yes</MenuItem>
                  <MenuItem value={"false"}>No</MenuItem>
                </Select>
              </Box>
              <Box marginTop={2} width="100%">
                <Typography>Collect Full Customer Details</Typography>
                <Select
                  name="fullCustDetails"
                  fullWidth
                  value={fieldValues.settings.fullCustDetails}
                  onChange={handleFullCustDetailsChange}
                >
                  <MenuItem value={"true"}>Yes</MenuItem>
                  <MenuItem value={"false"}>No</MenuItem>
                </Select>
              </Box>
            </Box>

            <Box display="flex" justifyContent="center" marginTop={2}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                startIcon={<Save />}
              >
                Save Data
              </Button>
            </Box>
          </form>
        </Container>
      </Box>
    </Page>
  );
};

export default SettingsView;
