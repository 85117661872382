import React, { useEffect, useState } from "react";
import axios from "axios";

const GoogleOAuthCallback = () => {
  const [authorizationCode, setAuthorizationCode] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [saveError, setSaveError] = useState("");

  useEffect(() => {
    // Log for debugging: Check if the code is present in the URL
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get("code");

    if (code) {
      // Set the authorization code to state to display it
      setAuthorizationCode(code);
      console.log("Authorization Code:", code);

      // Start the process to exchange the code for tokens and save them
      saveTokens(code);
    } else {
      console.error("No authorization code found in URL");
      setSaveError("No authorization code found.");
    }
  }, []);

  const saveTokens = async (authorizationCode) => {
    setIsSaving(true);
    try {
      // Send the authorization code to your server to exchange for tokens and save them
      const response = await axios.post("/exchange-code-for-tokens", {
        code: authorizationCode,
      });

      if (response.data.success) {
        console.log("Tokens saved successfully.");
      } else {
        const logs = response.data.logs;
        if (logs && Array.isArray(logs)) {
          console.error("Failed to save tokens. Server logs:", logs);
          setSaveError(`Failed to save tokens. Logs: ${logs.join("\n")}`);
        } else {
          console.error("Failed to save tokens. No logs provided.");
          setSaveError("Failed to save tokens. No logs provided.");
        }
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.logs) {
        const logs = error.response.data.logs;
        console.error("Error saving tokens. Server logs:", logs);
        setSaveError(`Error saving tokens. Logs: ${logs.join("\n")}`);
      } else {
        console.error("Error saving tokens:", error);
        setSaveError("Error saving tokens. No logs found.");
      }
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div>
      <h2>Processing OAuth callback...</h2>
      {authorizationCode ? (
        <p>Authorization Code: {authorizationCode}</p>
      ) : (
        <p>No authorization code found.</p>
      )}
      {isSaving && <p>Saving tokens...</p>}
      {saveError && <p>Error: {saveError}</p>}
    </div>
  );
};

export default GoogleOAuthCallback;
