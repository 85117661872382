import React, { useContext } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { LogOut, Users } from "react-feather";
import { AiFillHome } from "react-icons/ai";
import { FaGoogle, FaMoneyBillWave, FaUsers } from "react-icons/fa";
import {
  MdAnnouncement,
  MdCardGiftcard,
  MdLocalOffer,
  MdSettings,
} from "react-icons/md";
import NavItem from "./NavItem";
import firebase from "../../../../config/firebase";
import MerchantContext from "../../../../config/MerchantContext";

const items = [
  {
    href: "/app/dashboard",
    icon: AiFillHome,
    title: "Business Metrics",
    enabled: true,
  },
  {
    href: "/app/manage-google-reviews",
    icon: FaGoogle,
    title: "Manage Google Reviews",
    enabled: true,
  },
  {
    href: "/app/customers",
    icon: FaUsers,
    title: "Customers",
    enabled: true,
  },
  {
    href: "/app/coupons",
    icon: MdLocalOffer,
    title: "Coupon Management",
    enabled: true,
  },
  {
    href: "/app/giftcards",
    icon: MdCardGiftcard,
    title: "Gift Cards",
    enabled: false,
  },
  {
    href: "/app/promotions",
    icon: MdAnnouncement,
    title: "Promotions Marketing",
    enabled: true,
  },
  {
    href: "/app/subscription",
    icon: FaMoneyBillWave,
    title: "Subscription",
    enabled: true,
  },
  {
    href: "/app/employees",
    icon: Users,
    title: "Employees",
    enabled: false,
  },
  {
    href: "/app/settings",
    icon: MdSettings,
    title: "Settings",
    enabled: true,
  },
  {
    href: "",
    icon: LogOut,
    title: "Logout",
    enabled: true,
  },
];

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 266,
  },
  desktopDrawer: {
    width: 266,
    height: "calc(100%)",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  currentPlan: {
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.common.white,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(0.5, 2),
    marginTop: theme.spacing(1),
  },
}));

const NavBar = ({ onMobileClose = () => {}, openMobile = false }) => {
  const classes = useStyles();
  const location = useLocation();
  const { currentMerchant, plan } = useContext(MerchantContext);

  const handleLogoutClick = () => {
    onMobileClose();
    logout();
  };

  const externalLinkClick = () => {
    window.open(
      "http://www.reviewplusapp.com",
      "_blank",
      "noopener,noreferrer"
    );
  };

  const getPlanName = (planCode) => {
    switch (planCode) {
      case "BASIC_01":
        return "Basic";
      case "STAN_01":
        return "Standard";
      case "PREMIUM_01":
        return "Premium";
      case "TRAIL_01":
        return "Trial";
      default:
        return "Unknown Plan";
    }
  };

  async function logout() {
    try {
      await firebase.logout();
      sessionStorage.removeItem("currentMerchant");
      sessionStorage.removeItem("merchantData");
      sessionStorage.removeItem("selectedLocation");
      window.location.reload();
    } catch (error) {
      alert(error.message);
    }
  }

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box alignItems="center" display="flex" flexDirection="column" p={2}>
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          src="/static/images/logo.png"
          to="/app/dashboard"
        />
        <Typography color="textPrimary" variant="h5">
          <strong> Review+ Dashboard </strong>
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {currentMerchant?.email || ""}
        </Typography>
        <Box className={classes.currentPlan}>
          <Typography variant="body2">
            Current Plan: {getPlanName(plan)}
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box p={2}>
        <List>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
              enabled={item.enabled}
              onClick={
                item.title !== "Logout" ? onMobileClose : handleLogoutClick
              }
              selected={location.pathname === item.href}
            />
          ))}
        </List>
      </Box>
      <Box flexGrow={1} />
      <Box p={2} mb={2} bgcolor="background.default">
        <Typography align="center" variant="body2">
          info@tezooo.com
        </Typography>
        <Box display="flex" justifyContent="center" mt={2}>
          <Button
            color="primary"
            onClick={externalLinkClick}
            variant="contained"
          >
            Visit Review+
          </Button>
        </Box>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
