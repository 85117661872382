import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    background: {
      dark: "#F0F0F0", // Light Gray Background for Dark Mode
      default: "#FFFFFF", // White Background
      paper: "#FFFFFF", // White Paper Background
      gradient: "linear-gradient(135deg, #B5EAEA 20%, #F38BA0 95%)", // New gradient
    },
    primary: {
      main: "#B5EAEA", // Primary color
      light: "#EDF6E5", // Light variant of primary
      dark: "#8AC3C3", // Darker variant of primary (adjusted for better contrast)
      lighter: "#E0F7F7", // Even lighter variant of primary
      darker: "#629393", // Even darker variant of primary (adjusted for better contrast)
    },
    secondary: {
      main: "#FFBCBC", // Secondary color
      light: "#F38BA0", // Light variant of secondary
      dark: "#CC9696", // Darker variant of secondary (adjusted for better contrast)
      lighter: "#FFD9D9", // Even lighter variant of secondary
      darker: "#b53b33", // Even darker variant of secondary (adjusted for better contrast)
    },
    accent: {
      main: "#F38BA0", // Accent color
    },
    highlight: {
      main: "#EDF6E5", // Highlight color
    },
    text: {
      primary: "#333333", // Dark Text
      secondary: "#666666", // Gray Text
      light: "#FFFFFF", // Light Text
    },
  },
});

export default theme;
