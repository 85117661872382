import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import LoginForm from "../auth/LoginForm";
import Logo from "../../icons/logo.png";

const LoginView = () => {
  return (
    <div className="main-page-wrapper p0">
      <Helmet>
        <title>Merchant Login | Review+ Access Dashboard</title>
      </Helmet>
      {/* End Page SEO Content */}
      <div className="user-data-page clearfix d-lg-flex">
        <div className="illustration-wrapper d-flex align-items-center justify-content-between flex-column">
          {/* <h3 className="font-rubik">
            Want your best managment <br />
            software? <Link to="/signup">sign up</Link>
          </h3> */}
          <div className="illustration-holder">
            <img
              src="images/assets/ils-01.png"
              alt="illustration"
              className="illustration"
            />
            <img
              src="images/assets/ils-01.1.png"
              alt="illustration"
              className="shapes shape-one"
            />

            <img
              src="images/assets/ils-01.3.png"
              alt="shape"
              className="shapes shape-three"
            />
          </div>
        </div>
        {/* /.illustration-wrapper */}

        <div className="form-wrapper">
          <div className="d-flex justify-content-between align-items-center">
            <div className="logo-container">
              <div className="d-flex align-items-center logo-link">
                <img height="60px" src={Logo} alt="Review+ logo" />
                <span className="logo-text">Review+ Dashboard</span>
              </div>
            </div>
            <Link className="font-rubik go-back-button" to="/home">
              Go to home
            </Link>
          </div>
          <div className="mt-80 md-mt-40 pb-50">
            <h2>
              Hey, Welcome <br /> Back!
            </h2>
            {/* <p className="header-info pt-30 pb-50">
              Still don't have an account? <Link to="/signup">Sign Up</Link>
            </p> */}
          </div>

          <LoginForm />
          {/* Login Form End */}
          <p className="text-center font-rubik copyright-text">
            Copyright @{new Date().getFullYear()}{" "}
            <a
              href="https://reviewplusapp.com"
              target="_blank"
              title="myFrame"
              rel="noreferrer"
            >
              Review+
            </a>{" "}
          </p>
        </div>
        {/* /.form-wrapper */}
      </div>
      {/* /.user-data-page */}
    </div>
  );
};

export default LoginView;
