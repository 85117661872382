import React, { useState, useEffect } from "react";
import { Box, Container, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Page from "../../utils/Page";
import Lottie from "lottie-react";
import thankyouAnim from "../../utils/animations/thankyou.json";
import logo from "../../icons/logo.png";
import { useLocation } from "react-router-dom";
import heart from "../../utils/images/heart.png";
import { createBrowserHistory } from "history";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    background: `linear-gradient(to bottom right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
  },
  logo: {
    width: 100,
    height: 100,
    borderRadius: "50%",
    boxShadow: "0 0 15px 5px rgba(255,255,255,0.7)",
    marginBottom: theme.spacing(2),
    userSelect: "none",
  },
  title: {
    fontSize: "2rem",
    fontWeight: "bold",
    color: "#fff",
    textShadow: "2px 2px 4px rgba(0,0,0,0.3)",
    textAlign: "center",
    userSelect: "none",
  },
  address: {
    fontSize: "1rem",
    color: "#fff",
    textShadow: "1px 1px 2px rgba(0,0,0,0.3)",
    textAlign: "center",
    userSelect: "none",
  },
  animationContainer: {
    width: 260,
    height: 260,
    margin: theme.spacing(1, 0), // Reduce gap above and below the animation
    userSelect: "none",
  },
  message: {
    color: "#fff",
    textAlign: "center",
    fontSize: "1rem",
    fontFamily: "'Roboto', sans-serif",
    textShadow: "2px 2px 8px rgba(0, 0, 0, 0.5)",
    userSelect: "none",
  },
  smiley: {
    width: "24px",
    userSelect: "none",
  },
}));

const SetupCompleteThankYou = () => {
  const classes = useStyles();
  const { state } = useLocation();
  const history = createBrowserHistory();

  useEffect(() => {
    //send a confirmation email that registration is done.
    setTimeout(() => {
      history.push("/login");
      window.location.reload();
    }, 10000);
    //redirect to login page after 10 seconds
  }, [history]);

  return (
    <Page className={classes.root} title="Thank You">
      <Container maxWidth="sm">
        <Box display="flex" flexDirection="column" alignItems="center">
          {state?.logo ? (
            <img
              src={state.logo}
              alt="Business Logo"
              className={classes.logo}
            />
          ) : (
            <img src={logo} alt="Business Logo" className={classes.logo} />
          )}
          <Typography className={classes.title}>
            {" "}
            {state?.name || "Business"}
          </Typography>
          <Typography className={classes.address}>
            {" "}
            {state?.address || ""}
          </Typography>

          <Box className={classes.animationContainer}>
            <Lottie animationData={thankyouAnim} />
          </Box>
          <Typography className={classes.message}>
            Thank you for completing your registration! You can now login to
            your Dashboard to access Reviews, Business Insights, Promotions,
            Digi cards, Customer Experiences and much more! Please wait 10
            seconds to redirect...
          </Typography>
        </Box>
      </Container>
    </Page>
  );
};

export default SetupCompleteThankYou;
