import React from "react";
import { Link } from "react-router-dom";

const HomeDropdown = [
  {
    img: "home01",
    routerPath: "/faq",
    inenerText: "",
    inenerTextWrapClass: "",
    clasName: `dropdown-item img-box`,
    title: "Frequently Asked Questions",
  },
  {
    img: "home02",
    routerPath: "/blog",
    inenerText: "",
    inenerTextWrapClass: "",
    clasName: `dropdown-item img-box`,
    title: "Blog",
  },
];

const MegaMenuLanding = () => {
  return (
    <ul className="mega-menu d-flex ">
      {HomeDropdown.map((val, i) => (
        <li key={i}>
          <Link to={val.routerPath} className={val.clasName}>
            <img src={`images/menu/${val.img}.png`} alt="home-demo" />
            <span className="font-rubik">{val.title}</span>
            <div className={val.inenerTextWrapClass}>
              <div className="font-rubik">{val.inenerText}</div>
            </div>
          </Link>
        </li>
      ))}
    </ul>
  );
  // End navbar nav mega menu main
};

export default MegaMenuLanding;
