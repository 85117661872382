import moment from "moment";

export const isLastMonthData = (date) => {
  const d1 = new Date(date);
  const d2 = new Date();

  const diffInMonth = moment(d2).diff(d1, "months");

  return diffInMonth === 1;
};

export function isCurrentMonthData(date) {
  const d1 = new Date(date);
  const d2 = new Date();

  const diffInMonth = moment(d2).diff(d1, "months");

  return diffInMonth === 0;
}

export const is2ndLastMonthData = (date) => {
  const d1 = new Date(date);
  const d2 = new Date();

  const diffInMonth = moment(d2).diff(d1, "months");

  return diffInMonth === 2;
};

export const is3rdLastMonthData = (date) => {
  const d1 = new Date(date);
  const d2 = new Date();

  const diffInMonth = moment(d2).diff(d1, "months");

  return diffInMonth === 3;
};

export const is4thLastMonthData = (date) => {
  const d1 = new Date(date);
  const d2 = new Date();
  const diffInMonth = moment(d2).diff(d1, "months");

  return diffInMonth === 4;
};

export const is5thLastMonthData = (date) => {
  const d1 = new Date(date);
  const d2 = new Date();
  const diffInMonth = moment(d2).diff(d1, "months");

  return diffInMonth === 5;
};
