import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Container,
  TextField,
  InputAdornment,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  Autocomplete,
  DialogContentText,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Page from "../../utils/Page";
import classNames from "classnames";
import ArrowForward from "@mui/icons-material/ArrowForward";
import SearchLocation from "../../components/Maps/SearchLocation";
import { Link, User } from "react-feather";
import {
  BusinessOutlined,
  LocationSearchingOutlined,
  Business,
} from "@mui/icons-material";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import storage from "../../config/firebaseStorage";
import logo from "../../icons/logo.png";
//import Autocomplete from "@mui/lab/Autocomplete";
import { generateUniqueId } from "../../utils/GenerateUniqueId";
import { sendRegistrationEmail } from "../../config/cloudApis";
import firebase from "../../config/firebase";
require("../../App.css");

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    background: `linear-gradient(135deg, ${theme.palette.primary.main} 30%, ${theme.palette.secondary.main} 90%)`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    animation: "$fadeIn 2s",
    position: "relative",
    userSelect: "none",
  },
  "@keyframes fadeIn": {
    "0%": { opacity: 0 },
    "100%": { opacity: 1 },
  },
  glowingIcon: {
    margin: theme.spacing(1, 0),
    animation: "$glow 2.5s infinite",
    borderRadius: "50%",
    width: "110px",
    height: "110px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0px 0px 20px rgba(255, 255, 255, 0.7)",
    position: "relative",
    userSelect: "none",
  },
  "@keyframes glow": {
    "0%": { boxShadow: "0 0 5px rgba(255, 255, 255, 0.5)" },
    "50%": { boxShadow: "0 0 20px rgba(255, 255, 255, 1)" },
    "100%": { boxShadow: "0 0 5px rgba(255, 255, 255, 0.5)" },
  },
  h3title: {
    color: "#ffffff",
    fontSize: 18,
    userSelect: "none",
  },
  subtitle: {
    fontSize: 16,
    userSelect: "none",
  },
  errorText: {
    color: theme.palette.error.main,
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  marginTp: {
    marginTop: theme.spacing(2),
  },
  marginBtm: {
    marginBottom: theme.spacing(2),
  },
  iconSmall: {
    fontSize: 20,
  },
  typography: {
    padding: theme.spacing(2),
  },
  footer: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    textAlign: "center",
    color: "#fff",
    fontSize: "0.8rem",
    opacity: 0.8,
    width: "100%",
    userSelect: "none",
  },
  mapWidget: {
    borderRadius: 4,
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
    background: "#fff",
    marginBottom: theme.spacing(2),
  },
  lottieContainer: {
    marginBottom: theme.spacing(3),
  },
  inputField: {
    marginTop: theme.spacing(2),
    width: "100%",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black", // Changed border color to black
      },
      "&:hover fieldset": {
        borderColor: "black", // Changed border color to black
      },
      "&.Mui-focused fieldset": {
        borderColor: "black", // Changed border color to black
      },
      background: "rgba(255, 255, 255, 0.1)",
      backdropFilter: "blur(10px)",
      borderRadius: "10px",
      color: "black", // Changed font color to black
    },
    "& .MuiInputLabel-root": {
      color: "black", // Changed font color to black
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "black", // Changed font color to black
    },
    "& .MuiInputAdornment-root svg": {
      color: "black", // Changed icon color to black
    },
  },
}));

const MerchantOnBoard = () => {
  const classes = useStyles();
  const [fieldValues, setFieldValues] = useState({
    merchantID: "",
    businessName: "",
    businessAddress: "",
    businessType: "Not Available",
    businessLogoUrl:
      "https://firebasestorage.googleapis.com/v0/b/reviews-plus-8779c.appspot.com/o/businessLogos%2Flogo.png?alt=media&token=152350ef-963f-4483-8adc-0e763abad2d9",
    googlePlaceID: "",
    googleMyBusinessUrl: "",
    initGoogleReviews: "0",
    initGoogleRating: "0",
    website: "Not Available",
    lat: "",
    lng: "",
    ownerName: "",
    ownerPhone: "",
    ownerEmail: "",
    createdTimeStamp: Date.now(),
  });
  const [hasAddress, setHasAddress] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [mapData, setMapData] = useState(null);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [dialogContentText, setDialogContentText] = useState(
    "Are you sure you want to create this merchant profile and send a registration email to the provided email address?"
  );
  const [isRefreshRequired, setIsRefreshRequired] = useState(false);

  const defaultBusinessTypes = [
    "Auto Parts Shop",
    "Bakery",
    "Bar",
    "Beauty Salon & Barber Shop",
    "Business",
    "Book Store",
    "Candy Shop",
    "Cigar & Vape",
    "Contractor",
    "Dentist & Orthodontist",
    "Dry Cleaning & Laundry",
    "Electronics Store",
    "Financial Services",
    "Florist",
    "Full Service Restaurant",
    "Furniture Store",
    "Golf Course",
    "Grocery Convenience Store",
    "Hardware Store",
    "Household Items & Furnishings",
    "Jewelry Store",
    "Liquor Store",
    "Medical Services",
    "Membership Club",
    "Non Profit",
    "Other Retail",
    "Pet Shop",
    "Professional Services",
    "Quick Service Restaurant",
    "Religious Org",
    "Repair Shop",
    "Salon & Spa",
    "School Education",
    "Shoe Repair & Shine",
    "Shoe Store",
    "Taxi",
    "Toy & Hobby Shop",
    "Not Available",
  ];

  const [imageAsUrl, setImageAsUrl] = useState("");

  useEffect(() => {
    const newMerchantID = generateUniqueId();
    setFieldValues((prevState) => ({
      ...prevState,
      merchantID: newMerchantID,
    }));
  }, []);

  useEffect(() => {
    const allFieldsFilled =
      fieldValues.businessName &&
      fieldValues.businessAddress &&
      fieldValues.ownerEmail &&
      isValidEmailAddress(fieldValues.ownerEmail);
    setIsSubmitDisabled(!allFieldsFilled);
  }, [fieldValues]);

  const handleLogoAsFile = (e) => {
    const image = e.target.files[0];

    if (!image) {
      console.error(`not an image, the image file is a ${typeof image}`);
      return;
    }

    const uploadTask = storage
      .ref(`/businessLogos/${fieldValues.merchantID}`)
      .put(image);
    uploadTask.on(
      "state_changed",
      (snapShot) => {
        // console.log(snapShot)
      },
      (err) => {
        console.log(err);
      },
      () => {
        storage
          .ref("businessLogos")
          .child(fieldValues.merchantID)
          .getDownloadURL()
          .then((fireBaseUrl) => {
            setFieldValues((prevState) => ({
              ...prevState,
              businessLogoUrl: fireBaseUrl,
            }));
            setImageAsUrl(fireBaseUrl);
          });
      }
    );
  };

  const handleSubmition = (e) => {
    e.preventDefault();
    setDialogContentText(
      "Are you sure you want to create this merchant profile and send a registration email to the provided email address?"
    );
    setIsConfirmationOpen(true);
  };

  const handleConfirmSubmission = async () => {
    try {
      await firebase.createMerchantProfile(fieldValues);
      const result = await sendRegistrationEmail(fieldValues);

      if (result) {
        setDialogContentText(
          "A registration link has been sent to the provided email."
        );
        setIsConfirmationOpen(true);
        setIsRefreshRequired(true);
      } else {
        setDialogContentText("Failed to send registration email.");
        setIsConfirmationOpen(true);
      }
    } catch (error) {
      console.log(error);
      setDialogContentText(
        "An error occurred while creating the merchant profile."
      );
    } finally {
      setIsConfirmationOpen(true);
    }
  };

  const handleChange = (data) => {
    let { name, value } = data.target;

    if (name === "ownerPhone") {
      value = formatPhoneNumber(value);
    }

    setFieldValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const formatPhoneNumber = (value) => {
    const cleaned = ("" + value).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
    if (match) {
      return !match[2]
        ? match[1]
        : `(${match[1]}) ${match[2]}${match[3] ? `-${match[3]}` : ""}`;
    }
    return value;
  };

  const isValidEmailAddress = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const handleEmailChange = (event) => {
    const { value } = event.target;
    const lowerCaseEmail = value.toLowerCase(); // Convert email to lowercase
    setFieldValues((prevData) => ({
      ...prevData,
      ownerEmail: lowerCaseEmail,
    }));
    if (lowerCaseEmail && !isValidEmailAddress(lowerCaseEmail)) {
      setEmailError("Incorrect Email is entered. Please try again.");
    } else {
      setEmailError("");
    }
  };

  function createReviewURL(placeID) {
    return `https://search.google.com/local/writereview?placeid=${placeID}`;
  }

  const handleFocus = () => {
    const url = createReviewURL(fieldValues.googlePlaceID);
    setFieldValues((prevState) => ({
      ...prevState,
      googleMyBusinessUrl: url,
    }));
  };

  useEffect(() => {
    if (mapData) {
      setHasAddress(true);
      setFieldValues((prevState) => ({
        ...prevState,
        businessName: mapData.name || "",
        businessAddress: mapData.formatted_address || "",
        googlePlaceID: mapData.place_id || "",
        googleMyBusinessUrl: createReviewURL(mapData.place_id || ""),
        website: mapData.website || "Not Available",
        lat: mapData.geometry.location.lat() || "",
        lng: mapData.geometry.location.lng() || "",
      }));
    }
  }, [mapData]);

  return (
    <Page className={classes.root} title="Review+ Merchant Setup">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
        alignItems="center"
      >
        <Container maxWidth="md">
          <Typography color={"secondary"} variant="h3">
            <b>REVIEW+</b>
          </Typography>
          <Typography variant="h4" color="black" gutterBottom>
            <strong>Merchant Setup</strong>
          </Typography>

          <Typography
            variant="caption"
            className={classes.subtitle}
            gutterBottom
          >
            To get started, enter your Business name or Business address.
            Business logo can also be uploaded. Click "CONTINUE" to proceed.
          </Typography>

          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            mb={3}
          >
            <Box className={classes.glowingIcon}>
              <img
                src={imageAsUrl || logo}
                alt="Business Logo"
                style={{
                  borderRadius: "50%",
                  width: "110px",
                  height: "110px",
                  objectFit: "cover",
                }}
              />
            </Box>
            <Button
              className={classes.marginTp}
              variant="contained"
              color="secondary"
              component="label"
              startIcon={<PhotoCamera />}
            >
              Upload Business Logo
              <input
                type="file"
                id="businessLogo"
                name="businessLogo"
                onChange={handleLogoAsFile}
                hidden
              />
            </Button>
          </Box>

          <form onSubmit={handleSubmition} autoComplete="off">
            <TextField
              fullWidth
              className={classes.inputField}
              label="ID"
              margin="normal"
              name="merchantID"
              type="text"
              value={fieldValues.merchantID}
              autoComplete="new-password"
              variant="outlined"
              onChange={handleChange}
              disabled
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <User />
                  </InputAdornment>
                ),
              }}
            />
            <Box className={classes.mapWidget}>
              <SearchLocation passChildData={setMapData} />
            </Box>
            <TextField
              className={classes.inputField}
              fullWidth
              label="Business Name"
              margin="normal"
              name="businessName"
              type="text"
              placeholder="Your Business Name"
              value={fieldValues.businessName}
              autoComplete="new-password"
              variant="outlined"
              onChange={handleChange}
              disabled={!hasAddress}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <BusinessOutlined />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              className={classes.inputField}
              fullWidth
              label="Business Address"
              margin="normal"
              name="businessAddress"
              type="text"
              placeholder="Your Business Address"
              value={fieldValues.businessAddress}
              autoComplete="new-password"
              variant="outlined"
              onChange={handleChange}
              onFocus={handleFocus}
              disabled={!hasAddress}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LocationSearchingOutlined />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              className={classes.inputField}
              fullWidth
              label="Google Business Review Link"
              margin="normal"
              name="googleMyBusinessUrl"
              type="text"
              placeholder="Your Unique Review Link"
              value={fieldValues.googleMyBusinessUrl}
              autoComplete="new-password"
              variant="outlined"
              onChange={handleChange}
              onFocus={handleFocus}
              disabled={!hasAddress}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Link />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              className={classes.inputField}
              fullWidth
              label="Website"
              margin="normal"
              name="website"
              type="text"
              placeholder="Enter your website url"
              value={fieldValues.website || "Not Available"}
              autoComplete="new-password"
              variant="outlined"
              onChange={handleChange}
              onFocus={handleFocus}
              disabled={!hasAddress}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Link />
                  </InputAdornment>
                ),
              }}
            />
            <Autocomplete
              className={classes.marginTp}
              fullWidth
              margin="normal"
              name="businessType"
              variant="outlined"
              onSelect={handleChange}
              disabled={!hasAddress}
              options={defaultBusinessTypes}
              renderInput={(params) => (
                <TextField
                  className={classes.inputField}
                  name="businessType"
                  id="businessType"
                  onChange={handleChange}
                  value={fieldValues.businessType}
                  {...params}
                  label="Select your Business Type"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <Business />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            <TextField
              className={classes.inputField}
              fullWidth
              label="Owner Name"
              margin="normal"
              name="ownerName"
              type="text"
              disabled={!hasAddress}
              placeholder="Business Owner Name"
              value={fieldValues.ownerName}
              autoComplete="new-password"
              variant="outlined"
              onChange={handleChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <User />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              className={classes.inputField}
              fullWidth
              label="Owner Phone"
              margin="normal"
              name="ownerPhone"
              type="text"
              disabled={!hasAddress}
              placeholder="Business Owner Phone"
              value={fieldValues.ownerPhone}
              autoComplete="new-password"
              variant="outlined"
              onChange={handleChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <BusinessOutlined />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              className={classes.inputField}
              fullWidth
              label="Owner Email"
              margin="normal"
              name="ownerEmail"
              type="email"
              disabled={!hasAddress}
              placeholder="Business Owner Email"
              value={fieldValues.ownerEmail}
              autoComplete="new-password"
              variant="outlined"
              helperText={emailError}
              error={!!emailError}
              onChange={handleEmailChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Link />
                  </InputAdornment>
                ),
              }}
            />
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              p={2}
            >
              <Button
                variant="contained"
                color="secondary"
                className={classes.marginTp}
                size="large"
                disabled={isSubmitDisabled}
                type="submit"
              >
                Complete Registration
                <ArrowForward
                  className={classNames(classes.rightIcon, classes.iconSmall)}
                />
              </Button>
            </Box>
          </form>
          <Dialog
            open={isConfirmationOpen}
            onClose={() => setIsConfirmationOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {dialogContentText}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              {isRefreshRequired ? (
                <></>
              ) : (
                <Button
                  onClick={() => setIsConfirmationOpen(false)}
                  color="secondary"
                >
                  Cancel
                </Button>
              )}
              {isRefreshRequired ? (
                <Button
                  onClick={() => window.location.reload()}
                  color="secondary"
                  autoFocus
                >
                  OK
                </Button>
              ) : (
                <Button
                  onClick={handleConfirmSubmission}
                  variant="contained"
                  color="secondary"
                  autoFocus
                >
                  Confirm
                </Button>
              )}
            </DialogActions>
          </Dialog>
        </Container>
      </Box>
    </Page>
  );
};

export default MerchantOnBoard;
