import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  Box,
  Container,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import firebase from "../../config/firebase"; // Adjust the import path as needed
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: `linear-gradient(135deg, ${theme.palette.primary.main} 20%, ${theme.palette.secondary.main} 90%)`,
    padding: theme.spacing(2),
  },
  card: {
    maxWidth: 600,
    textAlign: "center",
    borderRadius: theme.shape.borderRadius,
  },
  media: {
    height: 200,
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: theme.spacing(2),
  },
  button: {
    borderRadius: theme.shape.borderRadius,
  },
  noColorButton: {
    color: "#000",
  },
}));

const CustomerUnsubscribe = () => {
  const classes = useStyles();
  const { merchantId, customerEmail } = useParams();
  const [merchantDetails, setMerchantDetails] = useState(null);
  const [unsubscribed, setUnsubscribed] = useState(false);
  const [stay, setStay] = useState(false);

  useEffect(() => {
    const fetchMerchantDetails = async () => {
      try {
        const merchantRef = firebase.db.collection("merchants").doc(merchantId);
        const merchantDoc = await merchantRef.get();
        if (merchantDoc.exists) {
          setMerchantDetails(merchantDoc.data());
        } else {
          console.error("Merchant not found");
        }
      } catch (error) {
        console.error("Error fetching merchant details:", error);
      }
    };

    fetchMerchantDetails();
  }, [merchantId]);

  const handleUnsubscribe = async () => {
    try {
      // Update in customers collection
      const customerCollectionRef = firebase.db
        .collection("customers")
        .doc(customerEmail);
      const customerCollectionMerchantRef = customerCollectionRef
        .collection("merchants")
        .doc(merchantId);

      await customerCollectionMerchantRef.update({
        optEmail: false,
      });

      // Update in merchants collection
      const merchantCustomerRef = firebase.db
        .collection("merchants")
        .doc(merchantId)
        .collection("customers")
        .doc(customerEmail);

      await merchantCustomerRef.update({
        optEmail: false,
      });

      setUnsubscribed(true);
    } catch (error) {
      console.error("Error updating optEmail:", error);
    }
  };

  const handleStay = async () => {
    try {
      // Update in customers collection
      const customerCollectionRef = firebase.db
        .collection("customers")
        .doc(customerEmail);
      const customerCollectionMerchantRef = customerCollectionRef
        .collection("merchants")
        .doc(merchantId);

      await customerCollectionMerchantRef.update({
        optEmail: true,
      });

      // Update in merchants collection
      const merchantCustomerRef = firebase.db
        .collection("merchants")
        .doc(merchantId)
        .collection("customers")
        .doc(customerEmail);

      await merchantCustomerRef.update({
        optEmail: true,
      });

      setStay(true);
    } catch (error) {
      console.error("Error updating optEmail:", error);
    }
  };

  const handleGoBack = () => {
    setStay(false);
    setUnsubscribed(false);
  };

  return (
    <div className={classes.root}>
      <Helmet>
        <title>{`Unsubscribe from ${merchantDetails?.businessName} Emails`}</title>
      </Helmet>
      <Container maxWidth="sm">
        <Card className={classes.card}>
          <div className="logo">
            <Link to="/home">
              <img
                style={{ height: "56px" }}
                src="https://firebasestorage.googleapis.com/v0/b/reviews-plus-8779c.appspot.com/o/review-plus-assets%2Flogo.png?alt=media&token=b20f1562-1ea4-484f-9e2e-75198e900be1"
                alt="Review+"
              />
            </Link>
          </div>
          <CardMedia
            className={classes.media}
            image={
              stay
                ? "https://firebasestorage.googleapis.com/v0/b/reviews-plus-8779c.appspot.com/o/review-plus-assets%2Funsub-stay.png?alt=media&token=3d9d9662-c927-4080-acae-2d4ef45fa375"
                : "https://firebasestorage.googleapis.com/v0/b/reviews-plus-8779c.appspot.com/o/review-plus-assets%2Funsubscribe.png?alt=media&token=73196224-7502-47f2-89ff-d4a559115e7a"
            }
            title="Cover Image"
          />
          <CardContent>
            <Typography variant="h4" component="h2" mb={"4px"}>
              {unsubscribed
                ? "Sad to see you go. Feel free to opt back"
                : stay
                ? "Excellent Choice!"
                : `Are you sure about unsubscribing from ${merchantDetails?.businessName} emails?`}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {unsubscribed
                ? ""
                : stay
                ? `Now we will keep you updated with ${merchantDetails?.businessName} latest promotions and updates`
                : `If you unsubscribe now, you might miss nice deals, birthday coupons and promotions from ${merchantDetails?.businessName}!`}
            </Typography>
            <Box className={classes.buttonGroup}>
              {unsubscribed ? (
                <Button
                  variant="contained"
                  color="secondary"
                  className={`${classes.button}`}
                  onClick={handleGoBack}
                >
                  Go back
                </Button>
              ) : stay ? (
                <Button
                  variant="contained"
                  className={`${classes.button} `}
                  onClick={handleGoBack}
                >
                  Go back
                </Button>
              ) : (
                <>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={`${classes.button}`}
                    onClick={handleStay}
                  >
                    I'd rather stay
                  </Button>
                  <Button
                    variant="outlined"
                    className={`${classes.button} ${classes.noColorButton}`}
                    onClick={handleUnsubscribe}
                  >
                    Unsubscribe me
                  </Button>
                </>
              )}
            </Box>
          </CardContent>
        </Card>
      </Container>
    </div>
  );
};

export default CustomerUnsubscribe;
