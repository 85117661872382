import React from "react";
import { Box, Container, TextField, InputAdornment } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Page from "../../utils/Page";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import classNames from "classnames";
import Typography from "@mui/material/Typography";
import ArrowForward from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import PerformanceWidget from "../../components/Widget/PerformanceWidget";
import SearchLocation from "../../components/Maps/SearchLocation";
import Lottie from "lottie-react";
import bannerAnimation from "../../utils/animations/person-review.json";
import { Link, User } from "react-feather";
import {
  BusinessOutlined,
  LocationSearchingOutlined,
} from "@mui/icons-material";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import storage from "../../config/firebaseStorage";
import logo from "../../icons/logo.png";
import firebase from "../../config/firebase";
import Autocomplete from "@mui/lab/Autocomplete";
require("../../App.css");

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  h1title: {
    color: "#ffffff",
  },
  h2title: {
    color: theme.palette.primary.main,
    fontSize: 26,
  },
  h3title: {
    color: theme.palette.primary.main,
    fontSize: 18,
  },
  subtitle: {
    fontSize: 16,
  },
  errorText: {
    color: theme.palette.error.main,
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  marginTp: {
    marginTop: theme.spacing(2),
  },
  marginBtm: {
    marginBottom: theme.spacing(2),
  },
  iconSmall: {
    fontSize: 20,
  },
  typography: {
    padding: theme.spacing(2),
  },
  footer: {
    paddingTop: theme.spacing(2),
    fontSize: 15,
  },
  lotties: {
    height: "100px",
    width: "100px",
  },
}));

//Lottie options
const defaultOptions = {
  loop: 5,
  autoplay: true,
  animationData: bannerAnimation,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const CloverSetup = () => {
  const classes = useStyles();
  const [fieldValues, setFieldValues] = useState({
    merchantID: "",
    businessName: "",
    businessAddress: "",
    businessType: "Not Available",
    googlePlaceID: "",
    initGoogleReviews: "0",
    initGoogleRating: "0",
    website: "www.google.com",
    lat: "",
    lng: "",
  });

  const [hasAddress, setHasAddress] = useState(false);

  const [mapData, setMapData] = useState(null);
  const [percent, setPercent] = useState(0);

  //Default business types
  const defaultBusinessTypes = [
    "Auto Parts Shop",
    "Bakery",
    "Bar",
    "Beauty Salon & Barber Shop",
    "Book Store",
    "Candy Shop",
    "Cigar & Vape",
    "Contractor",
    "Dentist & Orthodontist",
    "Dry Cleaning & Laundry",
    "Electronics Store",
    "Financial Services",
    "Florist",
    "Full Service Restaurant",
    "Furniture Store",
    "Golf Course",
    "Grocery Convenience Store",
    "Hardware Store",
    "Household Items & Furnishings",
    "Jewelry Store",
    "Liquor Store",
    "Medical Services",
    "Membership Club",
    "Non Profit",
    "Other Retail",
    "Pet Shop",
    "Professional Services",
    "Quick Service Restaurant",
    "Religious Org",
    "Repair Shop",
    "Salon & Spa",
    "School Education",
    "Shoe Repair & Shine",
    "Shoe Store",
    "Taxi",
    "Toy & Hobby Shop",
    "Not Available",
  ];

  //Image File
  const [imageAsUrl, setImageAsUrl] = useState("");
  const navigate = useNavigate();

  //Should have same name as parameter
  const { id } = useParams();

  useEffect(() => {
    //Get the Merchant ID
    setFieldValues({
      merchantID: id,
    });
    //set the progress bar
    setPercent(20);
  }, []);

  const handleLogoAsFile = (e) => {
    const image = e.target.files[0];

    // async magic goes here...
    if (image === "") {
      console.error(`not an image, the image file is a ${typeof image}`);
    }

    const uploadTask = storage
      .ref(`/businessLogos/${fieldValues.merchantID}`)
      .put(image);
    //initiates the firebase side uploading
    uploadTask.on(
      "state_changed",
      (snapShot) => {
        // console.log(snapShot)
      },
      (err) => {
        //catches the errors
        console.log(err);
      },
      () => {
        storage
          .ref("businessLogos")
          .child(fieldValues.merchantID)
          .getDownloadURL()
          .then((fireBaseUrl) => {
            //console.log(fireBaseUrl)
            setImageAsUrl(fireBaseUrl);
          });
      }
    );
  };

  async function checkIsApprovedMid() {
    let mIDStatus = await firebase.checkIfApprovedId(fieldValues.merchantID);
    //if MID is under approved list, give access
    if (mIDStatus === "MID Inactive") {
      navigate("/merchant-onboarding-finish/", {
        state: {
          merchantID: id,
          businessName: fieldValues.businessName,
          businessAddress: fieldValues.businessAddress,
          businessLogoUrl: imageAsUrl,
          googleReviewUrl: fieldValues.googleMyBusinessUrl,
          businessType: fieldValues.businessType,
          googlePlaceID: fieldValues.placeID,
          website: fieldValues.website,
          initGoogleRating: fieldValues.initGoogleRating,
          initGoogleReviews: fieldValues.initGoogleReviews,
          createdTimeStamp: Date.now(),
          lat: fieldValues.lat,
          lng: fieldValues.lng,
        },
      });
    } else {
      //dont exist
      alert("Invalid or Expired URL. Please reach out to info@tezooo.com");
    }
  }

  async function checkIfCloverMerchantRegistered() {
    let merchantData = await firebase.checkIfMerchantExist(
      fieldValues.merchantID
    );

    if (merchantData.length > 0) {
      alert(
        "Merchant is already created. Click on Completed Setup button on Clover."
      );
    } else {
      saveMerchantAndNavigate();
    }
  }

  const handleSubmition = (e) => {
    e.preventDefault();

    // Dynamically create Google Business URL based on the provided address
    if (fieldValues.googleMyBusinessUrl.length === 0) {
      const googleMyBusinessUrl = createReviewURL(fieldValues.placeID);
      setFieldValues((prevState) => ({
        ...prevState,
        googleMyBusinessUrl,
      }));
    }

    //check all fields are filled otherwise show popup
    for (const value of Object.values(fieldValues)) {
      //console.log(value);
      if (value === "" || value === undefined) {
        alert("Please fill out all fields");
        return;
      }
    }

    // setFieldValues(updatedFieldValues);
    //console.log(fieldValues);
    //check all fields are filled otherwise show popup
    // for (const value of Object.values(fieldValues)) {
    //   console.log(value);
    //   if (value === "" || value === undefined) {
    //     alert("Please fill out all fields");
    //     return;
    //   }
    // }

    checkIfCloverMerchantRegistered();
  };

  function saveMerchantAndNavigate() {
    navigate("/cs-finish/", {
      state: {
        merchantID: id,
        businessName: fieldValues.businessName,
        businessAddress: fieldValues.businessAddress,
        businessLogoUrl: imageAsUrl,
        googleReviewUrl: fieldValues.googleMyBusinessUrl,
        businessType: fieldValues.businessType ?? "Not Available",
        googlePlaceID: fieldValues.placeID,
        website: fieldValues.website ?? "Not Available",
        initGoogleRating: fieldValues.initGoogleRating ?? "0",
        initGoogleReviews: fieldValues.initGoogleReviews ?? "0",
        createdTimeStamp: Date.now(),
        lat: fieldValues.lat,
        lng: fieldValues.lng,
      },
    });
  }

  const handleChange = (data) => {
    setFieldValues((prevState) => ({
      ...prevState,
      [data.target.name]: data.target.value,
    }));
  };

  function createReviewURL(data) {
    var baseURL = "https://search.google.com/local/writereview?placeid=";
    return baseURL + data;
  }

  useEffect(() => {
    if (mapData != null) {
      //update the other fields
      setFieldValues({
        merchantID: id,
        businessName: mapData.name,
        businessAddress: mapData.formatted_address,
        googleMyBusinessUrl: createReviewURL(mapData.place_id),
        initGoogleRating: mapData.rating ?? "0",
        initGoogleReviews: mapData.user_ratings_total ?? "0",
        website: mapData.website ?? "Not Available",
        placeID: mapData.place_id,
        lat: mapData.geometry.location.lat(),
        lng: mapData.geometry.location.lng(),
        createdTimestamp: Date.now(),
      });

      //unlock the button and check type
      setHasAddress(true);

      //update the progress
      setPercent(45);
    }
  }, [mapData]);

  const handleFocus = (event) => {
    event.target.select();
  };

  return (
    <Page className={classes.root} title="Review+ Merchant Setup">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Container maxWidth="md">
          <Box mb={3}>
            <Lottie
              animationData={bannerAnimation}
              style={{ height: "200px" }}
            />

            <Typography variant="h1" gutterBottom>
              Welcome to,
            </Typography>
            <Typography
              variant="h2"
              component="h2"
              color="secondary"
              className="h1title"
              gutterBottom
            >
              <b>REVIEW+</b>
            </Typography>
            <Typography variant="h4" gutterBottom>
              Complete your setup
            </Typography>
            <Typography
              variant="caption"
              className={classes.subtitle}
              gutterBottom
              align="center"
            >
              To get started, Enter your Business name or Business address as
              per google. Business logo can also be uploaded. Click "CONTINUE"
              to proceed.
            </Typography>
          </Box>

          <section>
            <div>
              <PerformanceWidget passToChild={percent} />
            </div>
          </section>
          <form onSubmit={handleSubmition} autoComplete="off">
            <TextField
              fullWidth
              label="ID"
              margin="normal"
              name="cloverMerchantID"
              type="text"
              value={fieldValues.merchantID || ""}
              autoComplete="new-password"
              variant="outlined"
              onChange={handleChange}
              disabled
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <User />
                  </InputAdornment>
                ),
              }}
            />
            <SearchLocation passChildData={setMapData} />

            <TextField
              fullWidth
              label="Business Name"
              margin="normal"
              name="businessName"
              type="text"
              placeholder="Your Business Name"
              value={fieldValues.businessName || ""}
              autoComplete="new-password"
              variant="outlined"
              onChange={handleChange}
              disabled={!hasAddress ? true : false}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <BusinessOutlined />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              fullWidth
              label="Business Address"
              margin="normal"
              name="businessAddress"
              type="text"
              placeholder="Your Business Address"
              value={fieldValues.businessAddress || ""}
              autoComplete="new-password"
              variant="outlined"
              onChange={handleChange}
              onFocus={handleFocus}
              disabled={!hasAddress ? true : false}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LocationSearchingOutlined />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              fullWidth
              label="Google Business Review Link"
              margin="normal"
              name="googleMyBusinessUrl"
              type="text"
              placeholder="Your Unique Review Link"
              value={fieldValues.googleMyBusinessUrl || ""}
              autoComplete="new-password"
              variant="outlined"
              onChange={handleChange}
              onFocus={handleFocus}
              disabled={!hasAddress ? true : false}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Link />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              fullWidth
              label="Website"
              margin="normal"
              name="website"
              type="text"
              placeholder="Enter your website url"
              value={fieldValues.website || "Not Available"}
              autoComplete="new-password"
              variant="outlined"
              onChange={handleChange}
              onFocus={handleFocus}
              disabled={!hasAddress ? true : false}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Link />
                  </InputAdornment>
                ),
              }}
            />
            <Autocomplete
              className={classes.marginTp}
              fullWidth
              margin="normal"
              name="businessType"
              variant="outlined"
              onSelect={handleChange}
              disabled={!hasAddress ? true : false}
              options={defaultBusinessTypes}
              renderInput={(params) => (
                <TextField
                  name="businessType"
                  id="businessType"
                  onChange={handleChange}
                  value={fieldValues.businessType}
                  {...params}
                  label="Select your Business Type"
                />
              )}
            />
            <Box
              display="flex"
              flexDirection="column"
              height="100%"
              justifyContent="center"
              alignItems="center"
              p={2}
            >
              <div>
                {imageAsUrl !== "" ? (
                  <Box
                    component="img"
                    sx={{
                      maxHeight: { xs: 160 },
                      maxWidth: { xs: 240 },
                    }}
                    className={classes.marginBtm}
                    alt="Business Logo"
                    src={imageAsUrl}
                  />
                ) : (
                  <Box
                    component="img"
                    sx={{
                      maxHeight: { xs: 160 },
                      maxWidth: { xs: 240 },
                    }}
                    className={classes.marginBtm}
                    alt="Business Logo"
                    src={logo}
                  />
                )}
              </div>

              <Button
                disabled={!hasAddress ? true : false}
                className={classes.marginBtm}
                variant="outlined"
                component="label"
                color="primary"
                startIcon={<PhotoCamera />}
              >
                Upload Business Logo
                <input
                  type="file"
                  id="businessLogo"
                  name="businessLogo"
                  onChange={handleLogoAsFile}
                  hidden
                />
              </Button>

              <Button
                variant="contained"
                color="secondary"
                className={classes.marginTp}
                size="large"
                disabled={!hasAddress ? true : false}
                type="submit"
              >
                Continue
                <ArrowForward
                  className={classNames(classes.rightIcon, classes.iconSmall)}
                />
              </Button>
            </Box>
          </form>
        </Container>
      </Box>

      {/* <Hidden mdUp>
        <NavLink to="/">
          <img src={logo} alt="Review+" />
          Review+
        </NavLink>
      </Hidden>     */}
    </Page>
  );
};

export default CloverSetup;
