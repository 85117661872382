export const defaultBusinessTypes = [
  "Auto Parts Shop",
  "Bakery",
  "Bar",
  "Beauty Salon & Barber Shop",
  "Business",
  "Book Store",
  "Candy Shop",
  "Cigar & Vape",
  "Contractor",
  "Dentist & Orthodontist",
  "Dry Cleaning & Laundry",
  "Electronics Store",
  "Financial Services",
  "Florist",
  "Full Service Restaurant",
  "Furniture Store",
  "Golf Course",
  "Grocery Convenience Store",
  "Hardware Store",
  "Household Items & Furnishings",
  "Jewelry Store",
  "Liquor Store",
  "Medical Services",
  "Membership Club",
  "Non Profit",
  "Other Retail",
  "Pet Shop",
  "Professional Services",
  "Quick Service Restaurant",
  "Religious Org",
  "Repair Shop",
  "Salon & Spa",
  "School Education",
  "Shoe Repair & Shine",
  "Shoe Store",
  "Taxi",
  "Toy & Hobby Shop",
  "Not Available",
];
