import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Card, CardContent, Typography, Grid, Box } from "@mui/material";
import Rating from "@mui/material/Rating";
import BmAnalyticsChart from "./BmAnalyticsChart";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    backgroundColor: "#FFF8E5",
    borderRadius: theme.spacing(2),
    boxShadow: theme.shadows[4],
    overflow: "visible",
    transition: "transform 0.2s, box-shadow 0.2s",
    "&:hover": {
      transform: "translateY(-2px)",
      boxShadow: theme.shadows[10],
    },
  },
  mediumText: {
    fontWeight: "bold",
  },
  largeText: {
    marginTop: theme.spacing(2),
    fontWeight: "bold",
    fontSize: "2rem",
  },
  rating: {
    marginTop: theme.spacing(2),
    fontSize: "3rem",
  },
  chartContainer: {
    height: 200,
    marginTop: theme.spacing(2),
  },
}));

function BmGoogleRatingCard({
  ratingValue,
  mediumText,
  initialRating,
  currentRating,
  plan,
}) {
  const classes = useStyles();
  const [displayedRating, setDisplayedRating] = useState(0);
  const [displayedNumber, setDisplayedNumber] = useState(0);
  const ratingIncrease = initialRating
    ? ((currentRating - initialRating) / initialRating) * 100
    : currentRating > 0
    ? 100
    : 0;

  useEffect(() => {
    let currentRating = 0;
    const ratingIncrement = ratingValue / 20;

    let currentNumber = 0;
    const numberIncrement = parseFloat(currentRating) / 20;

    const animationInterval = setInterval(() => {
      if (
        currentRating >= ratingValue &&
        currentNumber >= parseFloat(currentRating)
      ) {
        clearInterval(animationInterval);
        setDisplayedRating(ratingValue);
        setDisplayedNumber(parseFloat(currentRating));
      } else {
        currentRating += ratingIncrement;
        currentNumber += numberIncrement;
        setDisplayedRating(currentRating);
        setDisplayedNumber(currentNumber.toFixed(1));
      }
    }, 50);

    return () => clearInterval(animationInterval);
  }, [ratingValue, currentRating]);

  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography className={classes.rating}>
              <Rating
                size="large"
                value={currentRating}
                readOnly
                color="warning"
                precision={0.1}
              />
            </Typography>
            <Typography variant="subtitle1" className={classes.mediumText}>
              {mediumText}
            </Typography>
            <Typography variant="h5" className={classes.largeText}>
              {currentRating}
            </Typography>
            {plan !== "BASIC_01" && (
              <Typography variant="body2" color="textSecondary">
                {`Increase: ${
                  !isFinite(ratingIncrease)
                    ? "Not Available"
                    : ratingIncrease.toFixed(2) + "%"
                }`}
              </Typography>
            )}
          </Grid>
          {plan !== "BASIC_01" && (
            <Grid item xs={6} className={classes.chartContainer}>
              <BmAnalyticsChart
                initial={initialRating}
                current={currentRating}
                label="Rating"
              />
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}

export default BmGoogleRatingCard;
