import React from "react";
import { makeStyles } from "@mui/styles";
import { Card, CardContent, Typography } from "@mui/material";
import BmQrScanChart from "./BmQrScanChart";

const useStyles = makeStyles((theme) => ({
  card: {
    height: "100%",
    backgroundColor: "#FFFFFF",
    borderRadius: theme.spacing(2),
    boxShadow: theme.shadows[4],
    overflow: "visible",
    transition: "transform 0.2s, box-shadow 0.2s",
    "&:hover": {
      transform: "translateY(-2px)",
      boxShadow: theme.shadows[10],
    },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: theme.spacing(1),
  },
  title: {
    paddingTop: theme.spacing(2),
    fontSize: "1rem",
    fontWeight: "bold",
  },
  number: {
    fontSize: "2rem",
    fontWeight: "bold",
    lineHeight: 1,
    paddingTop: theme.spacing(1),
  },
  formControl: {
    minWidth: 120,
  },
  chart: {
    paddingTop: theme.spacing(2),
  },
  select: {
    borderRadius: 25,
    backgroundColor: "white",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginRight: theme.spacing(2),
    "&:focus": {
      borderRadius: 25,
      backgroundColor: "white",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "150px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      maxWidth: "200px",
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: "300px",
    },
  },
}));

const months = [3, 6, 9, 12];

function BmQrScansGraph({ currentMerchant }) {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography className={classes.title} gutterBottom>
            QR Scans vs Customers
          </Typography>
        </div>
        <BmQrScanChart currentMerchant={currentMerchant} />
      </CardContent>
    </Card>
  );
}

export default BmQrScansGraph;
