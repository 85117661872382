import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Grid,
  Container,
  Card,
  CardContent,
  Typography,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  Gift as GiftIcon,
  User as UserIcon,
  Mail as MailIcon,
  Phone as PhoneIcon,
} from "react-feather"; // Using react-feather for icons
import firebase from "../../../config/firebase";
import Page from "../../../utils/Page";
import Logo from "../../../icons/logo.png";
import LovedAnim from "../../../utils/animations/lovedanim.json";
import ConfettiAnim from "../../../utils/animations/confetti.json"; // Importing Confetti animation
import Lottie from "lottie-react";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    background: `linear-gradient(135deg, #FAEED1 50%, ${theme.palette.secondary.main} 95%)`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    animation: "$fadeIn 2s",
    position: "relative",
    userSelect: "none",
  },
  "@keyframes fadeIn": {
    "0%": { opacity: 0 },
    "100%": { opacity: 1 },
  },
  glowingIcon: {
    margin: theme.spacing(1, 0),
    animation: "$glow 2.5s infinite",
    borderRadius: "50%",
    width: "110px",
    height: "110px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0px 0px 20px rgba(255, 255, 255, 0.7)",
    position: "relative",
    userSelect: "none",
  },
  "@keyframes glow": {
    "0%": { boxShadow: "0 0 5px rgba(255, 255, 255, 0.5)" },
    "50%": { boxShadow: "0 0 20px rgba(255, 255, 255, 1)" },
    "100%": { boxShadow: "0 0 5px rgba(255, 255, 255, 0.5)" },
  },
  footer: {
    position: "absolute",
    bottom: theme.spacing(1),
    textAlign: "center",
    color: "#fff",
    fontSize: "0.8rem",
    opacity: 0.8,
    width: "100%",
    userSelect: "none",
  },
  businessName: {
    fontSize: "1.9rem",
    fontWeight: "bold",
    textAlign: "center",
    color: "#fff",
    marginTop: theme.spacing(1),
    fontFamily: "Helvetica, Arial, sans-serif",
    textShadow: "2px 2px 8px rgba(0, 0, 0, 0.5)",
    userSelect: "none",
  },
  cardContainer: {
    backgroundColor: "#DED0B6", // Background for div card
    borderRadius: 8, // Rounded corners
    padding: theme.spacing(1),
    margin: theme.spacing(1),
  },
  couponCard: {
    background: `linear-gradient(150deg, #FAEED1 10%, ${theme.palette.secondary.main} 100%)`,
    textAlign: "center",
    borderRadius: 8, // Rounded corners for coupon shape
    border: "1px solid rgba(0, 0, 0, 0.1)", // Reduced border size
  },
  couponRedeemTitle: {
    textShadow: "2px 2px 8px rgba(0, 0, 0, 0.2)",
    fontSize: "1.4rem", // Increased title size
    fontWeight: "bold",
    color: "#000", // Use a darker color for contrast
    marginBottom: theme.spacing(1), // Add spacing below title
    fontFamily: "Helvetica, Arial, sans-serif",
  },
  couponTitle: {
    textShadow: "1px 1px 4px rgba(0, 0, 0, 0.2)",
    fontSize: "2rem", // Increased title size
    fontWeight: "bold",
    color: "#fff", // Use a darker color for contrast
    marginBottom: theme.spacing(1), // Add spacing below title
  },
  couponDescription: {
    fontSize: "1rem", // Reduced description size
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(1), // Add spacing below description
  },
  customerDetails: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  customerDetail: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: theme.spacing(1),
    cursor: "pointer", // Add pointer cursor for clickable elements
  },
  address: {
    textAlign: "center", // Center the business address
  },
  detailIcon: {
    marginRight: theme.spacing(1),
  },
  expiryDate: {
    marginTop: theme.spacing(2),
    fontSize: "1rem",
    color: theme.palette.text.primary,
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },
  redeemButton: {
    borderRadius: 50, // Rounded button
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Add shadow
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 auto", // Center the button
    background: theme.palette.secondary.main,
  },
  giftIcon: {
    marginRight: theme.spacing(1),
  },
  animationContainer: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(2),
  },
  confettiAnimation: {
    position: "absolute",
    zIndex: 1,
  },
  lovedAnimation: {
    position: "relative",
    zIndex: 2,
  },
  errorMessage: {
    fontSize: "1.2rem",
    color: "#f44336", // Red color for error
    fontWeight: "bold",
    textAlign: "center",
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
}));

function RedeemCoupon() {
  const classes = useStyles();
  const { merchantId, customerEmail, couponId } = useParams();
  const [coupon, setCoupon] = useState(null);
  const [merchant, setMerchant] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [pin, setPin] = useState("");
  const [redeemDialogOpen, setRedeemDialogOpen] = useState(false);
  const [isRedeemed, setIsRedeemed] = useState(false);

  useEffect(() => {
    getMerchantData(merchantId);
    getCustomerData(merchantId, customerEmail);
    getCouponData(merchantId, customerEmail, couponId);
  }, [merchantId, customerEmail, couponId]);

  const getMerchantData = async (merchantId) => {
    try {
      const data = await firebase.getMerchantDetails(merchantId);
      if (data) {
        setMerchant(data);
      }
    } catch (error) {
      console.error("Error fetching merchant data:", error);
    }
  };

  const getCustomerData = async (merchantId, customerEmail) => {
    try {
      const data = await firebase.getMerchantCustomerDetails(
        merchantId,
        customerEmail
      );
      if (data) {
        setCustomer(data);
      }
    } catch (error) {
      console.error("Error fetching customer data:", error);
    }
  };

  const getCouponData = async (merchantId, customerEmail, couponId) => {
    try {
      const data = await firebase.getCustomerCoupon(
        merchantId,
        customerEmail,
        couponId
      );
      if (data) {
        setCoupon(data);
        setIsRedeemed(data.isCouponRedeemed);
      }
    } catch (error) {
      console.error("Error fetching coupon data:", error);
    }
  };

  const handlePinChange = (event) => {
    setPin(event.target.value);
  };

  const handleRedeem = async () => {
    try {
      await firebase.markCouponRedeemed(
        merchantId,
        customerEmail,
        coupon.selectedCoupon.id,
        new Date().toISOString().split("T")[0]
      );
      setRedeemDialogOpen(false);
      setIsRedeemed(true); // Update redeemed status locally
      alert("Coupon redeemed successfully!");
      window.location.reload();
    } catch (error) {
      alert("An error occurred while redeeming the coupon.");
    }
  };

  const handleVerifyPin = async () => {
    try {
      const isValidPin = await firebase.verifyMerchantPin(merchantId, pin);

      if (!isValidPin) {
        alert("Incorrect PIN. Please try again.");
        return;
      }

      await handleRedeem();
    } catch (error) {
      alert("An error occurred while verifying the PIN.");
    }
  };

  return (
    <Page title="Redeem Coupon" className={classes.root}>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
        alignItems="center"
      >
        <Container maxWidth="sm">
          {merchant && (
            <Grid
              item
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <Box className={classes.glowingIcon}>
                <img
                  src={merchant.businessLogoUrl || Logo}
                  alt="Business Logo"
                  style={{
                    borderRadius: "50%",
                    width: "110px",
                    height: "110px",
                    objectFit: "cover",
                  }}
                />
              </Box>
              <Typography className={classes.businessName}>
                {merchant.businessName}
              </Typography>
              {merchant.businessAddress && (
                <Typography variant="body2" className={classes.address}>
                  {merchant.businessAddress}
                </Typography>
              )}
            </Grid>
          )}
          {!coupon || !customer ? (
            <div className={classes.cardContainer}>
              <Card className={classes.couponCard}>
                <CardContent>
                  <Typography className={classes.errorMessage}>
                    😔 Coupon might have expired or Customer is not found.
                  </Typography>
                </CardContent>
              </Card>
            </div>
          ) : (
            <div className={classes.cardContainer}>
              {isRedeemed ? (
                <>
                  <Card className={classes.couponCard}>
                    <CardContent>
                      <div className={classes.animationContainer}>
                        <Lottie
                          animationData={ConfettiAnim}
                          loop={true}
                          className={classes.confettiAnimation}
                          style={{ height: "100px", width: "100px" }}
                        />
                        <Lottie
                          animationData={LovedAnim}
                          loop={true}
                          className={classes.lovedAnimation}
                          style={{ height: "60px", width: "60px" }}
                        />
                      </div>
                      <Typography className={classes.couponRedeemTitle}>
                        🥳 Coupon is already redeemed on <br />{" "}
                        {coupon.redemptionDate}!
                      </Typography>
                    </CardContent>
                  </Card>
                </>
              ) : (
                <Card className={classes.couponCard}>
                  <CardContent>
                    <Typography className={classes.couponTitle}>
                      {coupon.selectedCoupon.name}
                    </Typography>
                    <Typography className={classes.couponDescription}>
                      {coupon.selectedCoupon.description}
                    </Typography>
                    <Typography className={classes.expiryDate}>
                      <strong>Expires on: </strong>
                      {coupon.selectedCoupon.expiryDate}
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setRedeemDialogOpen(true)}
                      className={classes.redeemButton}
                      startIcon={<GiftIcon className={classes.giftIcon} />}
                    >
                      Redeem Coupon
                    </Button>
                  </CardContent>
                </Card>
              )}
            </div>
          )}
        </Container>
        <Typography className={classes.footer}>Powered by Review+</Typography>
      </Box>

      <Dialog
        open={redeemDialogOpen}
        onClose={() => setRedeemDialogOpen(false)}
      >
        <DialogTitle style={{ fontSize: "20px", fontWeight: "bold" }}>
          Enter Access PIN 🔑
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the merchant PIN to redeem this coupon.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="PIN"
            type="password"
            fullWidth
            value={pin}
            onChange={handlePinChange}
          />
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={() => setRedeemDialogOpen(false)}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleVerifyPin}
            color="secondary"
          >
            Redeem
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
}

export default RedeemCoupon;
