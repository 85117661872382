import firebase from 'firebase/compat/app';
import 'firebase/compat/storage'

const config = {
  apiKey: "AIzaSyD-P0pWcMvgEF0DwSEQHaKY9-f8hMR2imU",
  authDomain: "reviews-plus-8779c.firebaseapp.com",
  projectId: "reviews-plus-8779c",
  storageBucket: "reviews-plus-8779c.appspot.com",
  messagingSenderId: "293193770022",
  appId: "1:293193770022:web:645b8f58bbba3199cf32e7",
  measurementId: "G-E051WD0BCP"
};

// Initialize Firebase
firebase.initializeApp(config);

const storage = firebase.storage()

export  {
  storage as default
}
