import React, { useState } from "react";
import { Button } from "@mui/material";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import firebase from "../../config/firebase";

const TestExportCustomer = () => {
  const [loading, setLoading] = useState(false);

  const fetchCustomerData = async () => {
    setLoading(true);
    try {
      const customersRef = firebase.db.collection(
        "/merchants/lankasalon908544/customers"
      );
      const snapshot = await customersRef.get();
      const customers = snapshot.docs.map((doc) => doc.data());

      const filteredData = customers.map((customer) => ({
        name: customer.name,
        email: customer.email,
        phone: customer.phone,
        feedback: customer.feedback,
        feeling: customer.feeling,
        visitDate: customer.visitDate,
      }));

      exportToExcel(filteredData);
    } catch (error) {
      console.error("Error fetching customer data:", error);
    } finally {
      setLoading(false);
    }
  };

  const exportToExcel = (data) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Customers");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, "customers.xlsx");
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        onClick={fetchCustomerData}
        disabled={loading}
      >
        {loading ? "Exporting..." : "Export Customer Data"}
      </Button>
    </div>
  );
};

export default TestExportCustomer;
