import React from "react";
import { VideoTag } from "react-video-tag";

const Video = () => {
  return (
    <>
      <VideoTag
        autoPlay={`${true}`}
        muted={`${true}`}
        playsInline={`${true}`}
        loop={`${true}`}
        src={`${"https://firebasestorage.googleapis.com/v0/b/reviews-plus-8779c.appspot.com/o/review-plus-assets%2Fapp%20video.mp4?alt=media&token=08e5740f-51b5-4503-8f5c-1d1a3d9cc488"}`}
      />
    </>
  );
};

export default Video;
