//TODO: Fix the issue with navigation to dashboard

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Typography,
  Container,
  Card,
  CircularProgress,
  TextField,
  List,
  ListItem,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import PersonIcon from "@mui/icons-material/Person";
import BusinessIcon from "@mui/icons-material/Business";
import WorkIcon from "@mui/icons-material/Work";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import CloseIcon from "@mui/icons-material/Close";
import firebaseInstance from "../../config/firebase";
import Page from "../../utils/Page";
import logo from "../../icons/logo.png";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    background: `linear-gradient(135deg, ${theme.palette.primary.main} 30%, ${theme.palette.secondary.main} 90%)`,
  },
  card: {
    width: "100%",
    maxWidth: 200,
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    borderRadius: "15px",
    boxShadow: theme.shadows[5],
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer",
    backdropFilter: "blur(10px)", // Glassy blur effect
    background: "rgba(255, 255, 255, 0.8)",
  },
  customerCard: {
    background: "#FFECB3", // Light yellow
  },
  employeeCard: {
    background: "#C8E6C9", // Light green
  },
  merchantCard: {
    background: "#BBDEFB", // Light blue
  },
  icon: {
    fontSize: 50,
    color: "#000", // Black icons
    marginBottom: theme.spacing(1),
  },
  button: {
    marginTop: theme.spacing(2),
    width: "50%", // Smaller button width
    alignSelf: "center",
  },
  title: {
    textAlign: "center",
    marginBottom: theme.spacing(1),
    color: "#fff",
    fontWeight: "bold", // Bold title
    fontSize: "2.5rem", // Bigger font size
    textTransform: "uppercase",
  },
  subtitle: {
    textAlign: "center",
    marginBottom: theme.spacing(1),
    color: "#fff",
    fontSize: "1.5rem", // Bigger font size
  },
  description: {
    textAlign: "center",
    marginBottom: theme.spacing(4),
    color: "#fff",
  },
  footer: {
    marginTop: theme.spacing(4),
    textAlign: "center",
    color: "#fff",
    fontSize: "0.9rem",
    opacity: 0.8,
    width: "100%",
    userSelect: "none",
  },
  container: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  logo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  logoutButton: {
    background: "rgba(255, 255, 255, 0.8)",
    color: theme.palette.secondary.main,
    marginTop: theme.spacing(4),
    width: "50%", // Set width to 50%
    alignSelf: "center", // Center the button
  },
  loadingContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  loadingText: {
    textTransform: "none", // small letters
    marginTop: theme.spacing(2),
    color: "#fff",
  },
  searchBox: {
    marginBottom: theme.spacing(2),
    width: "100%",
  },
  list: {
    width: "100%",
  },
  listItem: {
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  dialogTitle: {
    fontSize: "2rem", // Bigger title font size
    textAlign: "center",
    fontWeight: "bold",
  },
  dialogDescription: {
    textAlign: "center",
    marginBottom: theme.spacing(2),
    fontWeight: "bold", // Bold content
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const RoleSelection = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [roles, setRoles] = useState({ user: {}, admin: {}, employee: {} });
  const [loading, setLoading] = useState(true);
  const [selectRole, setSelectRole] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [merchantDetails, setMerchantDetails] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    const fetchUserRoles = async () => {
      const currentUser = firebaseInstance.auth.currentUser;
      if (currentUser) {
        const userDoc = await firebaseInstance.getUserByEmail(
          currentUser.email
        );
        if (userDoc) {
          setRoles(userDoc);
        } else {
          console.log("No user roles found");
        }
      } else {
        console.log("No email found");
      }
      setLoading(false);
    };
    fetchUserRoles();
  }, []);

  async function logout() {
    try {
      await firebaseInstance.logout();
      sessionStorage.removeItem("currentMerchant");
      sessionStorage.removeItem("merchantData");
      navigate("/login", { replace: true });
    } catch (error) {
      alert(error.message);
    }
  }

  useEffect(() => {
    const fetchMerchantDetails = async () => {
      const details = {};
      for (const merchantId of filteredData) {
        const data = await firebaseInstance.getMerchantDetails(merchantId);
        details[merchantId] = data;
      }
      setMerchantDetails(details);
    };

    if (selectRole) {
      fetchMerchantDetails();
    }
  }, [filteredData, selectRole]);

  const handleRoleSelection = (role) => {
    //console.log("Select role is " + role);
    const availableData = roles[role];
    if (Object.keys(availableData).length > 1) {
      setSelectRole(role);
      setFilteredData(Object.keys(availableData));
      setDialogOpen(true);
    } else {
      const merchantId = Object.keys(availableData)[0];

      //if there are no additional businesses listed under merchant, go to default merchant page+
      if (role === "admin") {
        navigate("/app/dashboard", {
          replace: true,
        });
      } else {
        navigate(`/app/${role}-dashboard`, { state: { merchantId } });
      }
    }
  };

  const handleSelect = (id) => {
    //console.log("Select merchant is " + selectRole);
    if (selectRole === "admin") {
      navigate("/app/dashboard", {
        replace: true,
      });
      window.location.reload();
    } else {
      navigate(`/app/${selectRole}-dashboard`, { state: { merchantId: id } });
      //console.log("Select role is " + selectRole);
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectRole(null);
  };

  const handleLogout = () => {
    logout();
  };

  const roleDescription = () => {
    let descriptions = [];
    if (Object.keys(roles.user).length > 0) {
      descriptions.push(
        "As a customer, you can check stores you visited, points you've earned, access to your giftcards, promotions at the merchants you've visited, etc."
      );
    }
    if (Object.keys(roles.employee).length > 0) {
      descriptions.push(
        "As an employee, you can manage your assigned tasks and access business data."
      );
    }
    if (Object.keys(roles.admin).length > 0) {
      descriptions.push(
        "As a merchant, you can manage your store, promotions, and more."
      );
    }
    descriptions.push("Login to your dashboard to continue.");
    return descriptions.join(" ");
  };

  if (loading) {
    return (
      <Page title="Loading...">
        <Box className={classes.root}>
          <Box className={classes.loadingContainer}>
            <CircularProgress />
            <Typography variant="h4" className={classes.loadingText}>
              Loading...
            </Typography>
          </Box>
        </Box>
      </Page>
    );
  }

  return (
    <Page title="Select Role">
      <Box className={classes.root}>
        <Container maxWidth="sm" className={classes.logo}>
          <img
            src={logo}
            alt="Logo"
            style={{ width: "100px", marginBottom: "10px" }}
          />
          <Typography variant="h2" className={classes.title}>
            Review+
          </Typography>
          <Typography variant="h3" className={classes.subtitle}>
            Choose Your Dashboard
          </Typography>
          <Typography variant="body1" className={classes.description}>
            {roleDescription()}
          </Typography>
          <Box className={classes.container}>
            {Object.keys(roles.user).length > 0 && (
              <Card
                className={`${classes.card} ${classes.customerCard}`}
                onClick={() => handleRoleSelection("user")}
              >
                <PersonIcon className={classes.icon} />
                <Typography variant="h6">
                  <strong>Customer Dashboard</strong>
                </Typography>
              </Card>
            )}
            {Object.keys(roles.employee).length > 0 && (
              <Card
                className={`${classes.card} ${classes.employeeCard}`}
                onClick={() => handleRoleSelection("employee")}
              >
                <WorkIcon className={classes.icon} />
                <Typography variant="h6">
                  <strong>Employee Dashboard</strong>
                </Typography>
              </Card>
            )}
            {Object.keys(roles.admin).length > 0 && (
              <Card
                className={`${classes.card} ${classes.merchantCard}`}
                onClick={() => handleRoleSelection("admin")}
              >
                <BusinessIcon className={classes.icon} />
                <Typography variant="h6">
                  <strong>Merchant Dashboard</strong>
                </Typography>
              </Card>
            )}
          </Box>
          <Button
            variant="contained"
            className={classes.logoutButton}
            onClick={handleLogout}
            startIcon={<ExitToAppIcon />}
          >
            Logout
          </Button>
        </Container>

        <Typography className={classes.footer}>
          &copy; {new Date().getFullYear()} Powered by Review+ All rights
          reserved.
        </Typography>

        <Dialog
          open={dialogOpen}
          onClose={handleCloseDialog}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle className={classes.dialogTitle}>
            Select {selectRole === "admin" ? "Merchant" : "Employee"}
            <IconButton
              className={classes.closeButton}
              onClick={handleCloseDialog}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Typography variant="body2" className={classes.dialogDescription}>
              Please select a {selectRole === "admin" ? "merchant" : "employee"}{" "}
              from the list below.
            </Typography>
            <TextField
              variant="outlined"
              placeholder="Search"
              className={classes.searchBox}
              onChange={(event) => {
                const value = event.target.value.toLowerCase();
                setFilteredData(
                  Object.keys(roles[selectRole]).filter((key) =>
                    merchantDetails[key]?.businessName
                      .toLowerCase()
                      .includes(value)
                  )
                );
              }}
            />
            <List className={classes.list}>
              {filteredData.map((key) => (
                <ListItem
                  button
                  key={key}
                  onClick={() => handleSelect(key)}
                  className={classes.listItem}
                >
                  <ListItemText
                    primary={merchantDetails[key]?.businessName || key}
                  />
                </ListItem>
              ))}
            </List>
          </DialogContent>
        </Dialog>
      </Box>
    </Page>
  );
};

export default RoleSelection;
