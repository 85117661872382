import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Snackbar,
  FormHelperText,
  Alert,
  CircularProgress,
} from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import firebase from "../../../config/firebase";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import storage from "../../../config/firebaseStorage";
import imageCompression from "browser-image-compression";
import Page from "../../../utils/Page";
import { format } from "date-fns"; // Import format function from date-fns

import MerchantContext from "../../../config/MerchantContext";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  uploadButton: {
    marginTop: theme.spacing(2),
  },
  imagePreview: {
    width: "100%",
    height: "auto",
    marginBottom: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    objectFit: "cover",
  },
  imageContainer: {
    width: "100%",
    maxWidth: 400,
    height: 200,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(3), // Added margin above the image cover
    borderRadius: theme.shape.borderRadius,
    overflow: "hidden",
  },
  submitButton: {
    marginTop: theme.spacing(3),
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(3),
  },
  headerText: {
    fontWeight: "bold",
  },
  backButton: {
    marginLeft: theme.spacing(2),
  },
}));

const CreatePromoCard = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const defaultDate = new Date();
  defaultDate.setDate(defaultDate.getDate() + 7);
  const [promoData, setPromoData] = useState({
    name: "",
    description: "",
    scheduleDate: defaultDate,
    endDate: defaultDate,
    imageUrl: "",
    isActive: true,
    notes: "",
    email: false,
    sms: false,
  });
  const [imageAsFile, setImageAsFile] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");
  const { currentMerchant, plan } = useContext(MerchantContext); // Use MerchantContext to get the current merchant

  useEffect(() => {
    const interval = setInterval(() => {
      if (promoData.endDate && new Date() > promoData.endDate) {
        setPromoData((prevPromoData) => ({
          ...prevPromoData,
          isActive: false,
        }));
      }
    }, 1000 * 60 * 60); // Check every hour

    return () => clearInterval(interval);
  }, [promoData.endDate]);

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleImageChange = async (e) => {
    const image = e.target.files[0];

    if (!image) {
      console.error("Not an image, the image file is a", typeof image);
      return;
    }

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    try {
      const compressedFile = await imageCompression(image, options);
      setImageAsFile(compressedFile);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreviewUrl(reader.result);
      };
      reader.readAsDataURL(compressedFile);
    } catch (error) {
      console.error("Error during image compression", error);
    }
  };

  const handleChange = (e) => {
    setPromoData({ ...promoData, [e.target.name]: e.target.value });
  };

  const handleDateChange = (name, date) => {
    if (name === "endDate" && date < promoData.scheduleDate) {
      setPromoData((prevPromoData) => ({
        ...prevPromoData,
        scheduleDate: date,
      }));
    }
    setPromoData((prevPromoData) => ({
      ...prevPromoData,
      [name]: date,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!currentMerchant) {
      setLoading(false);
      setSnackbarMessage("Something went wrong. Please try again.");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
      return;
    }

    const currentMerchantId = currentMerchant.merchantID;

    try {
      if (imageAsFile) {
        const uploadTask = await storage
          .ref(`/merchants/${currentMerchantId}/promotions/${imageAsFile.name}`)
          .put(imageAsFile);
        const fireBaseUrl = await storage
          .ref(`/merchants/${currentMerchantId}/promotions`)
          .child(imageAsFile.name)
          .getDownloadURL();
        promoData.imageUrl = fireBaseUrl;
      }

      const formattedPromoData = {
        ...promoData,
        scheduleDate: promoData.scheduleDate
          ? format(promoData.scheduleDate, "yyyy-MM-dd")
          : null,
        endDate: promoData.endDate
          ? format(promoData.endDate, "yyyy-MM-dd")
          : null,
      };

      const newPromoId = await firebase.createPromoCard(
        currentMerchantId,
        formattedPromoData
      );
      if (newPromoId) {
        setLoading(false);
        setSnackbarMessage("Promotion created successfully");
        setSnackbarSeverity("success");
        setOpenSnackbar(true);
        navigate("/app/promotions");
      } else {
        throw new Error("Failed to create promotion");
      }
    } catch (error) {
      setLoading(false);
      setSnackbarMessage("Failed to create promotion");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
      console.error("Failed to create promo card:", error);
    }
  };

  return (
    <Page title="Create Promotion">
      <Box className={classes.root}>
        <Container maxWidth="md">
          <Box className={classes.header}>
            <Typography variant="h4" className={classes.headerText}>
              Create New Promotion
            </Typography>
            <Button
              variant="contained"
              color="primary"
              startIcon={<ArrowBackIcon />}
              onClick={() => navigate("/app/promotions")}
              className={classes.backButton}
            >
              Back
            </Button>
          </Box>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <form onSubmit={handleSubmit}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Box className={classes.imageContainer}>
                  <img
                    src={imagePreviewUrl || "https://via.placeholder.com/400"}
                    alt="Uploaded"
                    className={classes.imagePreview}
                  />
                </Box>
                <Button
                  variant="contained"
                  component="label"
                  className={classes.uploadButton}
                  startIcon={<PhotoCamera />}
                >
                  Upload Image
                  <input type="file" hidden onChange={handleImageChange} />
                </Button>
                <FormHelperText>
                  Upload an image that represents the promotion. The image
                  should be clear and relevant to the promotion.
                </FormHelperText>
              </Box>
              <TextField
                fullWidth
                label="Promotion Name"
                margin="normal"
                name="name"
                value={promoData.name}
                onChange={handleChange}
                required
              />
              <FormHelperText>
                Enter a clear and concise name for the promotion. This will be
                displayed to customers.
              </FormHelperText>
              <TextField
                fullWidth
                label="Description"
                margin="normal"
                name="description"
                value={promoData.description}
                onChange={handleChange}
                required
                multiline
                rows={4}
              />
              <FormHelperText>
                Provide a detailed description of the promotion, including any
                terms and conditions.
              </FormHelperText>
              <DatePicker
                label="End Date"
                value={promoData.endDate}
                onChange={(date) => handleDateChange("endDate", date)}
                renderInput={({ inputRef, inputProps, InputProps }) => (
                  <TextField
                    inputRef={inputRef}
                    {...inputProps}
                    InputProps={{
                      ...InputProps,
                      endAdornment: InputProps?.endAdornment,
                    }}
                    fullWidth
                    margin="normal"
                  />
                )}
                minDate={new Date()}
                disablePast
              />
              <FormHelperText>
                Select the end date for the promotion. The promotion will no
                longer be active after this date.
              </FormHelperText>
              <DatePicker
                label="Schedule Date"
                value={promoData.scheduleDate}
                onChange={(date) => handleDateChange("scheduleDate", date)}
                renderInput={({ inputRef, inputProps, InputProps }) => (
                  <TextField
                    inputRef={inputRef}
                    {...inputProps}
                    InputProps={{
                      ...InputProps,
                      endAdornment: InputProps?.endAdornment,
                    }}
                    fullWidth
                    margin="normal"
                  />
                )}
                minDate={new Date()}
                maxDate={promoData.endDate}
                disablePast
              />
              <FormHelperText>
                Select the start date for the promotion. The promotion will be
                active from this date.
              </FormHelperText>
              <TextField
                fullWidth
                label="Notes"
                margin="normal"
                name="notes"
                value={promoData.notes}
                onChange={handleChange}
              />
              <FormHelperText>
                Add any additional notes or information about the promotion.
                This can include internal notes for your team.
              </FormHelperText>
              <Box display="flex" justifyContent="space-between" marginTop={2}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  className={classes.submitButton}
                  disabled={loading}
                  startIcon={<SaveIcon />}
                >
                  {loading ? (
                    <CircularProgress size={24} />
                  ) : (
                    "Create Promotion"
                  )}
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => navigate("/app/promotions")}
                  className={classes.submitButton}
                  startIcon={<CancelIcon />}
                >
                  Cancel
                </Button>
              </Box>
            </form>
          </LocalizationProvider>
        </Container>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={3500}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={snackbarSeverity}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </Page>
  );
};

export default CreatePromoCard;
