import React, { useState } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { toast } from "react-toastify";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import GoogleIcon from "./google-icon.png";

const useStyles = makeStyles((theme) => ({
  googleButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: `#000`,
    color: "#fff",
    fontWeight: "bold",
    fontSize: "16px",
    borderRadius: "24px",
    padding: theme.spacing(1, 2),
    width: "100%",
    textTransform: "none",
    userSelect: "none",
    cursor: "pointer",
    transition: "background 0.3s ease",
    animation: "$buttonGlow 2.5s infinite",
    "&:hover": {
      background: `${theme.palette.secondary.light}`,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%", // Full width on smaller screens
      marginTop: theme.spacing(1), // Margin on top to create space from text
    },
  },
  googleIcon: {
    marginRight: theme.spacing(1),
    width: "20px",
    height: "20px",
  },
}));

const LinkGoogleBusinessBtn = ({ onSuccess = () => {}, clientId }) => {
  const classes = useStyles();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const login = useGoogleLogin({
    onSuccess: (res) => {
      console.log("Login successful, credential received:", res);
      onSuccess(res);
    },
    onError: (error) => {
      console.error("Login Failed. Error:", error);
      toast.error("Failed to link Google My Business. Please try again.");
    },
    isSignedIn: true,
    auto_select: false,
    clientId: clientId,
    responseType: "code",
    accessType: "offline",
    prompt: "consent",
    flow: "auth-code",
  });

  const handleOpenConfirmDialog = () => {
    setOpenConfirmDialog(true);
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  const handleConfirmConnect = () => {
    setOpenConfirmDialog(false);
    login();
  };

  return (
    <div id="signInButton">
      <Button
        onClick={handleOpenConfirmDialog}
        className={classes.googleButton}
      >
        <img
          src={GoogleIcon}
          alt="Google logo"
          className={classes.googleIcon}
        />{" "}
        Connect Now
      </Button>
      <Dialog open={openConfirmDialog} onClose={handleCloseConfirmDialog}>
        <DialogTitle>🔑 Connect to Google Business</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to connect your Google Business account? This
            will allow access to manage your reviews.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDialog} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={handleConfirmConnect}
            color="secondary"
            variant="contained"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default LinkGoogleBusinessBtn;
