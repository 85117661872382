import axios from "axios";

const API_BASE_URL =
  "https://us-central1-reviews-plus-8779c.cloudfunctions.net/app/api/";
//Local test api
//const API_BASE_URL = "http://localhost:5001/reviews-plus-8779c/us-central1/app/api/";

const MAX_RETRIES = 3;

// Function to generate AI response
export const generateAIResponse = async (
  reviewText,
  rating,
  businessType,
  customerName
) => {
  try {
    const response = await axios.post(`${API_BASE_URL}generate-response`, {
      reviewText,
      rating, // Pass rating to the API
      businessType,
      customerName,
    });
    return response.data.response;
  } catch (error) {
    console.error("Error generating AI response:", error);
    throw error;
  }
};

//exchange auth code to get refresh tokens and access tokens
export const exchangeAuthorizationCodeForTokens = async (
  authorizationCode,
  clientId,
  clientSecret,
  redirectUri
) => {
  const payload = {
    grant_type: "authorization_code",
    code: authorizationCode,
    client_id: clientId,
    client_secret: clientSecret,
    redirect_uri: redirectUri,
  };

  try {
    const response = await axios.post(
      "https://oauth2.googleapis.com/token",
      payload,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    // console.log("Tokens received:", response.data);
    return response.data; // This will include access_token, refresh_token, etc.
  } catch (error) {
    console.error(
      "Error exchanging authorization code for tokens:",
      error.response ? error.response.data : error.message
    );
    throw new Error("Failed to exchange authorization code for tokens.");
  }
};

export const getGoogleReviewDetails = async (placeId, retries = 0) => {
  try {
    const res = await axios.get(`${API_BASE_URL}getPlace/${placeId}`, {
      timeout: 15000, // increase timeout in milliseconds
    });
    if (!res) return null;
    return res.data;
  } catch (error) {
    if (error.code === "ECONNABORTED" && retries < MAX_RETRIES) {
      console.log(`Timeout error, retrying... (${retries + 1}/${MAX_RETRIES})`);
      return getGoogleReviewDetails(placeId, retries + 1);
    }
    console.log("getting review details error", error);
    return null;
  }
};

export const verifyToken = async (accessToken) => {
  try {
    //console.log("Verifying token:", accessToken);
    const response = await axios.get(
      "https://www.googleapis.com/oauth2/v1/tokeninfo",
      {
        params: {
          access_token: accessToken,
        },
      }
    );

    //console.log("Token verification response:", response.data);

    // If the response status is 200, the token is valid
    return response.data;
  } catch (error) {
    console.error("Token verification failed:", error);
    return false;
  }
};

//GETS NEW ACCESS TOKEN USING REFRESH TOKEN
export const refreshAccessToken = async (
  refreshToken,
  clientId,
  clientSecret
) => {
  try {
    const response = await axios.post(
      "https://www.googleapis.com/oauth2/v4/token",
      {
        grant_type: "refresh_token",
        client_id: clientId,
        client_secret: clientSecret,
        refresh_token: refreshToken,
      },
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );

    //console.log("Access token refresh response:", response.data);

    const newAccessToken = response.data;
    return newAccessToken;
  } catch (error) {
    console.error("Failed to refresh access token:", error);
    return null;
  }
};

//GET THE ACCOUNT ID USING TOKEN
export const fetchAccountDetails = async (token) => {
  try {
    const headers = new Headers();
    headers.append("Authorization", `Bearer ${token}`);

    const response = await fetch(
      "https://mybusinessbusinessinformation.googleapis.com/v1/accounts",
      { headers }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    const accounts = data.accounts;

    if (accounts && accounts.length > 0) {
      const account = accounts[0];
      const accountId = account.name;
      return accountId;
    } else {
      return null;
    }
  } catch (error) {
    console.error("Failed to fetch Google My Business account details:", error);
    return null;
  }
};

//////////FETCH LOCATIONS for account id////////////
export const fetchLocations = async (googleAccountId, gApi) => {
  try {
    if (!googleAccountId) {
      console.log(
        "No account ID found. Please link your Google My Business account first."
      );
      return;
    }

    const response = await gApi.client.request({
      path: `https://mybusinessbusinessinformation.googleapis.com/v1/${googleAccountId}/locations`,
      params: {
        read_mask:
          "name,title,phoneNumbers,categories,websiteUri,latlng,metadata,profile,storefrontAddress,regularHours,serviceItems",
      },
    });

    if (response.data.locations) {
      return response.data.locations;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Failed to fetch Google My Business locations:", error);
    return [];
  }
};

/*****GOOGLE APIS******/

//send customer feedback to merchant
export const notifyCustomerFeedbackToMerchant = async (
  merchantId,
  merchantEmail,
  businessLogoUrl,
  businessName,
  customerName,
  customerEmail,
  customerPhone,
  customerDob,
  customerGender,
  customerCountry,
  customerFeedback,
  customerFeeling
) => {
  try {
    const response = await axios.post(`${API_BASE_URL}notifyCustomerMerchant`, {
      merchantId,
      merchantEmail,
      businessLogoUrl,
      businessName,
      customerName,
      customerEmail,
      customerPhone,
      customerDob,
      customerGender,
      customerCountry,
      customerFeedback,
      customerFeeling,
    });
    return response.data;
  } catch (error) {
    console.log("Error notifying merchant:", error);
    return null;
  }
};

// Function to send default apology email
export const sendSadCustEmail = async (
  merchantId,
  email,
  name,
  imgUrl,
  businessName,
  businessAddress
) => {
  try {
    const response = await axios.post(`${API_BASE_URL}sendSadCustEmail`, {
      merchantId,
      email,
      name,
      imgUrl,
      businessName,
      businessAddress,
    });
    return response.data;
  } catch (error) {
    console.log("Error sending apology email:", error);
    return null;
  }
};

// Function to send default happy email
export const sendHappyCustEmail = async (
  merchantId,
  email,
  name,
  googleReviewLink,
  imgUrl,
  businessName,
  businessAddress
) => {
  try {
    const response = await axios.post(`${API_BASE_URL}sendHappyCustEmail`, {
      merchantId,
      email,
      name,
      googleReviewLink,
      imgUrl,
      businessName,
      businessAddress,
    });
    return response.data;
  } catch (error) {
    console.log("Error sending happy email:", error);
    return null;
  }
};

// Function to send first time apology email
export const sendFirstTimeSadCustEmail = async (
  merchantId,
  email,
  name,
  imgUrl,
  businessName,
  businessAddress
) => {
  try {
    const response = await axios.post(`${API_BASE_URL}sendFirstSadEmail`, {
      merchantId,
      email,
      name,
      imgUrl,
      businessName,
      businessAddress,
    });
    return response.data;
  } catch (error) {
    console.log("Error sending apology email:", error);
    return null;
  }
};

// Function to send first time happy email
export const sendFirstTimeHappyCustEmail = async (
  merchantId,
  email,
  name,
  googleReviewLink,
  imgUrl,
  businessName,
  businessAddress
) => {
  try {
    const response = await axios.post(`${API_BASE_URL}sendFirstHappyEmail`, {
      merchantId,
      email,
      name,
      googleReviewLink,
      imgUrl,
      businessName,
      businessAddress,
    });
    return response.data;
  } catch (error) {
    console.log("Error sending happy email:", error);
    return null;
  }
};

// Function to send promotional email
export const sendPromotionEmail = async (merchantId, promotionId) => {
  try {
    const response = await axios.post(`${API_BASE_URL}sendPromotionEmail`, {
      merchantId,
      promotionId,
    });
    return response.data;
  } catch (error) {
    console.log("Error sending promotion email:", error);
    return null;
  }
};

// Function to get email template
export const getEmailTemplate = async (type) => {
  try {
    const response = await axios.get(`${API_BASE_URL}getEmailTemplate/${type}`);
    return response.data;
  } catch (error) {
    console.log("Error getting email template:", error);
    return null;
  }
};

// Function to update email template
export const updateEmailTemplate = async (type, content) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}updateEmailTemplate/${type}`,
      { content }
    );
    return response.data;
  } catch (error) {
    console.log("Error updating email template:", error);
    return null;
  }
};

///////////////////////////////////////////////////WINBACK APIS///////////////////////////
// Function to send winback email
export const sendWinbackEmail = async (
  email,
  name,
  merchantId,
  emailContent,
  couponData,
  winbackSubject
) => {
  try {
    const response = await axios.post(`${API_BASE_URL}sendWinbackEmail`, {
      email,
      name,
      merchantId,
      emailContent,
      couponData,
      winbackSubject,
    });
    return response;
  } catch (error) {
    console.error("Error sending winback email:", error);
    throw error;
  }
};

///////////////////////////WIN BACK APIS//////////////////////////////

export const sendRegistrationEmail = async (fieldValues) => {
  const businessName = fieldValues.businessName;
  const businessLogo = fieldValues.businessLogoUrl;
  const email = fieldValues.ownerEmail;

  console.log("businessName" + businessName);

  console.log("businessLogo" + businessLogo);

  console.log("email" + email);
  try {
    const response = await axios.post(`${API_BASE_URL}sendRegistrationEmail`, {
      fieldValues,
    });
    return response.data;
  } catch (error) {
    console.log("Error sending registration email:", error);
    return null;
  }
};

export const sendEmployeeInvitation = async (
  email,
  merchantID,
  businessName,
  businessLogoUrl
) => {
  try {
    await axios.post(`${API_BASE_URL}sendEmployeeInvitation`, {
      email,
      merchantID,
      businessName,
      businessLogoUrl,
    });
    console.log("Invitation email sent successfully");
  } catch (error) {
    console.error("Error sending invitation email: ", error);
  }
};

//CUSTOMERS MODULE
//SEND EMAIL TO CUSTOMER
export const sendCouponEmailToCustomer = async (
  email,
  name,
  merchantId,
  coupon
) => {
  try {
    const response = await axios.post(`${API_BASE_URL}sendWinbackEmail`, {
      email,
      name,
      merchantId,
      coupon,
    });
    return response.data;
  } catch (error) {
    console.error("Error sending winback email:", error);
    throw error; // Ensure to throw the error so it can be caught in the calling function
  }
};

// Function to send SMS
export const sendSMS = async (merchantId, customerId, to, message) => {
  try {
    const response = await axios.post(`${API_BASE_URL}send-sms`, {
      merchantId,
      customerId,
      to,
      message,
    });
    return response.data;
  } catch (error) {
    console.log("Error sending SMS:", error);
    return null;
  }
};

export const unsubscribeFromZoho = async (merchantId) => {
  try {
    const response = await axios.post(`${API_BASE_URL}zoho/unsubscribe`, {
      merchantId,
    });
    return response.data;
  } catch (error) {
    console.error("Error unsubscribing from Zoho:", error);
    throw error; // Ensure to throw the error so it can be caught in the calling function
  }
};

//BOOK A DEMO
export const sendDemoBookingEmail = async (name, email, message) => {
  try {
    const response = await axios.post(`${API_BASE_URL}sendDemoBookingEmail`, {
      name,
      email,
      message,
    });
    return response.data;
  } catch (error) {
    console.error("Error sending demo booking email:", error);
    throw error; // Ensure to throw the error so it can be caught in the calling function
  }
};
