import React from "react";
import { NavLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Button, ListItem, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  item: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: "flex-start",
    letterSpacing: 0,
    padding: "10px 8px",
    textTransform: "none",
    width: "100%",
    textDecoration: "none", // Ensure the NavLink looks like a button
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  title: {
    marginRight: "auto",
  },
  active: {
    color: theme.palette.primary.main,
    "& $title": {
      fontWeight: theme.typography.fontWeightMedium,
    },
    "& $icon": {
      color: theme.palette.primary.main,
    },
  },
  comingSoon: {
    backgroundColor: theme.palette.warning.main,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.common.white,
    padding: theme.spacing(0.5, 1),
    marginLeft: theme.spacing(1),
    fontSize: "0.70rem",
  },
}));

const NavItem = ({ className, href, icon: Icon, title, enabled, ...rest }) => {
  const classes = useStyles();

  return (
    <ListItem
      className={clsx(classes.item, className)}
      disableGutters
      {...rest}
    >
      <NavLink
        to={enabled ? href : "#"}
        className={clsx(classes.button, { [classes.disabled]: !enabled })}
      >
        <Button className={classes.button} component="div" disabled={!enabled}>
          {Icon && <Icon className={classes.icon} size="20" />}
          <span className={classes.title}>{title}</span>
          {!enabled && (
            <Typography className={classes.comingSoon}>Coming Soon</Typography>
          )}
        </Button>
      </NavLink>
    </ListItem>
  );
};

NavItem.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string,
  enabled: PropTypes.bool,
};

export default NavItem;
