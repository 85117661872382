import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";

//Zoho Tokens
const OAuthCallback = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchTokens = async () => {
      const code = new URLSearchParams(location.search).get("code");
      if (code) {
        try {
          const response = await axios.post(
            "https://us-central1-reviews-plus-8779c.cloudfunctions.net/app/oauth2callback",
            null,
            {
              params: {
                code: code,
              },
            }
          );
          console.log("Tokens fetched successfully", response.data);
        } catch (error) {
          console.error("Error fetching tokens", error);
        }
      }
    };
    fetchTokens();
  }, [location]);

  return <div>Processing OAuth callback...</div>;
};

export default OAuthCallback;
