import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import firebase from "../../../config/firebase";
import { useLocation, useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import storage from "../../../config/firebaseStorage";
import imageCompression from "browser-image-compression";
import Page from "../../../utils/Page";
import { format, parseISO } from "date-fns"; // Import format and parseISO functions from date-fns

import MerchantContext from "../../../config/MerchantContext";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    overflowY: "auto",
  },
  uploadButton: {
    marginTop: theme.spacing(2),
  },
  imagePreview: {
    width: "100%",
    height: "auto",
    marginBottom: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    objectFit: "cover",
  },
  imageContainer: {
    width: "100%",
    maxWidth: 400,
    height: 200,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(3), // Added margin above the image cover
    borderRadius: theme.shape.borderRadius,
    overflow: "hidden",
  },
  submitButton: {
    marginTop: theme.spacing(3),
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(3),
  },
  headerText: {
    fontWeight: "bold",
  },
  backButton: {
    marginLeft: theme.spacing(2),
  },
}));

const EditPromoCard = () => {
  const { currentMerchant, plan } = useContext(MerchantContext);

  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const { card } = location.state;
  const defaultDate = new Date();
  defaultDate.setDate(defaultDate.getDate() + 7);
  const [promoData, setPromoData] = useState({
    name: card.name,
    description: card.description,
    scheduleDate: card.scheduleDate ? parseISO(card.scheduleDate) : defaultDate,
    endDate: card.endDate ? parseISO(card.endDate) : defaultDate,
    imageUrl: card.imageUrl,
    isActive: card.isActive,
    notes: card.notes,
    email: card.email,
    sms: card.sms,
  });
  const [imageAsFile, setImageAsFile] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(card.imageUrl || "");
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");

  useEffect(() => {
    const interval = setInterval(() => {
      if (promoData.endDate && new Date() > promoData.endDate) {
        setPromoData((prevPromoData) => ({
          ...prevPromoData,
          isActive: false,
        }));
      }
    }, 1000 * 60 * 60); // Check every hour

    return () => clearInterval(interval);
  }, [promoData.endDate]);

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleImageChange = async (e) => {
    const image = e.target.files[0];

    if (!image) {
      console.error("Not an image, the image file is a", typeof image);
      return;
    }

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    try {
      const compressedFile = await imageCompression(image, options);
      setImageAsFile(compressedFile);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreviewUrl(reader.result);
      };
      reader.readAsDataURL(compressedFile);
    } catch (error) {
      console.error("Error during image compression", error);
    }
  };

  const handleChange = (e) => {
    setPromoData({ ...promoData, [e.target.name]: e.target.value });
  };

  const handleDateChange = (name, date) => {
    setPromoData((prevPromoData) => ({
      ...prevPromoData,
      [name]: date,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!currentMerchant) {
      setLoading(false);
      setSnackbarMessage("Something went wrong. Please try again.");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
      return;
    }

    const currentMerchantId = currentMerchant.merchantID;

    try {
      if (imageAsFile) {
        const uploadTask = await storage
          .ref(`/merchants/${currentMerchantId}/promotions/${imageAsFile.name}`)
          .put(imageAsFile);
        const fireBaseUrl = await storage
          .ref(`/merchants/${currentMerchantId}/promotions`)
          .child(imageAsFile.name)
          .getDownloadURL();
        promoData.imageUrl = fireBaseUrl;
      }

      const formattedPromoData = {
        ...promoData,
        scheduleDate: promoData.scheduleDate
          ? format(promoData.scheduleDate, "yyyy-MM-dd")
          : null,
        endDate: promoData.endDate
          ? format(promoData.endDate, "yyyy-MM-dd")
          : null,
      };

      await firebase.updatePromoCard(
        currentMerchantId,
        card.id,
        formattedPromoData
      );

      setLoading(false);
      setSnackbarMessage("Promotion updated successfully");
      setSnackbarSeverity("success");
      setOpenSnackbar(true);
      navigate("/app/promotions");
    } catch (error) {
      setLoading(false);
      setSnackbarMessage("Failed to update promotion");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
      console.error("Failed to update promo card:", error);
    }
  };

  return (
    <Page title="Edit Promotion">
      <Box className={classes.root}>
        <Container maxWidth="md">
          <Box className={classes.header}>
            <Typography variant="h4" className={classes.headerText}>
              Edit Promotion
            </Typography>
            <Button
              variant="contained"
              color="primary"
              startIcon={<ArrowBackIcon />}
              onClick={() => navigate("/app/promotions")}
              className={classes.backButton}
            >
              Back
            </Button>
          </Box>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <form onSubmit={handleSubmit}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Box className={classes.imageContainer}>
                  <img
                    src={imagePreviewUrl || "https://via.placeholder.com/400"}
                    alt="Uploaded"
                    className={classes.imagePreview}
                  />
                </Box>
                <Button
                  variant="contained"
                  component="label"
                  className={classes.uploadButton}
                  startIcon={<PhotoCamera />}
                >
                  Upload Image
                  <input type="file" hidden onChange={handleImageChange} />
                </Button>
              </Box>

              <TextField
                fullWidth
                label="Promotion Name"
                margin="normal"
                name="name"
                value={promoData.name}
                onChange={handleChange}
                required
              />
              <TextField
                fullWidth
                label="Description"
                margin="normal"
                name="description"
                value={promoData.description}
                onChange={handleChange}
                required
                multiline
                rows={4}
              />
              <DatePicker
                label="End Date"
                value={promoData.endDate}
                onChange={(date) => handleDateChange("endDate", date)}
                renderInput={(params) => (
                  <TextField {...params} fullWidth margin="normal" />
                )}
                minDate={new Date()}
                disablePast
              />
              <DatePicker
                label="Schedule Date"
                value={promoData.scheduleDate}
                onChange={(date) => handleDateChange("scheduleDate", date)}
                renderInput={(params) => (
                  <TextField {...params} fullWidth margin="normal" />
                )}
                minDate={new Date()}
                maxDate={promoData.endDate}
                disablePast
              />
              <TextField
                fullWidth
                label="Notes"
                margin="normal"
                name="notes"
                value={promoData.notes}
                onChange={handleChange}
              />
              <Box display="flex" justifyContent="space-between" marginTop={2}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  className={classes.submitButton}
                  disabled={loading}
                  startIcon={<SaveIcon />}
                >
                  {loading ? (
                    <CircularProgress size={24} />
                  ) : (
                    "Update Promotion"
                  )}
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => navigate("/app/promotions")}
                  className={classes.submitButton}
                  startIcon={<CancelIcon />}
                >
                  Cancel
                </Button>
              </Box>
            </form>
          </LocalizationProvider>
        </Container>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={3500}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={snackbarSeverity}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </Page>
  );
};

export default EditPromoCard;
