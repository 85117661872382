import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Select,
  MenuItem,
  Box,
  Grid,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import firebase from "../../../config/firebase";
import Page from "../../../utils/Page";
import BmCardContainer from "../../../components/merchant/BmCardContainer";
import BmCustomerAnalysis from "../../../components/merchant/BmCustomerAnalysis";
import BmExperienceCard from "../../../components/merchant/BmExperinceCard";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    minHeight: "100%",
    padding: theme.spacing(3),
  },
  selectInput: {
    minWidth: 240,
  },
  marginBtm: {
    marginBottom: theme.spacing(3),
  },
  title: {
    color: "#000000",
    paddingBottom: theme.spacing(3),
  },
  headerBox: {
    marginBottom: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

const MerchantDashboard = () => {
  const classes = useStyles();
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [currentMerchant, setCurrentMerchant] = useState([]);
  const [customerFeelings, setCustomerFeelings] = useState({});

  useEffect(() => {
    const fetchMerchantData = async () => {
      try {
        const data = await firebase.getAllMerchantBusiness();
        if (data.length) {
          setLocations(data);
          setSelectedLocation(data[0].merchantID);
          setCurrentMerchant(data[0]);
          getCustomerFeelings(data[0].merchantID);
        }
      } catch (error) {
        console.error("Failed to fetch merchant data:", error);
      }
    };

    fetchMerchantData();
  }, []);

  const handleLocationChange = (event) => {
    const newLocationId = event.target.value;
    const selectedMerchant = locations.find(
      (loc) => loc.merchantID === newLocationId
    );
    setSelectedLocation(newLocationId);
    setCurrentMerchant(selectedMerchant);
    getCustomerFeelings(newLocationId);
  };

  const getCustomerFeelings = (merchantId) => {
    try {
      firebase.getCustomerFeelings(merchantId).then((data) => {
        //setCustomerFeelings(data);
      });
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <Page className={classes.root} title="Merchant Dashboard">
      <Container maxWidth="xl">
        <Box className={classes.headerBox}>
          <Typography variant="h1" className={classes.title}>
            <strong>Welcome back,</strong>
          </Typography>
          <Select
            value={selectedLocation}
            onChange={handleLocationChange}
            displayEmpty
            className={classes.selectInput}
            inputProps={{ "aria-label": "Choose location" }}
          >
            <MenuItem value="" disabled>
              Choose Location
            </MenuItem>
            {locations.map((location) => (
              <MenuItem key={location.merchantID} value={location.merchantID}>
                {`${location.businessName} - ${location.businessAddress}`}
              </MenuItem>
            ))}
          </Select>
        </Box>

        {currentMerchant ? (
          <BmCardContainer
            className={classes.marginBtm}
            currentMerchant={currentMerchant}
          />
        ) : (
          <Typography>Loading...</Typography>
        )}

        <Grid container direction="row" spacing={4}>
          <Grid item lg={3} md={6} sm={6} xl={3} xs={12}>
            {currentMerchant ? (
              <BmCustomerAnalysis currentMerchant={currentMerchant} />
            ) : (
              <Typography>Loading...</Typography>
            )}
          </Grid>
          <Grid item lg={3} md={6} sm={6} xl={3} xs={12}>
            {customerFeelings && Object.keys(customerFeelings).length > 0 && (
              <BmExperienceCard
                percentage={[
                  parseFloat(customerFeelings.loved),
                  parseFloat(customerFeelings.happy),
                  parseFloat(customerFeelings.ok),
                  parseFloat(customerFeelings.sad),
                  parseFloat(customerFeelings.angry),
                ]}
              />
            )}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};
export default MerchantDashboard;
