import React, { useState, useEffect } from "react";
import { useRoutes } from "react-router-dom";
import { ThemeProvider, CssBaseline, CircularProgress } from "@mui/material";
import GlobalStyles from "../src/utils/GlobalStyles";
import firebase from "../src/config/firebase";
import routes from "./routing/routes";
import theme from "../src/theme/index";
import { MerchantProvider } from "./config/MerchantContext";
import { Helmet } from "react-helmet";
import ScrollToTop from "../src/components/home/ScrollToTop";
import AOS from "aos";
import "aos/dist/aos.css";
import "photoswipe/dist/photoswipe.css";
import "bootstrap/dist/js/bootstrap";

require("./App.css");

const App = () => {
  const [firebaseInitialized, setFirebaseInitialized] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 1200,
    });

    firebase.isInitialized().then((val) => {
      setFirebaseInitialized(val);
    });
  }, []);

  window.addEventListener("load", AOS.refresh);

  const routing = useRoutes(routes(firebaseInitialized));

  return firebaseInitialized !== false ? (
    <MerchantProvider>
      <ThemeProvider theme={theme}>
        <ScrollToTop />
        <CssBaseline />
        <GlobalStyles />

        {routing}
      </ThemeProvider>
    </MerchantProvider>
  ) : (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div id="loader">
        <CircularProgress color="primary" />
      </div>
    </ThemeProvider>
  );
};

export default App;
