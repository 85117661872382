import React from "react";

const FormAppoint = ({ toggleModalOne, setEmail }) => {
  const handleSubmit = (event) => {
    event.preventDefault();
    toggleModalOne();
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="email"
        placeholder="Enter your Email"
        onChange={(e) => setEmail(e.target.value)}
      />
      <div className="download-btn">
        <button type="submit">Book a Demo</button>
      </div>
    </form>
  );
};

export default FormAppoint;
