import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  Typography,
  Button,
  Link,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
} from "@mui/material";
import firebase from "../../config/firebase";
import GoogleIcon from "@mui/icons-material/Google";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const LoginForm = () => {
  const [email, setEmail] = useState(localStorage.getItem("email") || "");
  const [password, setPassword] = useState(
    localStorage.getItem("password") || ""
  );
  const [rememberMe, setRememberMe] = useState(
    localStorage.getItem("rememberMe") === "true"
  );
  const [forgetEmail, setForgetEmail] = useState(null);

  const [errorEmailMessage, setEmailErrorMessage] = useState("");
  const [errorPwdMessage, setPwdErrorMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [openSnack, setSnackOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [isDataFilled, setIsDataFilled] = useState(false);

  // for password show hide
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (email !== null && !isValidEmailAddress(email) && email !== "") {
      setEmailErrorMessage("Incorrect Email is entered. Please try again");
    } else {
      setEmailErrorMessage("");
      if (
        password !== null &&
        password !== "" &&
        email !== null &&
        email !== ""
      ) {
        setIsDataFilled(true);
      } else {
        setIsDataFilled(false);
      }
    }
  }, [email]);

  useEffect(() => {
    if (password !== null && !validLength(password) && password !== "") {
      setPwdErrorMessage("Minimum 6 characters are required");
    } else {
      setPwdErrorMessage("");
      if (
        password !== null &&
        password !== "" &&
        email !== null &&
        email !== ""
      ) {
        setIsDataFilled(true);
      } else {
        setIsDataFilled(false);
      }
    }
  }, [password]);

  const handleSnackClick = () => {
    setSnackOpen(true);
  };

  const handleRememberMeChange = (event) => {
    setRememberMe(event.target.checked);
  };

  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
  };

  function isValidEmailAddress(email) {
    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(email)) {
      return true;
    } else {
      return false;
    }
  }

  function validLength(password) {
    if (password.length >= 6) {
      return true;
    } else {
      return false;
    }
  }

  const sendResendPwdLink = () => {
    firebase.forgotPassword(forgetEmail).then((isSent) => {
      if (isSent) {
        toast.success("Reset Password link is sent to your Email");
        setForgetEmail("");
      } else {
        toast.error("Email not found. Please reach out to info@tezooo.com");
      }
    });
    setOpen(false);
  };

  // for validation
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email is required")
      .email("Entered value does not match email format"),
    password: Yup.string().required("Password is required"),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  // get functions to build form with useForm() hook
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  function onSubmit(data, e) {
    // display form data on success
    console.log("Message submitted: " + JSON.stringify(data));
    e.target.reset();
  }

  const loginWithGoogle = async () => {
    try {
      const result = await firebase.loginWithGoogle();

      if (result) {
        if (rememberMe) {
          localStorage.setItem("email", email);
          localStorage.setItem("password", password);
          localStorage.setItem("rememberMe", rememberMe);
        } else {
          localStorage.removeItem("email");
          localStorage.removeItem("password");
          localStorage.removeItem("rememberMe");
        }
        checkIfUserExists(result.user.email);
      } else {
        toast.error(
          "Sorry for the inconvenience, but we couldn't find any Merchant account associated with this email ID. Please contact our support team for further assistance at info@tezooo.com"
        );
        await firebase.logout();
      }
    } catch (error) {
      toast.error("Error logging in with Google: " + error.message);
      console.log(error);
    }
  };

  const checkIfUserExists = async (email) => {
    try {
      const userDoc = await firebase.getUserByEmail(email);
      console.log("User Doc is ", userDoc);

      if (userDoc !== null) {
        const { admin, employee } = userDoc;
        const hasAdminRole = admin && Object.values(admin).includes(true);
        const hasEmployeeRole =
          employee && Object.values(employee).includes(true);

        if (hasAdminRole || hasEmployeeRole) {
          setTimeout(() => {
            window.location.reload();
          }, 500);
        } else {
          toast.error(
            "Sorry for the inconvenience, but we couldn't find any Merchant account associated with this email ID. Please contact our support team for further assistance at hello@reviewplusapp.com"
          );
          await firebase.logout();
        }
      } else {
        toast.error(
          "Sorry for the inconvenience, but we couldn't find any Merchant account associated with this email ID. Please contact our support team for further assistance at hello@reviewplusapp.com"
        );
        await firebase.logout();
      }
    } catch (error) {
      toast.error(
        "Email not found. Please try with a valid registered Email ID."
      );
      console.error("Error checking user roles:", error);
    }
  };

  const login = async () => {
    try {
      if (
        !email ||
        !isValidEmailAddress(email) ||
        !password ||
        !validLength(password)
      ) {
        throw new Error("Invalid email or password");
      }
      const result = await firebase.login(email, password);
      if (result) {
        if (rememberMe) {
          localStorage.setItem("email", email);
          localStorage.setItem("password", password);
          localStorage.setItem("rememberMe", rememberMe);
        } else {
          localStorage.removeItem("email");
          localStorage.removeItem("password");
          localStorage.removeItem("rememberMe");
        }
        checkIfUserExists(result.user.email);
      } else {
        toast.error(
          "Sorry for the inconvenience, but we couldn't find any Merchant account associated with this email ID. Please contact our support team for further assistance at info@tezooo.com"
        );
        await firebase.logout();
      }
    } catch (error) {
      toast.error("Error logging in: " + error.message);
      console.log(error.message);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="user-data-form">
        <div className="row">
          <div className="col-12">
            <div className="mb-10">
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="new-password"
                value={email || ""}
                helperText={errorEmailMessage}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
          <div className="col-12 mb-10">
            <div>
              <TextField
                variant="outlined"
                margin="normal"
                required
                helperText={errorPwdMessage}
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="new-password"
                value={password || ""}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </div>
          <div className="col-12">
            <div className="agreement-checkbox d-flex justify-content-between align-items-center">
              <div className="agreement-checkbox">
                <input
                  type="checkbox"
                  id="remember"
                  checked={rememberMe}
                  onChange={handleRememberMeChange}
                />
                <label htmlFor="remember" style={{ userSelect: "none" }}>
                  Remember me
                </label>
                <Link
                  href="#"
                  variant="body2"
                  style={{ userSelect: "none" }}
                  onClick={handleClickOpen}
                >
                  Forgot password?
                </Link>
              </div>
            </div>
          </div>
          <div className="col-12">
            <Tooltip
              title={
                !isDataFilled
                  ? "Make sure to fill email address and password"
                  : ""
              }
              arrow
            >
              <Button
                disabled={!isDataFilled ? true : false}
                type="submit"
                className="theme-btn-one"
                onClick={login}
              >
                Login
              </Button>
            </Tooltip>

            <Typography align="center" style={{ userSelect: "none" }}>
              or
            </Typography>
            <Button
              fullWidth
              className="theme-google-btn"
              onClick={loginWithGoogle}
            >
              Login with Google
              <GoogleIcon
                sx={{
                  color: "#F38BA0",
                  display: "inline-flex",
                  alignItems: "center",
                  ml: 1,
                  fontSize: "20px",
                }}
              />
            </Button>
          </div>
        </div>
      </form>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <Typography
            style={{ fontSize: "20px", color: "#fe7b7b", userSelect: "none" }}
          >
            🤔 Forgot Password ?
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ userSelect: "none" }}>
            Enter your registered Email ID to receive reset password link
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="forgetEmail"
            value={forgetEmail}
            onChange={(e) => setForgetEmail(e.target.value)}
            label={"Email Address"}
            type="email"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button variant="filled" onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={sendResendPwdLink}
          >
            Reset Password
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer />
    </>
  );
};

export default LoginForm;
