import React, { createContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import firebase from "./firebase";

const MerchantContext = createContext();

export const MerchantProvider = ({ children }) => {
  const [currentMerchant, setCurrentMerchant] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [merchantData, setMerchantData] = useState([]);
  const [plan, setPlan] = useState("");
  const [isSubscribed, setIsSubscribed] = useState(false);

  useEffect(() => {
    const fetchMerchantData = async () => {
      try {
        const data = await firebase.getAllMerchantBusiness();
        if (data.length > 0) {
          setMerchantData(data);

          const savedLocation = sessionStorage.getItem("selectedLocation");
          if (savedLocation) {
            const selectedMerchant = data.find(
              (merchant) => merchant.merchantID === savedLocation
            );
            setSelectedLocation(savedLocation);
            setCurrentMerchant(selectedMerchant);
            setPlan(selectedMerchant?.subscriptions?.plan || "");
            setIsSubscribed(
              selectedMerchant?.subscriptions?.isSubscribed || false
            );
          } else if (data.length > 0) {
            setSelectedLocation(data[0].merchantID);
            setCurrentMerchant(data[0]);
            setPlan(data[0]?.subscriptions?.plan || "");
            setIsSubscribed(data[0]?.subscriptions?.isSubscribed || false);
            sessionStorage.setItem("selectedLocation", data[0].merchantID);
          }
        } else {
          console.log("Merchant is Not Authenticated");
        }
      } catch (error) {
        console.error("Failed to fetch merchant data:", error);
      }
    };

    fetchMerchantData();
  }, []);

  const handleLocationChange = (locationId) => {
    const selectedMerchant = merchantData.find(
      (merchant) => merchant.merchantID === locationId
    );
    setSelectedLocation(locationId);
    setCurrentMerchant(selectedMerchant);
    setPlan(selectedMerchant?.subscriptions?.plan || "");
    setIsSubscribed(selectedMerchant?.subscriptions?.isSubscribed || false);
    sessionStorage.setItem("selectedLocation", locationId);
  };

  return (
    <MerchantContext.Provider
      value={{
        currentMerchant,
        selectedLocation,
        merchantData,
        plan,
        isSubscribed,
        handleLocationChange,
      }}
    >
      {children}
    </MerchantContext.Provider>
  );
};

MerchantProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MerchantContext;
