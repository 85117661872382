import React, { useState } from "react";
import { GoogleLogout } from "react-google-login";
import { toast } from "react-toastify";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import GoogleIcon from "./google-icon.png";

const useStyles = makeStyles((theme) => ({
  googleButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: `#000`,
    color: "#fff",
    fontWeight: "bold",
    fontSize: "16px",
    borderRadius: "24px",
    padding: theme.spacing(1, 2),
    width: "100%",
    textTransform: "none",
    userSelect: "none",
    cursor: "pointer",
    transition: "background 0.3s ease",
    animation: "$buttonGlow 2.5s infinite",
    "&:hover": {
      background: `${theme.palette.secondary.light}`,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%", // Full width on smaller screens
      marginTop: theme.spacing(1), // Margin on top to create space from text
    },
  },
  googleIcon: {
    width: "20px",
    height: "20px",
  },
  gbLogo: {
    marginLeft: theme.spacing(1),
    width: "20px",
    height: "20px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

const UnLinkGoogleBusinessBtn = ({ onUnlinkSuccess = () => {}, clientId }) => {
  const classes = useStyles();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const handleLogoutSuccess = () => {
    if (onUnlinkSuccess) {
      onUnlinkSuccess();
    }
    toast.success("Successfully unlinked Google Business.");
  };

  const handleOpenConfirmDialog = () => {
    setOpenConfirmDialog(true);
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  const handleConfirmDisconnect = (renderProps) => {
    renderProps.onClick();
    setOpenConfirmDialog(false);
  };

  return (
    <div id="logoutBtn">
      <GoogleLogout
        clientId={clientId}
        onLogoutSuccess={handleLogoutSuccess}
        render={(renderProps) => (
          <>
            <Button
              onClick={handleOpenConfirmDialog}
              disabled={renderProps.disabled}
              className={classes.googleButton}
            >
              <img
                src={GoogleIcon}
                alt="Google Business Logo"
                className={classes.gbLogo}
                style={{ marginRight: "8px" }}
              />
              {"  "}
              Disconnect Profile
            </Button>
            <Dialog open={openConfirmDialog} onClose={handleCloseConfirmDialog}>
              <DialogTitle>🔌 Disconnect Google Business Profile </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Are you sure you want to disconnect your Google Business
                  account? You will no longer be able to manage your reviews
                  from this platform.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseConfirmDialog} color="secondary">
                  Cancel
                </Button>
                <Button
                  onClick={() => handleConfirmDisconnect(renderProps)}
                  color="secondary"
                  variant="contained"
                >
                  Confirm
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      />
    </div>
  );
};

export default UnLinkGoogleBusinessBtn;
