import React from "react";
import { makeStyles } from "@mui/styles";
import Page from "../../utils/Page";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import firebase from "../../config/firebase";
import { Button } from "@mui/material";
import { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import { Box, Container } from "@mui/material";
import Grid from "@mui/material/Grid";
import Lottie from "lottie-react";
import BannerAnimation from "../../utils/animations/person-review.json";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  title: {
    color: theme.palette.primary.main,
    paddingBottom: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  marginBtm: {
    marginBottom: theme.spacing(2),
  },
}));

//Floating Likes
const animation = {
  loop: 1,
  autoplay: false,
  animationData: BannerAnimation,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid meet",
  },
  filterSize: {
    width: "200%",
    height: "200%",
    x: "-50%",
    y: "-50%",
  },
};

const RedirectToGoogle = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  //Should have same name as parameter
  const { id } = useParams();
  const [merchantData, setMerchantData] = useState([]);
  const [merchantId, setMerchantId] = useState([]);

  const getMerchantData = (merchantId) => {
    //set merchant docs data from firebase
    try {
      firebase.getMerchantDetails(merchantId).then((data) => {
        if (data !== undefined) {
          setMerchantData(data);
          updateMerchantVisit(merchantId, data.googleReviewUrl);
        } else {
          console.log("Merchant not found " + merchantId);
          navigate("/not-found");
        }
      });
    } catch (error) {
      console.log(error.message);
      navigate("/not-found");
    }
  };

  const updateMerchantVisit = (merchantId, reviewUrl) => {
    //set merchant docs data from firebase
    try {
      firebase.updateMerchantQrScan(merchantId);
      window.location.replace(reviewUrl);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    setMerchantId(id);
    getMerchantData(id);
  }, []);

  return (
    <Page className={classes.root} title="Google Review">
      {" "}
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
        alignItems="center"
      >
        <Container maxWidth="sm">
          <Lottie options={animation} height={240} />
        </Container>
      </Box>
    </Page>
  );
};

export default RedirectToGoogle;
