import React from "react";

const FeatureContent = [
  {
    icon: "121",
    title: "Effortless Feedback Collection",
    subTitle: `Collect unlimited customer reviews through taps or scans, making it easy to gather real-time feedback.`,
    bgColor: "#FF4A8B",
  },
  {
    icon: "122",
    title: "Actionable Insights",
    subTitle: `Analyze the collected feedback to gain valuable insights and make informed decisions to improve your business.`,
    bgColor: "#6D49FF",
  },
  {
    icon: "123",
    title: "Customer Engagement",
    subTitle: `Boost customer engagement with digital coupons and automated promotions, encouraging repeat business and loyalty.`,
    bgColor: "#FFB951",
  },
];

const FancyFeatureTewentyTwo = () => {
  return (
    <>
      {FeatureContent.map((val, i) => (
        <div
          className="col-lg-4 col-md-6"
          data-aos="fade-up"
          data-aos-duration="1200"
          key={i}
        >
          <div className="block-style-twentyTwo">
            <div
              className="icon d-flex align-items-center justify-content-center"
              style={{ background: val.bgColor }}
            >
              <img src={`images/icon/${val.icon}.svg`} alt="icon" />
            </div>
            <h4>{val.title}</h4>
            <p>{val.subTitle}</p>
          </div>
          {/* /.block-style-twentyTwo */}
        </div>
      ))}
    </>
  );
};

export default FancyFeatureTewentyTwo;
