import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Grid,
  Container,
  Typography,
  Card,
  CardContent,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import QRCode from "qrcode.react"; // QR code generation library
import firebase from "../../../config/firebase";
import Page from "../../../utils/Page";
import Logo from "../../../icons/logo.png";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    background: `linear-gradient(135deg, #FAEED1 50%, ${theme.palette.secondary.main} 95%)`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    animation: "$fadeIn 2s",
    position: "relative",
    userSelect: "none",
  },
  "@keyframes fadeIn": {
    "0%": { opacity: 0 },
    "100%": { opacity: 1 },
  },
  glowingIcon: {
    margin: theme.spacing(1, 0),
    animation: "$glow 2.5s infinite",
    borderRadius: "50%",
    width: "110px",
    height: "110px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0px 0px 20px rgba(255, 255, 255, 0.7)",
    position: "relative",
    userSelect: "none",
  },
  "@keyframes glow": {
    "0%": { boxShadow: "0 0 5px rgba(255, 255, 255, 0.5)" },
    "50%": { boxShadow: "0 0 20px rgba(255, 255, 255, 1)" },
    "100%": { boxShadow: "0 0 5px rgba(255, 255, 255, 0.5)" },
  },
  footer: {
    position: "absolute",
    bottom: theme.spacing(1),
    textAlign: "center",
    color: "#fff",
    fontSize: "0.8rem",
    opacity: 0.8,
    width: "100%",
    userSelect: "none",
  },
  businessName: {
    fontSize: "1.9rem",
    fontWeight: "bold",
    textAlign: "center",
    color: "#fff",
    marginTop: theme.spacing(1),
    fontFamily: "'Roboto', sans-serif",
    textShadow: "2px 2px 8px rgba(0, 0, 0, 0.5)",
    userSelect: "none",
  },
  address: {
    textAlign: "center",
  },
  cardContainer: {
    backgroundColor: "#DED0B6", // Background for div card
    borderRadius: 8, // Rounded corners
    padding: theme.spacing(1),
    margin: theme.spacing(1),
  },
  qrCard: {
    background: `linear-gradient(150deg, #FAEED1 10%, ${theme.palette.secondary.main} 100%)`,
    textAlign: "center",
    borderRadius: 8, // Rounded corners for coupon shape
    border: "1px solid rgba(0, 0, 0, 0.1)", // Reduced border size
  },
  qrTitle: {
    textShadow: "2px 2px 8px rgba(0, 0, 0, 0.2)",
    fontSize: "1.5rem", // Increased title size
    fontWeight: "bold",
    color: "#fff", // Use a darker color for contrast
    marginBottom: theme.spacing(1), // Add spacing below title
  },
  qrDescription: {
    fontSize: "1rem", // Reduced description size
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(1), // Add spacing below description
  },
}));

function ShowCouponQr() {
  const classes = useStyles();
  const { merchantId, customerEmail, couponId } = useParams();
  const [merchant, setMerchant] = useState(null);

  useEffect(() => {
    getMerchantData(merchantId);
  }, [merchantId]);

  const getMerchantData = async (merchantId) => {
    try {
      const data = await firebase.getMerchantDetails(merchantId);
      if (data) {
        setMerchant(data);
        console.log("Merchant found " + data.businessName);
      } else {
        console.log("Merchant not found " + merchantId);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const couponLink = `https://www.reviewplusapp.com/redeem-offer/${merchantId}/${customerEmail}/${couponId}`;

  return (
    <Page title="Show Coupon QR Code" className={classes.root}>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
        alignItems="center"
      >
        <Container maxWidth="sm">
          {merchant && (
            <Grid
              item
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <Box className={classes.glowingIcon}>
                <img
                  src={merchant.businessLogoUrl || Logo}
                  alt="Business Logo"
                  style={{
                    borderRadius: "50%",
                    width: "110px",
                    height: "110px",
                    objectFit: "cover",
                  }}
                />
              </Box>
              <Typography className={classes.businessName}>
                {merchant.businessName}
              </Typography>
              {merchant.businessAddress && (
                <Typography variant="body2" className={classes.address}>
                  {merchant.businessAddress}
                </Typography>
              )}
            </Grid>
          )}
          <div className={classes.cardContainer}>
            <Card className={classes.qrCard}>
              <CardContent>
                <Typography className={classes.qrTitle}>
                  Show this QR code to the merchant to redeem your coupon.
                </Typography>
                <QRCode value={couponLink} size={128} />
              </CardContent>
            </Card>
          </div>
        </Container>
        <Typography className={classes.footer}>Powered by Review+</Typography>
      </Box>
    </Page>
  );
}

export default ShowCouponQr;
