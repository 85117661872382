import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import NavBar from "./NavBar";
import TopBar from "./TopBar";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper, // Use theme palette
    position: "relative", // Ensure relative positioning for the sticky footer
  },
  wrapper: {
    display: "flex",
    flex: "1 1 auto",
    [theme.breakpoints.up("md")]: {
      paddingLeft: 256,
    },
  },
  contentContainer: {
    display: "flex",
    flex: "1 1 auto",
  },
  content: {
    flex: "1 1 auto",
    height: "100vh",
    overflow: "auto",
    paddingTop: 64, // Add padding to account for the TopBar
  },
  topBar: {
    position: "fixed",
    width: "100%",
    top: 0,
    zIndex: 1100,
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

const DashboardLayout = () => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <div className={classes.root}>
      <TopBar
        className={classes.topBar}
        onMobileNavOpen={() => setMobileNavOpen(true)}
      />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
