import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Grid,
  Typography,
  LinearProgress,
} from "@mui/material";
import firebase from "../../config/firebase";
import Logo from "../../icons/logo.png";
import LazyLoad from "react-lazyload";
import Page from "../../utils/Page";
import { useTranslation } from "react-i18next";
import "../../utils/i18n"; // Ensure i18n is initialized

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    background: `linear-gradient(135deg, ${theme.palette.primary.main} 20%, ${theme.palette.secondary.main} 95%)`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    animation: "$fadeIn 2s",
  },
  "@keyframes fadeIn": {
    "0%": { opacity: 0 },
    "100%": { opacity: 1 },
  },
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(12, 1fr)",
    gridGap: `${theme.spacing(3)}px`,
  },
  appTitle: {
    marginBottom: theme.spacing(3),
  },
  logoContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: theme.spacing(3),
    animation: "$fadeIn 2.5s",
  },
  logo: {
    borderRadius: "50%",
    width: "130px",
    height: "130px",
    objectFit: "cover",
    boxShadow: theme.shadows[5],
  },
  text: {
    textAlign: "center",
    color: "#000",
    animation: "$fadeIn 2.5s",
  },
  title: {
    fontFamily: "'Roboto', sans-serif",
    textShadow: "2px 2px 8px rgba(0, 0, 0, 0.5)",
    color: "#000",
  },
  progressContainer: {
    position: "absolute",
    bottom: theme.spacing(2),
    width: "60%",
    left: "20%",
    right: "20%",
    animation: "$fadeIn 3s",
  },
  progressBar: {
    height: "6px",
    borderRadius: "4px",
    background: `
    linear-gradient(to bottom right, ${theme.palette.primary.main}, ${theme.palette.secondary.main}),
    radial-gradient(circle at center, transparent 80%, #d3d3d3 80%)
    `,
  },
}));

const CustomerViewTableMode = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const [isImgLoaded, setIsImgLoaded] = useState(false);

  const [fieldValues, setFieldValues] = useState({
    merchantID: null,
    merchantEmail: null,
    businessName: null,
    googleReviewUrl: null,
    urls: null,
    language: null,
    currentTime: new Date().toUTCString(),
  });

  async function getMerchantDetails() {
    try {
      const res = await firebase.getMerchantDetails(id);
      if (res) {
        const language = res.settings.defaultLanguage || "en";
        i18n.changeLanguage(language); // Change language based on merchant details

        setFieldValues((prevValues) => ({
          ...prevValues,
          merchantID: id,
          businessName: res.businessName,
          businessAddress: res.businessAddress,
          businessLogoUrl: res.businessLogoUrl,
          googleReviewUrl: res.googleReviewUrl,
          merchantEmail: res.email,
          urls: res.urls,
          language,
        }));

        if (res.businessName) {
          setTimeout(() => {
            navigate(
              "/customer-exp-tablet-mode/",
              {
                state: {
                  merchantID: id,
                  businessName: res.businessName,
                  businessAddress: res.businessAddress,
                  businessLogoUrl: res.businessLogoUrl,
                  googleReviewUrl: res.googleReviewUrl,
                  ownerPhone: res.ownerPhone,
                  merchantEmail: res.email,
                  urls: res.urls,
                  settings: res.settings,
                  subscriptions: res.subscriptions,
                  language,
                  createdTimeStamp: Date.now(),
                },
              },
              { replace: true }
            );
          }, 3000);
        }

        await recordQrScan();
      } else {
        navigate("/not-found", { replace: true });
      }
    } catch (e) {
      console.log(e);
      navigate("/not-found", { replace: true });
    }
  }

  useEffect(() => {
    getMerchantDetails();
  }, []);

  async function recordQrScan() {
    try {
      await firebase.updateMerchantQrScan(id);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Page className={classes.root} title={t("CUSTOMER_REVIEW")}>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
        alignItems="center"
      >
        <Container maxWidth="sm">
          <Grid
            item
            className={classes.appTitle}
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <div className={classes.logoContainer}>
              {fieldValues.businessLogoUrl !== "" &&
              fieldValues.businessLogoUrl !== null ? (
                <LazyLoad once placeholder={<p>{t("LOADING")}</p>}>
                  <Box
                    component="img"
                    alt="Business Logo"
                    src={fieldValues.businessLogoUrl}
                    className={classes.logo}
                    onLoad={() => setIsImgLoaded(true)}
                    style={{ display: isImgLoaded ? "block" : "none" }}
                  />
                </LazyLoad>
              ) : (
                <LazyLoad once placeholder={<p>{t("LOADING")}</p>}>
                  <img
                    src={Logo}
                    alt="Business Logo"
                    className={classes.logo}
                  />
                </LazyLoad>
              )}
            </div>
            <Typography variant="h4" className={classes.text}>
              {t("WELCOME_TO")}
            </Typography>
            <Typography
              variant="h2"
              mt={1}
              className={`${classes.text} ${classes.title}`}
            >
              <strong>{fieldValues.businessName}</strong>
            </Typography>
            <Typography variant="body1" mt={1} className={classes.text}>
              {fieldValues.businessAddress}
            </Typography>
          </Grid>
        </Container>
        <Box className={classes.progressContainer}>
          <LinearProgress className={classes.progressBar} />
        </Box>
      </Box>
    </Page>
  );
};

export default CustomerViewTableMode;
