import React, { useEffect, useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import firebase from "../../../config/firebase";
import MerchantContext from "../../../config/MerchantContext";
import {
  Card,
  CardContent,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  IconButton,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  InputAdornment,
  Autocomplete,
  Avatar,
  Menu,
  CircularProgress,
  FormHelperText,
  Radio,
  RadioGroup,
  FormControlLabel,
  Tooltip,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format } from "date-fns";
import Flag from "@mui/icons-material/Flag";
import countries from "../../../utils/countries";
import AngryAnim from "../../../utils/animations/angryanim.json";
import HappyAnim from "../../../utils/animations/happyanim.json";
import NeutralAnim from "../../../utils/animations/neutralanim.json";
import SadAnim from "../../../utils/animations/sadanim.json";
import LovedAnim from "../../../utils/animations/lovedanim.json";
import Lottie from "lottie-react";
import { sendWinbackEmail, sendSMS } from "../../../config/cloudApis";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import useUpdateEffect from "../../../utils/useUpdateEffect";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CakeIcon from "@mui/icons-material/Cake";
import dayjs from "dayjs";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Page from "../../../utils/Page";

const emojiAnimations = {
  angry: AngryAnim,
  sad: SadAnim,
  ok: NeutralAnim,
  happy: HappyAnim,
  loved: LovedAnim,
};

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    [{ align: [] }],
    [{ color: [] }, { background: [] }],
    [{ font: [] }],
    ["clean"],
  ],
};

const formats = [
  "header",
  "font",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "align",
  "color",
  "background",
];

const FullCustomerDetails = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { currentMerchant, plan, isSubscribed } = useContext(MerchantContext);

  useEffect(() => {
    if (!location.state || !location.state.customerData) {
      navigate("/app/dashboard");
    }
  }, [location.state, navigate]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [customerData, setCustomerData] = useState(
    location.state?.customerData || {}
  );
  const [editData, setEditData] = useState(customerData);

  const { filteredCustomers } = location.state || { filteredCustomers: [] };
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [alertDialogOpen, setAlertDialogOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [customerCoupons, setCustomerCoupons] = useState([]);
  const [birthdayOffer, setBirthdayOffer] = useState(null);
  const [birthdayOfferDialogOpen, setBirthdayOfferDialogOpen] = useState(false);
  const [deleteBirthdayCouponDialogOpen, setDeleteBirthdayCouponDialogOpen] =
    useState(false);
  const [error, setError] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [selectedVisit, setSelectedVisit] = useState(null);
  const [emailDialogOpen, setEmailDialogOpen] = useState(false);
  const [availableCoupons, setAvailableCoupons] = useState([]);
  const [selectedCoupon, setSelectedCoupon] = useState("");
  const [selectedBirthdayCoupon, setSelectedBirthdayCoupon] = useState("");
  const [couponValidityDate, setCouponValidityDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [winbackSubjectText, setWinbackSubjectText] = useState("");

  const [birthdayOfferEnabled, setBirthdayOfferEnabled] = useState(
    customerData.birthdayOfferDetails?.sendEmail || false
  );
  const [customerScans, setCustomerScans] = useState([]);

  const currentCustomerEmail = customerData.email;
  const [winbackMethod, setWinbackMethod] = useState("email");

  const sentimentBasedSubjects = {
    angry: [
      "[Customer Name], We're Sorry – Here's an Exclusive Offer to Make It Right",
      "[Customer Name], We Value Your Feedback – Please Accept This Gift",
      "A Special Offer for You, [Customer Name] – We Want to Regain Your Trust",
    ],
    sad: [
      "[Customer Name], We Miss You! Here's a Little Something to Cheer You Up",
      "A Special Offer for You, [Customer Name] – We'd Love to See You Again",
      "We'd Love to Have You Back, [Customer Name]! Here's an Exclusive Discount",
    ],
    ok: [
      "[Customer Name], Thanks for the Feedback! Here's a Coupon for Your Next Visit",
      "An Exclusive Offer for You, [Customer Name] – We Appreciate Your Input",
      "We're Always Improving! Here's a Gift to Show Our Gratitude, [Customer Name]",
    ],
    happy: [
      "[Customer Name], We're So Glad You Had a Great Time! Enjoy This Bonus",
      "Thank You for the Kind Words, [Customer Name]! Here's a Special Offer",
      "A Little Surprise for You, [Customer Name] – Because You Deserve It!",
    ],
    loved: [
      "[Customer Name], You're Awesome! Here's an Exclusive Offer Just for You",
      "A Huge Thank You, [Customer Name]! Please Enjoy This Special Gift",
      "We Love Having You as a Customer! Here's a Token of Our Appreciation",
    ],
  };

  const templates = [
    "We've missed you!  To show our appreciation for your past visits, we're sending you a special coupon for your next purchase.",
    "Hey, it's been too long! Come back and visit us soon. We have a special treat waiting just for you.",
    "We hope everything's been well. We wanted to let you know we value your business and are offering you this exclusive discount on your next visit.",
    "Don't be a stranger! We have a special offer you won't want to miss. Come back and see what's new!",
    "We're thinking of you! We're excited to welcome you back with this special coupon as a thank you for being a loyal customer.",
    "Long time no see! We've been saving a special discount just for you. Use this coupon on your next visit and rediscover what you love about us.",
    "We hope you'll give us another chance! We've made some improvements and added new offerings that we think you'll enjoy. Here's a coupon to sweeten the deal.",
    "We've missed having you around. Please accept this exclusive offer as a small way of saying we appreciate your business.",
    "Just a little something to show we care. Come back and redeem this special coupon on your next purchase.",
    "We want you back! We've got a great deal just for you. Use this coupon and visit us again soon!",
  ];

  const [selectedTemplate, setSelectedTemplate] = useState(templates[0]);
  const [emailContent, setEmailContent] = useState("");

  const filteredVisits = filteredCustomers.filter(
    (visit) => visit.email === currentCustomerEmail
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDeleteCustomer = async () => {
    setDeleteDialogOpen(false);
    try {
      await firebase.deleteCustomer(
        customerData.merchantId,
        customerData.email
      );
      setAlertMessage("Customer deleted successfully!");
      setAlertDialogOpen(true);
      navigate(-1);
    } catch (error) {
      setError(error.message);
      setErrorDialogOpen(true);
    }
  };

  const handleEditCustomer = async () => {
    const fieldsToUpdate = {
      country: editData.country,
      dob: editData.dob,
      email: editData.email,
      gender: editData.gender,
      name: editData.name,
      optEmail: editData.optEmail,
      phone: editData.phone,
    };

    const cleanedData = {};
    Object.keys(fieldsToUpdate).forEach((key) => {
      if (fieldsToUpdate[key] !== undefined) {
        cleanedData[key] = fieldsToUpdate[key];
      }
    });

    try {
      await firebase.updateCustomerInfo(
        customerData.merchantId,
        editData.email,
        cleanedData
      );

      setCustomerData(cleanedData);
      setAlertMessage("Customer updated successfully!");
      setAlertDialogOpen(true);
      setEditDialogOpen(false);
    } catch (error) {
      console.error("Error updating customer:", error);
      setError(error.message);
      setErrorDialogOpen(true);
    }
  };

  useEffect(() => {
    setEditData(customerData);

    const fetchCustomerCoupons = async () => {
      try {
        const couponsRef = firebase.db.collection(
          `merchants/${customerData.merchantId}/customers/${customerData.email}/coupons`
        );
        const couponsSnapshot = await couponsRef.get();

        if (!couponsSnapshot.empty) {
          const couponsData = couponsSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setCustomerCoupons(couponsData);
        } else {
          setCustomerCoupons([]);
        }

        const merchantRef = firebase.db
          .collection("merchants")
          .doc(customerData.merchantId);
        const merchantSnapshot = await merchantRef.get();
        const merchantData = merchantSnapshot.data();

        if (merchantData.birthdayOfferDetails) {
          setBirthdayOffer(merchantData.birthdayOfferDetails);
          setSelectedBirthdayCoupon(
            merchantData.birthdayOfferDetails.couponDetails?.id || ""
          );
        }
      } catch (error) {
        console.error("Error fetching customer coupons:", error);
      }
    };

    fetchCustomerCoupons();
  }, [customerData]);

  const handleCloseErrorDialog = () => {
    setErrorDialogOpen(false);
  };

  const handleCloseAlertDialog = () => {
    setAlertDialogOpen(false);
  };

  const handleOpenDeleteDialog = () => {
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleOpenEditDialog = () => {
    setEditDialogOpen(true);
  };

  const handleCloseEditDialog = () => {
    setEditDialogOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditData({
      ...editData,
      [name]: value,
    });
  };

  const handleDateChange = (date) => {
    if (date && !isNaN(date.getTime())) {
      setEditData({
        ...editData,
        dob: format(date, "yyyy-MM-dd"),
      });
    } else {
      console.error("Invalid date:", date);
    }
  };

  const handleCountryChange = (event, value) => {
    setEditData({
      ...editData,
      country: value ? value.label : "",
    });
  };

  const handleMenuOpen = (event, visit) => {
    setSelectedVisit(visit);
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleSendEmailConfirm = async () => {
    if (!window.confirm("Are you sure you want to send this winback offer?")) {
      return;
    }

    setLoading(true);

    try {
      const selectedCouponData = availableCoupons.find(
        (coupon) => coupon.id === selectedCoupon
      );

      const updatedEmailContent = emailContent.replace(
        /(\r\n|\n|\r)/gm,
        "<br/>"
      );

      if (winbackMethod === "email") {
        await sendWinbackEmail(
          customerData.email,
          customerData.name,
          customerData.merchantId,
          updatedEmailContent,
          selectedCouponData,
          winbackSubjectText
        );
      } else if (winbackMethod === "sms") {
        const merchantDetails = await firebase.getMerchantDetails(
          customerData.merchantId
        );
        const businessAddress = merchantDetails?.businessAddress || "";
        const businessName = merchantDetails?.businessName || "";

        const plainTextContent = updatedEmailContent.replace(/<[^>]+>/g, "");

        const couponLink = `https://www.reviewplusapp.com/coupon-link/${customerData.merchantId}/${customerData.email}/${selectedCouponData.id}`;

        const message = `Dear ${customerData.name},\n\n${plainTextContent}\n\nYour Exclusive Offer: ${selectedCouponData.name}\n\nRedeem this at ${businessName}.\n\nLocation: ${businessAddress}\n\nCoupon Link: ${couponLink}`;

        await sendSMS(
          customerData.merchantId,
          customerData.email,
          customerData.phone,
          message
        );
      }

      await firebase.updateScanWithWinbackOffer(
        customerData.merchantId,
        customerData.email,
        selectedVisit.visitDate,
        selectedCouponData
      );

      setAlertMessage("Win-back offer sent successfully!");
      setAlertDialogOpen(true);
    } catch (error) {
      console.error("Error sending win-back offer:", error);
      setError(error.message);
      setErrorDialogOpen(true);
    }

    setLoading(false);
    setEmailDialogOpen(false);
  };

  const handleDeleteVisit = async () => {
    try {
      await firebase.deleteVisit(currentCustomerEmail, selectedVisit.visitDate);
      setAlertMessage("Visit deleted successfully!");
      setAlertDialogOpen(true);
    } catch (error) {
      console.error("Error deleting visit:", error);
      setError(error.message);
      setErrorDialogOpen(true);
    }
    handleMenuClose();
  };

  // useEffect(() => {
  //   const fetchCoupons = async () => {
  //     try {
  //       const merchantID = currentMerchant?.merchantID;

  //       // console.log("Fetching coupons for merchant ID:", merchantID);

  //       const couponsData = await firebase.fetchCoupons(merchantID);
  //       setAvailableCoupons(couponsData);

  //       //console.log("Available coupons:", couponsData);

  //       if (customerData.birthdayOfferDetails) {
  //         // console.log(
  //         //   "Birthday offer details found:",
  //         //   customerData.birthdayOfferDetails
  //         // );

  //         setSelectedBirthdayCoupon(
  //           customerData.birthdayOfferDetails.couponDetails.id || ""
  //         );

  //         setBirthdayOfferEnabled(
  //           customerData.birthdayOfferDetails.sendEmail || false
  //         );

  //         // console.log(
  //         //   "Selected birthday coupon:",
  //         //   customerData.birthdayOfferDetails.couponDetails.id
  //         // );
  //         // console.log(
  //         //   "Is birthday offer enabled:",
  //         //   customerData.birthdayOfferDetails.sendEmail
  //         // );
  //       }
  //     } catch (err) {
  //       setError("Failed to load coupons");
  //       console.error("Error fetching coupons:", err);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchCoupons();
  // }, [currentMerchant, customerData]);

  useEffect(() => {
    const fetchCoupons = async () => {
      try {
        const merchantID = currentMerchant?.merchantID;
        const couponsData = await firebase.fetchCoupons(merchantID);
        setAvailableCoupons(couponsData);

        if (customerData.birthdayOfferDetails) {
          const birthdayCoupon =
            customerData.birthdayOfferDetails.couponDetails;

          // Check if the coupon is redeemed or expired
          const isExpired = new Date(birthdayCoupon.expiryDate) < new Date();
          const isRedeemed = customerData.birthdayOfferDetails.isRedeemed;

          if (isExpired || isRedeemed) {
            setBirthdayOffer(null);
            setAlertMessage(
              isExpired
                ? "Previous birthday coupon expired and has been removed."
                : "Previous birthday coupon was redeemed and has been removed."
            );
            await firebase.removeBirthdayOffer(
              customerData.merchantId,
              customerData.email
            );
            setAlertDialogOpen(true);
          } else {
            setSelectedBirthdayCoupon(birthdayCoupon.id || "");
            setBirthdayOfferEnabled(
              customerData.birthdayOfferDetails.sendEmail || false
            );
            setBirthdayOffer(customerData.birthdayOfferDetails);
          }
        }
      } catch (err) {
        setError("Failed to load coupons");
        console.error("Error fetching coupons:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchCoupons();
  }, [currentMerchant, customerData]);

  useEffect(() => {
    const fetchCustomerScans = async () => {
      if (!customerData || !customerData.email) {
        return;
      }

      try {
        const merchantID = currentMerchant?.merchantID;

        const scansData = await firebase.fetchMerchantCustomerScans(
          merchantID,
          customerData.email
        );

        setCustomerScans(scansData);
      } catch (err) {
        setError("Failed to load customer scans");
        console.error("Error fetching customer scans:", err);
      }
    };

    fetchCustomerScans();
  }, [currentMerchant, customerData.email]);

  useUpdateEffect(() => {
    if (customerData.country) {
      setEditData({ ...editData, country: customerData.country });
    }
  }, [customerData.country]);

  useUpdateEffect(() => {
    const couponMatch = selectedTemplate.match(
      /<strong>Your Exclusive Offer:.*?<\/strong>/
    );
    if (!couponMatch && selectedCoupon) {
      const coupon = availableCoupons.find((c) => c.id === selectedCoupon);
      const couponContent = `<br/><br/><strong>Your Exclusive Offer:</strong> ${coupon.name}`;

      setEmailContent(
        emailContent.replace(
          /<br\/><br\/><strong>Your Exclusive Offer:.*?<\/strong>/,
          couponContent
        )
      );
    } else {
      setEmailContent(selectedTemplate);
    }
  }, [selectedTemplate, selectedCoupon]);

  const handleWinBackCustomer = async () => {
    const initialSubjects =
      sentimentBasedSubjects[selectedVisit?.feeling || "ok"];
    const initialSubject = initialSubjects[0].replace(
      "[Customer Name]",
      customerData.name || ""
    );
    setWinbackSubjectText(initialSubject);
    setEmailDialogOpen(true);
    handleMenuClose();
  };

  const handleSetBirthdayOffer = async () => {
    try {
      const selectedCouponData = availableCoupons.find(
        (coupon) => coupon.id === selectedBirthdayCoupon
      );

      await firebase.setBirthdayOffer(
        customerData.merchantId,
        customerData.email,
        {
          couponDetails: selectedCouponData,
          sendEmail: birthdayOfferEnabled,
        }
      );

      setAlertMessage("The Birthday offer is updated successfully");
      setAlertDialogOpen(true);
    } catch (error) {
      console.error("Error setting birthday offer:", error);
      setError(error.message);
      setErrorDialogOpen(true);
    }

    setBirthdayOfferDialogOpen(false);
  };

  const handleRemoveBirthdayOffer = async () => {
    try {
      await firebase.removeBirthdayOffer(
        customerData.merchantId,
        customerData.email
      );
      setBirthdayOffer(null);
      setDeleteBirthdayCouponDialogOpen(false);
      navigate(-1);
    } catch (error) {
      console.error("Error removing birthday offer:", error);
    }
  };

  return (
    <Page title="Customer Details">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Card style={{ width: "900px", height: "100%", margin: "10px 20px" }}>
          <CardContent>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                margin: "16px 16px 8px 16px",
                padding: "8px",
              }}
            >
              <IconButton
                onClick={() => navigate(-1)}
                style={{ position: "absolute", left: 0 }}
                disabled={loading}
              >
                <ArrowBackIcon />
              </IconButton>
              <Typography
                variant="h3"
                component="h1"
                style={{
                  fontWeight: "bold",
                }}
              >
                Customer Details
              </Typography>
              <Avatar
                alt={customerData.name}
                src="/path/to/profile/picture.jpg"
                style={{ position: "absolute", right: 0 }}
              />
            </div>
            <FormHelperText
              style={{
                textAlign: "center",
                margin: "10px 20px",
              }}
            >
              Contains information about your customer. Check visits, schedule
              exclusive birthday offers, send instant retention/win back offer
              if customer has negative experience.
            </FormHelperText>
            <Typography color="textSecondary" style={{ margin: "10px 20px" }}>
              <strong>Full Name: </strong>
              {customerData.name}
            </Typography>
            <Typography color="textSecondary" style={{ margin: "10px 20px" }}>
              <strong>Gender: </strong>
              {customerData.gender || "Not provided"}
            </Typography>
            <Typography color="textSecondary" style={{ margin: "10px 20px" }}>
              <strong>Email: </strong>
              {customerData.email}
            </Typography>
            <Typography color="textSecondary" style={{ margin: "10px 20px" }}>
              <strong>Mobile: </strong>
              {customerData.phone}
            </Typography>
            <Typography color="textSecondary" style={{ margin: "10px 20px" }}>
              <strong>Country: </strong>
              {customerData.country || "Not provided"}
            </Typography>
            <Typography color="textSecondary" style={{ margin: "10px 20px" }}>
              <strong>Date of Birth: </strong>
              {customerData.dob || "Not provided"}
            </Typography>
            <Typography color="textSecondary" style={{ margin: "10px 20px" }}>
              <strong>Latest Feedback: </strong>
              {customerData.lastFeedback || "Not provided"}
            </Typography>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Tooltip
                title={
                  plan === "BASIC_01"
                    ? "Not available in Basic plan"
                    : customerData.dob === "Not provided"
                    ? "Date of birth not available for this customer"
                    : ""
                }
                arrow
              >
                <span>
                  <Button
                    variant="contained"
                    onClick={() => setBirthdayOfferDialogOpen(true)}
                    style={{ margin: "20px" }}
                    disabled={
                      loading ||
                      plan === "BASIC_01" ||
                      customerData.dob === "Not provided"
                    }
                    color="warning"
                    startIcon={!isMobile && <CakeIcon />}
                    sx={{
                      fontSize: isMobile ? "0.6rem" : "0.8rem",
                      margin: theme.spacing(2),
                      color: "white",
                    }}
                  >
                    {birthdayOffer
                      ? "Birthday Offer Scheduled"
                      : "Schedule Birthday Offer"}
                  </Button>
                </span>
              </Tooltip>

              {birthdayOffer && (
                <Tooltip title="Remove Birthday Offer" arrow>
                  <span>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => setDeleteBirthdayCouponDialogOpen(true)}
                      style={{ margin: "20px" }}
                      disabled={loading}
                    >
                      Remove Birthday Offer
                    </Button>
                  </span>
                </Tooltip>
              )}

              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenEditDialog}
                style={{ margin: "20px" }}
                disabled={loading}
                startIcon={!isMobile && <EditIcon />}
                sx={{
                  fontSize: isMobile ? "0.6rem" : "0.8rem",
                  margin: theme.spacing(2),
                }}
              >
                Edit Customer
              </Button>

              <Button
                variant="contained"
                color="secondary"
                onClick={handleOpenDeleteDialog}
                style={{ margin: "20px" }}
                disabled={loading}
                startIcon={!isMobile && <DeleteIcon />}
                sx={{
                  fontSize: isMobile ? "0.6rem" : "0.8rem",
                  margin: theme.spacing(2),
                  color: "white",
                }}
              >
                Delete Customer
              </Button>
            </div>

            {customerCoupons.length > 0 && (
              <>
                <Typography
                  variant="h4"
                  style={{
                    marginTop: "20px",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  Active Coupons
                </Typography>
                <FormHelperText
                  style={{
                    textAlign: "center",
                  }}
                >
                  All active coupons for {customerData.name} are displayed here.
                  Once the expiry date is completed, the coupon becomes
                  inactive.
                </FormHelperText>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ fontWeight: "bold" }}>
                          Coupon Name
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                          Validity
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                          Redeemed Date
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                          Redeemed
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {customerCoupons.map((coupon) => (
                        <TableRow key={coupon.selectedCoupon.id}>
                          <TableCell>{coupon.selectedCoupon.name}</TableCell>
                          <TableCell>
                            {coupon.selectedCoupon.expiryDate}
                          </TableCell>
                          <TableCell>
                            {coupon.isCouponRedeemed
                              ? coupon.redemptionDate
                              : "N/A"}
                          </TableCell>
                          <TableCell>
                            {coupon.isCouponRedeemed ? "Yes" : "No"}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}

            {birthdayOffer && (
              <>
                <Typography
                  variant="h4"
                  style={{
                    marginTop: "20px",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  Birthday Offer
                </Typography>
                <FormHelperText
                  style={{
                    textAlign: "center",
                  }}
                >
                  The coupon currently assigned for {customerData.name}'s
                  birthday is displayed here. You can remove or update the
                  birthday offer as needed.
                </FormHelperText>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ fontWeight: "bold" }}>
                          Coupon Name
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                          Validity
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                          Redeemed Date
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                          Redeemed
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          {birthdayOffer.couponDetails.name}
                        </TableCell>
                        <TableCell>
                          {birthdayOffer.couponDetails.expiryDate}
                        </TableCell>
                        <TableCell>
                          {birthdayOffer.isRedeemed
                            ? birthdayOffer.redemptionDate
                            : "N/A"}
                        </TableCell>
                        <TableCell>
                          {birthdayOffer.isRedeemed ? "Yes" : "No"}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}

            <Typography
              variant="h4"
              style={{
                marginTop: "20px",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              Customer Experiences
            </Typography>
            <FormHelperText
              style={{
                textAlign: "center",
              }}
            >
              This contains information of all dates {customerData.name} has
              visited your business. Use options menu to send winback coupons to{" "}
              {customerData.name}
            </FormHelperText>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: "15%", fontWeight: "bold" }}>
                      Visited Date
                    </TableCell>
                    <TableCell style={{ width: "5%", fontWeight: "bold" }}>
                      Experience
                    </TableCell>
                    <TableCell style={{ width: "60%", fontWeight: "bold" }}>
                      Feedback
                    </TableCell>
                    <TableCell style={{ width: "20%", fontWeight: "bold" }}>
                      Options
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {customerScans.map((scan, index) => (
                    <TableRow key={index}>
                      <TableCell>{scan.visitDate}</TableCell>
                      <TableCell>
                        <Lottie
                          animationData={emojiAnimations[scan.feeling]}
                          style={{
                            width: scan.feeling === "angry" ? "30px" : "40px",
                            height: scan.feeling === "angry" ? "30px" : "40px",
                          }}
                        />
                      </TableCell>
                      <TableCell>{scan.feedback || "Not provided"}</TableCell>
                      <TableCell>
                        <IconButton
                          onClick={(event) => handleMenuOpen(event, scan)}
                          disabled={loading}
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          anchorEl={menuAnchorEl}
                          open={Boolean(menuAnchorEl)}
                          onClose={handleMenuClose}
                        >
                          <MenuItem onClick={handleWinBackCustomer}>
                            Send Instant Winback Coupon
                          </MenuItem>

                          <MenuItem onClick={handleDeleteVisit}>
                            Delete Visit
                          </MenuItem>
                        </Menu>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={filteredVisits.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </CardContent>
        </Card>

        <Dialog
          open={birthdayOfferDialogOpen}
          onClose={() => {
            setSelectedBirthdayCoupon("");
            setBirthdayOfferEnabled(
              customerData.birthdayOffer?.sendEmail || false
            );
            setBirthdayOfferDialogOpen(false);
          }}
        >
          <DialogTitle style={{ fontSize: "20px", fontWeight: "bold" }}>
            {birthdayOffer
              ? "Birthday Offer Scheduled 🎉"
              : "Schedule Birthday Offer 🎂"}
          </DialogTitle>
          <DialogContent>
            {birthdayOffer &&
              (birthdayOffer.isRedeemed ||
                new Date(birthdayOffer.couponDetails.expiryDate) <
                  new Date()) && (
                <Typography color="error">
                  {birthdayOffer.isRedeemed
                    ? "This birthday coupon has already been redeemed."
                    : "This birthday coupon has expired."}{" "}
                  It will be removed from the customer's account.
                </Typography>
              )}
            <FormControl fullWidth variant="outlined" margin="dense">
              <Typography>Choose Coupon</Typography>
              <Select
                value={
                  selectedBirthdayCoupon ||
                  customerData.birthdayOffer?.couponDetails?.id ||
                  ""
                }
                onChange={(e) => setSelectedBirthdayCoupon(e.target.value)}
                displayEmpty
              >
                <MenuItem value="" disabled>
                  Select Coupon
                </MenuItem>
                {availableCoupons.map((coupon) => (
                  <MenuItem key={coupon.id} value={coupon.id}>
                    {coupon.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                Choose the coupon you want to send for the customer's birthday.
              </FormHelperText>
            </FormControl>

            <FormControl fullWidth variant="outlined" margin="dense">
              <Typography>Enable Birthday Offer</Typography>
              <Select
                value={
                  birthdayOfferEnabled || customerData.birthdayOffer?.sendEmail
                    ? "yes"
                    : "no"
                }
                onChange={(e) =>
                  setBirthdayOfferEnabled(e.target.value === "yes")
                }
                disabled={!selectedBirthdayCoupon}
              >
                <MenuItem value="yes">Yes</MenuItem>
                <MenuItem value="no">No</MenuItem>
              </Select>
              <FormHelperText>
                Enabling this option will send an email to {customerData.name}{" "}
                this year on their birthday.
              </FormHelperText>
            </FormControl>
          </DialogContent>

          <DialogActions>
            <Button
              onClick={() => setBirthdayOfferDialogOpen(false)}
              color="secondary"
            >
              Cancel
            </Button>
            <Button
              onClick={handleSetBirthdayOffer}
              color="secondary"
              variant="contained"
              disabled={!selectedBirthdayCoupon}
            >
              Set Offer
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={deleteBirthdayCouponDialogOpen}
          onClose={() => setDeleteBirthdayCouponDialogOpen(false)}
        >
          <DialogTitle style={{ fontSize: "20px", fontWeight: "bold" }}>
            Remove Birthday Coupon 😟
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to remove the birthday coupon? This action
              cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setDeleteBirthdayCouponDialogOpen(false)}
              color="secondary"
            >
              Cancel
            </Button>
            <Button
              onClick={handleRemoveBirthdayOffer}
              color="secondary"
              variant="contained"
            >
              Remove
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
          <DialogTitle style={{ fontSize: "20px", fontWeight: "bold" }}>
            Delete Customer
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this customer? This action cannot
              be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={handleCloseDeleteDialog}
              color="secondary"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleDeleteCustomer}
              color="primary"
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={editDialogOpen} onClose={handleCloseEditDialog}>
          <DialogTitle style={{ fontSize: "20px", fontWeight: "bold" }}>
            Edit Customer 🧍🏻
          </DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              label="Name"
              name="name"
              value={editData.name}
              onChange={handleInputChange}
              fullWidth
            />
            <FormControl fullWidth margin="dense">
              <InputLabel>Gender</InputLabel>
              <Select
                name="gender"
                value={editData.gender}
                onChange={handleInputChange}
                fullWidth
              >
                <MenuItem value="male">Male</MenuItem>
                <MenuItem value="female">Female</MenuItem>
                <MenuItem value="other">Others</MenuItem>
                <MenuItem value="Not provided">Not provided</MenuItem>
              </Select>
            </FormControl>
            <TextField
              margin="dense"
              label="Email"
              name="email"
              value={editData.email}
              onChange={handleInputChange}
              fullWidth
            />
            <TextField
              margin="dense"
              label="Mobile"
              name="phone"
              value={editData.phone}
              onChange={handleInputChange}
              fullWidth
            />
            <Autocomplete
              fullWidth
              options={countries}
              getOptionLabel={(option) => `${option.label}`}
              value={
                countries.find(
                  (country) => country.label === editData.country
                ) || null
              }
              onChange={handleCountryChange}
              isOptionEqualToValue={(option, value) =>
                option.label === value.label
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  margin="dense"
                  label="Choose your Country"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <Flag />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />

            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Date of Birth"
                value={editData.dob ? dayjs(editData.dob) : null}
                onChange={handleDateChange}
                renderInput={(params) => (
                  <TextField {...params} margin="dense" fullWidth />
                )}
                maxDate={dayjs().subtract(10, "year").toDate()}
                inputFormat="yyyy-MM-dd"
              />
            </LocalizationProvider>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseEditDialog} color="secondary">
              Cancel
            </Button>
            <Button
              onClick={handleEditCustomer}
              variant="contained"
              color="secondary"
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={errorDialogOpen} onClose={handleCloseErrorDialog}>
          <DialogTitle style={{ fontSize: "20px", fontWeight: "bold" }}>
            Error
          </DialogTitle>
          <DialogContent>
            <DialogContentText>{error}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseErrorDialog}
              color="secondary"
              variant="contained"
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={alertDialogOpen} onClose={handleCloseAlertDialog}>
          <DialogTitle style={{ fontSize: "20px", fontWeight: "bold" }}>
            Action Confirmation
          </DialogTitle>
          <DialogContent>
            <DialogContentText>{alertMessage}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={handleCloseAlertDialog}
              color="secondary"
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={emailDialogOpen}
          onClose={() => setEmailDialogOpen(false)}
        >
          <DialogTitle style={{ fontSize: "20px", fontWeight: "bold" }}>
            Send Winback Coupon 💳
          </DialogTitle>
          <DialogContent>
            <FormControl component="fieldset" style={{ marginBottom: "16px" }}>
              <Typography>Select Method</Typography>
              <RadioGroup
                row
                value={winbackMethod}
                onChange={(e) => setWinbackMethod(e.target.value)}
              >
                <FormControlLabel
                  value="email"
                  control={
                    <Radio
                      sx={{
                        color: "secondary.main",
                        "&.Mui-checked": { color: "secondary.main" },
                      }}
                    />
                  }
                  label="Email"
                />
                <FormControlLabel
                  value="sms"
                  control={
                    <Radio
                      sx={{
                        color: "secondary.main",
                        "&.Mui-checked": { color: "secondary.main" },
                      }}
                    />
                  }
                  label="SMS"
                />
              </RadioGroup>
            </FormControl>

            <FormControl
              fullWidth
              variant="outlined"
              margin="dense"
              label="Select Subject"
              disabled={winbackMethod === "sms"}
            >
              <InputLabel shrink>Select Subject</InputLabel>
              <Select
                value={winbackSubjectText}
                onChange={(e) => setWinbackSubjectText(e.target.value)}
                displayEmpty
                fullWidth
              >
                {sentimentBasedSubjects[selectedVisit?.feeling || "ok"].map(
                  (subject, index) => (
                    <MenuItem
                      key={index}
                      value={subject.replace(
                        "[Customer Name]",
                        customerData.name || ""
                      )}
                    >
                      {subject.replace(
                        "[Customer Name]",
                        customerData.name || ""
                      )}
                    </MenuItem>
                  )
                )}
              </Select>
              <FormHelperText>
                Choose a subject line that aligns with the customer's
                experience.
              </FormHelperText>
            </FormControl>

            <FormControl
              fullWidth
              variant="outlined"
              margin="dense"
              label="Select Coupon"
            >
              <Select
                value={selectedCoupon}
                onChange={(e) => {
                  const coupon = availableCoupons.find(
                    (c) => c.id === e.target.value
                  );
                  setSelectedCoupon(e.target.value);
                  setCouponValidityDate(coupon.expiryDate);

                  const couponContent = `<br/><br/><strong>Your Exclusive Offer:</strong> ${coupon.name}`;

                  setEmailContent(
                    emailContent.replace(
                      /<br\/><br\/><strong>Your Exclusive Offer:.*?<\/strong>/,
                      couponContent
                    )
                  );
                }}
                displayEmpty
                fullWidth
              >
                <MenuItem value="" disabled>
                  Select Coupon
                </MenuItem>
                {availableCoupons.map((coupon) => {
                  const expiryDate = coupon.expiryDate
                    ? new Date(coupon.expiryDate)
                    : null;

                  const isExpired = expiryDate && expiryDate < new Date();

                  return (
                    <MenuItem
                      key={coupon.id}
                      value={coupon.id}
                      disabled={isExpired}
                    >
                      {coupon.name} {isExpired && " (Expired)"}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>
                Choose from the available coupons you have created. To
                add/modify, visit the coupons section.
              </FormHelperText>
            </FormControl>
            <TextField
              disabled
              margin="dense"
              label="Validity Date"
              value={couponValidityDate}
              InputProps={{
                readOnly: true,
              }}
              fullWidth
            />

            <FormControl
              fullWidth
              variant="outlined"
              margin="dense"
              label="Select Body Template"
            >
              <InputLabel shrink>Select Template</InputLabel>
              <Select
                value={selectedTemplate}
                onChange={(e) => {
                  setSelectedTemplate(e.target.value);

                  const existingCouponMatch = emailContent.match(
                    /<strong>(.*?)<\/strong>/
                  );
                  const existingCoupon = existingCouponMatch
                    ? existingCouponMatch[1]
                    : null;

                  if (existingCoupon) {
                    const newEmailContent =
                      e.target.value +
                      "<br/>" +
                      ` <strong>${existingCoupon}</strong>`;
                    setEmailContent(newEmailContent);
                  } else {
                    setEmailContent(e.target.value);
                  }
                }}
              >
                {templates.map((template, index) => (
                  <MenuItem key={index} value={template}>
                    {template}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                Choose from the available email templates. Feel free to modify
                and add your custom text as well. NOTE: For SMS method, the
                formatting will not apply.
              </FormHelperText>
            </FormControl>

            <ReactQuill
              theme="snow"
              className="custom-quill"
              value={emailContent}
              onChange={setEmailContent}
              modules={modules}
              formats={formats}
              style={{ height: 300 }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setEmailDialogOpen(false)} color="secondary">
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleSendEmailConfirm}
              color="secondary"
              disabled={loading || !selectedCoupon}
            >
              {loading ? <CircularProgress size={24} /> : "Send"}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </Page>
  );
};

export default FullCustomerDetails;
