import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const LogoSlider = [
  { img: "11-min", url: "" },
  { img: "14-min", url: "" },
  { img: "15-min", url: "" },
  { img: "10-min", url: "" },
  { img: "16-min", url: "" },
  { img: "17-min", url: "" },
  { img: "2-min", url: "" },
  { img: "18-min", url: "" },
  { img: "19-min", url: "" },
  { img: "20-min", url: "" },
  { img: "21-min", url: "" },
  { img: "9-min", url: "" },
  { img: "26-min", url: "" },
  { img: "27-min", url: "" },
  { img: "22-min", url: "" },
  { img: "23-min", url: "" },
  { img: "3-min", url: "" },
  { img: "4-min", url: "" },
  { img: "5-min", url: "" },
  { img: "6-min", url: "" },
  { img: "7-min", url: "" },
  { img: "8-min", url: "" },
  { img: "24-min", url: "" },
  { img: "25-min", url: "" },
];

const BrandFour = () => {
  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 900,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    margin: 30,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <Slider {...settings} className="partner-slider-two">
      {LogoSlider.map((val, i) => (
        <div className="item" key={i}>
          <div className="img-meta">
            <a href={val.url}>
              <img src={`images/logo/${val.img}.png`} alt={`logo ${val.img}`} />
            </a>
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default BrandFour;
